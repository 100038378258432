import { StyledSection } from 'layout/StyledSection';
import QRCode from 'react-qr-code';
import { calcChamferedCorners } from 'styles/style.util';
import forestProfile from './forest-profile.jpg';

const LINK = 'https://t.me/overshom';

export const SectionAnyQuestions: React.FC = () => {
    return (
        <StyledSection title="Any Questions?" color="cyan">
            <div className="flex flex-col items-center justify-center gap-8 text-center md:flex-row md:items-start">
                <div className="flex flex-col items-center justify-center gap-4 pt-2 text-slate-500">
                    <img
                        src={forestProfile}
                        alt="Oleksii Shnyra"
                        className="w-32"
                        style={AVATAR_STYLE}
                    />
                    <div>
                        Contact founder <br />
                        directly at Telegram
                    </div>
                </div>
                <a
                    href={LINK}
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-col items-center justify-center gap-4 p-2 hover:bg-slate-200">
                    <QRCode value={LINK} size={128} />
                    <div className="text-sky-600">@overshom</div>
                </a>
                <div className="flex flex-col items-start justify-center pl-3 text-slate-500">
                    <div>Hi, I am Oleksii Shnyra 👋</div>
                    <div>I founded Targer at 2022</div>
                    <div>Feel free to contact me 😉</div>
                    <div>I love to build connections.</div>
                    <div>{`Let's together make`}</div>
                    <div>Targer the best place</div>
                    <div>For developers 👨‍💻 🧑‍💻 👩‍💻</div>
                </div>
            </div>
        </StyledSection>
    );
};

const AVATAR_STYLE = calcChamferedCorners([1, 1, 1, 1], 12);
