export const renderReadableNumber = (n: number | string) =>
    String(n).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const randi = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const round = (num = 0, fractions = 0) => {
    const k = Math.pow(10, fractions);
    const n = num * k * (1 + Number.EPSILON);
    return Math.round(n) / k;
};

export const doubleDigit = (num: number) => {
    const str = String(num);
    if (str.length === 1) {
        return `0${str}`;
    }
    return str;
};

export const clamp = (min: number, max: number, value: number) =>
    Math.max(min, Math.min(max, value));
