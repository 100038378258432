import { useState, useCallback, useMemo, useEffect, useRef } from 'react';

export const useInput = <T extends string>(initial = '', transform?: (s: string) => string) => {
    const [value, setValue] = useState(initial);

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            const value = e.target.value;
            setValue(transform ? transform(value) : value);
        },
        [transform],
    );

    const input = useMemo(() => {
        return {
            value: value as T,
            onChange,
        };
    }, [value, onChange]);

    return input;
};

// TODO refactor to reuse string input
// TODO support float number.
// Issue - currently, user cannot input 0.5 because "0." coerced to 0 int after input.
// So, we might need to validate / setState number on blur actually.
// But if we use oblur only - React complains about lack of onChange - because setState is not called,
// and therefore user cannot type anything without onChange.
// So, solution probably to used uncontrolled input.
export const useIntInput = (initial = 0, transform?: (s: number) => number) => {
    const [value, setValue] = useState(initial);

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            // value from input is always string, so convert it to number
            const value = parseInt(e.target.value || '0');
            if (Number.isNaN(value)) {
                return;
            }
            setValue(transform ? transform(value) : value);
        },
        [transform],
    );

    const input = useMemo(() => {
        return {
            value,
            onChange,
        };
    }, [value, onChange]);

    return input;
};

export const useAdvancedInput = <T extends string>(
    initial = '',
    transform?: (s: string) => string,
) => {
    const [value, setValue] = useState(initial);

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const value = e.target.value;
            setValue(transform ? transform(value) : value);
        },
        [transform],
    );

    const input = useMemo(() => {
        return [
            {
                value: value as T,
                onChange,
            },
            {
                setValue,
            },
        ] as const;
    }, [value, setValue, onChange]);

    return input;
};

export const useMountedRef = () => {
    const state = useRef(false);
    useEffect(() => {
        state.current = true;
        return () => {
            state.current = false;
        };
    }, []);
    return state;
};
