import { SectionAnyQuestions } from 'branding/SectionAnyQuestions';
import React from 'react';
import { Footer } from './Footer';
import { Header } from './Header';

export const Layout: React.FC = ({ children }) => {
    return (
        <>
            <div className="min-h-screen">
                <Header />
                <div className="flex flex-col">{children}</div>
            </div>
            <div className="main-container pt-4">
                <SectionAnyQuestions />
            </div>
            <Footer />
        </>
    );
};
