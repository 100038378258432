import { AppLogoLink } from 'branding/AppLogoLink';
import { FollowTelegramChannel } from 'branding/FollowTelegramChannel';
import { format } from 'date-fns';
import { RELEASE_DATE, RELEASE_VERSION } from 'dev/release.constant';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppGeneratedRoutes } from 'router/app-generated-routes';
/*
 * payment icons downloaded as per monobank style guide
 * https://www.figma.com/proto/6JDfLtGK9FemdE8z8izfWh/Monopay-Styleguide?page-id=0%3A1&node-id=1-128&viewport=358%2C477%2C0.08&scaling=scale-down
 */
import { ReactComponent as MonopaySvg } from './payment-methods/footer_monopay_dark_bg.svg';
import { ReactComponent as ApplePaySvg } from './payment-methods/footer_applepay_dark_bg.svg';
import { ReactComponent as GooglePaySvg } from './payment-methods/footer_googlepay_dark_bg.svg';
import { ReactComponent as VisaSvg } from './payment-methods/footer_visa_dark_bg.svg';
import { ReactComponent as MastercardSvg } from './payment-methods/footer_mc_dark_bg.svg';
import { useUsdToAuhConversion } from 'hooks/currency.hook';

const THIS_YEAR = new Date().getFullYear();

export const Footer: React.FC = () => {
    const navigate = useNavigate();
    const [ref, setRef] = useState<HTMLElement | null>(null);

    // helper behaviour to scroll page to footer
    useEffect(() => {
        if (!ref) {
            return;
        }

        const THRESHOLD = 3;
        const SIZE = 120;
        const TRIGGER_DELTA = 450;
        let lastClick = 0;
        let count = 0;
        const handler = (e: TouchEvent) => {
            if (!ref) {
                return;
            }
            const p = e.touches[0];
            if (!p) {
                return;
            }
            const isClickInsideBottomRightCorner = (() => {
                const bottom = window.innerHeight - SIZE;
                const right = window.innerWidth - SIZE;
                return p.clientX >= right && p.clientY >= bottom;
            })();
            if (!isClickInsideBottomRightCorner) {
                lastClick = 0;
                count = 0;
                return;
            }
            const now = Date.now();
            const delta = now - lastClick;
            lastClick = now;
            if (TRIGGER_DELTA > delta) {
                count++;
            } else {
                count = 1;
            }
            // console.log(delta, count);
            if (count >= THRESHOLD) {
                ref.scrollIntoView(true);
            }
        };

        window.addEventListener('touchstart', handler, { passive: true });
        return () => {
            window.removeEventListener('touchstart', handler);
        };
    }, [ref]);

    return (
        <footer
            className="mt-12 bg-zinc-800 px-8 py-20 md:py-32 md:px-16"
            style={style}
            ref={setRef}>
            <div className="flex flex-col justify-between gap-8 md:flex-row">
                <AppLogoLink
                    hideTitleOnMobile={false}
                    className="inline-flex bg-zinc-900 text-gray-300 md:self-start"
                />
                <FollowTelegramChannel />
                <div className="flex flex-col gap-4 text-center text-white md:text-right">
                    <div>&copy; {THIS_YEAR}, Ukraine 🇺🇦</div>
                    <div>Live Development Competitions</div>
                    <div>
                        <Link to={AppGeneratedRoutes.terms_and_conditions} className="underline">
                            Terms And Conditions
                        </Link>
                    </div>
                    <div className="flex flex-wrap justify-center gap-4">
                        <MonopaySvg className="h-16 w-16" />
                        <ApplePaySvg className="h-16 w-16 fill-white" />
                        <GooglePaySvg className="h-16 w-16" />
                        <div className="flex gap-4">
                            <VisaSvg className="h-16 w-16 scale-90" />
                            <MastercardSvg className="h-16 w-16 scale-75" />
                        </div>
                    </div>
                    <div>
                        <CurrencyRates />
                    </div>
                </div>
            </div>
            <div
                aria-hidden
                className="pt-6 text-center md:pt-2 md:text-left"
                onDoubleClick={e => {
                    e.preventDefault();
                    document.getSelection()?.empty();
                    navigate(AppGeneratedRoutes.benchmark);
                }}>
                <div className="text-xs text-slate-500">
                    release {format(new Date(RELEASE_DATE), 'ddMMMy p').toLowerCase()}
                </div>
                <div className="text-xs text-slate-500">release {RELEASE_VERSION}</div>
            </div>
        </footer>
    );
};

const CurrencyRates: React.FC = () => {
    const uah = useUsdToAuhConversion(1);
    return <div className="text-gray-500">1 USD = {uah} UAH</div>;
};

const style: React.CSSProperties = {
    // fix mobile Chrome artifacts (strange line) due to clip-path https://stackoverflow.com/a/54102171/5843293
    transform: 'translateZ(0)',
    clipPath:
        'polygon(0 0, 15% 0, calc(15% + 32px) 32px, calc(85% - 32px) 32px, 85% 0, 100% 0, 100% 100%, 0 100%)',
};
