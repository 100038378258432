import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const fade = keyframes`
    to {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
`;

export const AnimatedItem = styled.div<{
    index: number;
}>(({ index = 0 }) => ({
    opacity: 0,
    transform: 'translateY(48px) scale(0.9)',
    animation: `${fade} 300ms forwards`,
    animationDelay: `${index * 100}ms`,
}));
