import { SectionContainer } from 'layout/SectionContainer';
import { StyledSection } from 'layout/StyledSection';
import { IRouteConfig } from 'router/router.types';
import { clsxm } from 'utils/clsxm';
import EventSampleSrc from './-page/event-sample.png';
import LeaderboardSampleSrc from './-page/leaderboard-sample.png';

export const routeConfig: IRouteConfig = {
    title: 'Partnership for IT Schools',
    Component: () => (
        <SectionContainer>
            <StyledSection title="Partnership request">
                <div className="flex flex-col gap-4">
                    <div className="text-center text-xl font-bold">
                        Привіт, мене звати Олексій 👋
                    </div>
                    <div className="bg-slate-100 p-2 text-slate-600">
                        Video intro - <b>1 min</b>
                        <br />
                        Targer demo competition - <b>11 min</b>
                    </div>
                    <iframe
                        className="aspect-video"
                        src="https://www.youtube.com/embed/oMJj6Jnx2Wk"
                        title="Targer for IT schools   HD 720p"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                    <div>
                        Вже понад 5 років я кофаундер і техлід аутсорс компанії OSDB.
                        <br />
                        Маючи досвід розробки продуктів з нуля для клієнтів з Америки та Європи, я
                        створив новий ІТ продукт - Targer.
                    </div>
                    <div className="text-center align-bottom text-xl font-bold">
                        Targer - це платформа для проведення змагань між девелоперами наживо
                        <div
                            className={clsxm(
                                'ml-2 inline-block h-3 w-3 animate-pulse rounded-full bg-red-400',
                                'md:mt-1',
                            )}
                        />
                    </div>
                    <div>
                        Я зашарив вам це відео бо вважаю що з Таргером ваша ІТ школа здатна
                        забезпечити ще кращі офери на роботу для своїх випускників.
                        <br />
                        Враховуючи специфіку курсів вашої школи, разом з вами я хочу розробити і
                        проводити регулярні змагання для ваших студентів.
                        <br />
                        Ми можемо проводити змагання з таких пріоритетних технологій як:
                        <ul className="list-inside list-disc">
                            <li>TypeScript</li>
                            <li>JavaScript</li>
                            <li>NodeJs</li>
                            <li>React</li>
                            <li>Machine Learning</li>
                        </ul>
                    </div>
                    <div className="text-center text-xl font-bold">Як проходить змагання</div>
                    <div>
                        На головній сторінці Таргера бачимо які є змагання для прийняття участі.
                    </div>
                    <div>
                        <img
                            src={EventSampleSrc}
                            alt="event sample"
                            className="mx-auto my-4 w-full max-w-lg"
                        />
                    </div>
                    <div>Кожне змагання призначене на конкретну дату та обмежене у часі.</div>
                    <div>
                        Змагання проходить в максимально наближеному до роботи форматі - є гітхаб
                        репозиторій куди потрібно пушити код рішення задач.
                    </div>
                    <div>
                        Процес змагання автоматизований:
                        <ul className="list-inside list-disc">
                            <li>Девелопер бере собі квиток на змагання</li>
                            <li>
                                Коли змагання стартує - стане доступна кнопка для сетапа репозиторія
                            </li>
                            <li>
                                В репозиторії прописані усі необхідні вимоги до задачі конкретного
                                івенту
                            </li>
                            <li>
                                Кожен пуш в репозиторій запускає ряд тестів який вираховує бали
                                конкретного учасника
                            </li>
                            <li>Все це в реальному часі відображається в лідерборді</li>
                        </ul>
                    </div>
                    <div>
                        Коли змагання завершилось - лідерборд рейтинг фіксується і таким чином видно
                        позицію кожного учасника змагання.
                    </div>
                    <div>
                        <img
                            src={LeaderboardSampleSrc}
                            alt="leaderboard sample"
                            className="mx-auto my-4 w-full max-w-lg"
                        />
                    </div>
                    <div className="text-center text-xl font-bold">
                        Причини співпраці вашої ІТ школи з Таргером:
                    </div>
                    <div>
                        <ol className="list-inside list-decimal">
                            <li>
                                Це дає змогу студентам потрапити в стресову ситуацію, в
                                високо-конкурентне середовище щоб максимально швидко проявити свої
                                практичні навички які вони здобули в школі / протягом курсу.
                            </li>
                            <li>
                                Обʼєктивна оцінка девелопера є підставою для релевантних оферів по
                                роботі.
                                <ol className="list-inside list-decimal pl-4">
                                    <li>
                                        Мої партнерські рекрутери і компанії зацікавлені в наймі
                                        кандидатів що круто проявлять себе на змаганнях.
                                    </li>
                                </ol>
                            </li>
                            <li>Реклама школи на головній сторінці</li>
                        </ol>
                    </div>
                    <div className="text-center text-xl font-bold">
                        Чекаю на вашу позитивну відповідь 😉
                    </div>
                </div>
            </StyledSection>
        </SectionContainer>
    ),
};
