import { useIsMobile } from 'hooks/useIsMobile.hook';
import React, { useMemo } from 'react';
import { clsxm } from 'utils/clsxm';
import { TriangleBadge } from 'components/triangle-bage/TriangleBadge';

export const StyledSection: React.FC<{
    title: string;
    className?: string;
    // TODO enum for event type
    color?: 'sky' | 'emerald' | 'cyan' | 'javascript';
    badgeProps?: React.ComponentProps<typeof TriangleBadge>;
    id?: string;
    wideTitle?: boolean;
    asMobile?: boolean;
}> = ({
    title,
    children,
    className,
    color = 'sky',
    badgeProps,
    id,
    wideTitle = false,
    asMobile = false,
}) => {
    const isMobile = useIsMobile() || asMobile;
    const sectionStyle = useMemo(() => {
        const style: React.CSSProperties = {
            clipPath: getComplexClipPath(isMobile, wideTitle),
        };
        return style;
    }, [isMobile, wideTitle]);

    return (
        <div className="drop-shadow" id={id}>
            <div
                style={sectionStyle}
                className={clsxm('relative min-h-[320px] bg-white', className)}>
                <div
                    className={clsxm(
                        'relative',
                        'p-2 px-4 pl-14 text-left text-white',
                        color === 'sky' && 'bg-sky-800',
                        color === 'emerald' && 'bg-emerald-800',
                        color === 'cyan' && 'bg-cyan-800',
                        color === 'javascript' && 'bg-yellow-700',
                    )}>
                    {title}
                </div>
                {Boolean(badgeProps) && <TriangleBadge wrapClassName="md:top-6" {...badgeProps} />}
                <div className="px-4 py-12 md:pb-20">{children}</div>
            </div>
        </div>
    );
};

const getComplexClipPath = (isMobile: boolean, wideTitle: boolean) => {
    const chamfer = '2rem';
    const doubleChamfer = '4rem';
    const calcChamfer = `calc(100% - ${chamfer})`;
    const top =
        isMobile || wideTitle
            ? [`0 ${chamfer}`, `${chamfer} 0`, `${calcChamfer} 0`, `100% ${chamfer}`]
            : [
                  `0 ${chamfer}`,
                  `${chamfer} 0`,
                  `calc(40% - ${chamfer}) 0`,
                  `40% ${chamfer}`,
                  `${calcChamfer} ${chamfer}`,
                  `100% ${doubleChamfer}`,
              ];
    const bottom = isMobile
        ? [`100% ${calcChamfer}`, `${calcChamfer} 100%`, `${chamfer} 100%`, `0 ${calcChamfer}`]
        : [
              `100% ${calcChamfer}`,
              `${calcChamfer} 100%`,
              `60% 100%`,
              `calc(60% - ${chamfer}) ${calcChamfer}`,
              `${chamfer} ${calcChamfer}`,
              `0 calc(100% - ${chamfer} - ${chamfer}))`,
          ];
    return `polygon(${[...top, ...bottom].join(', ')}`;
};
