export {};
/*
Polyfill to avoid errors from Sentry like this - https://targer.sentry.io/issues/3798480258/?project=5569975&referrer=weekly-email

browser
HeadlessChrome 68.0.3440
browser.name
HeadlessChrome
environment
FE-production
handled
yes
level
error
mechanism
generic
os.name
Linux
transaction
/
url
https://targer.dev/
user
ip:35.162.4.117
*/

if (!String.prototype.matchAll) {
    String.prototype.matchAll = function matchAll(pattern: any, haystack: any) {
        const regex = new RegExp(pattern, 'g');
        const matches: any[] = [];

        const match_result = haystack.match(regex);

        for (const index in match_result) {
            const item = match_result[index];
            matches[+index] = item.match(new RegExp(pattern));
        }
        return matches;
    } as any;
}
