import useId from '@mui/material/utils/useId';
import { clsxm } from 'utils/clsxm';

export const StyledSelect: React.FC<{
    label: string;
    options: { key: string; content: React.ReactNode }[];
    value: string;
    onChange: (value: string) => void;
    className?: string;
    bottomContent?: React.ReactNode;
    asRadio?: boolean;
    disabled?: boolean;
}> = ({ label, options, value, onChange, className, bottomContent, asRadio, disabled }) => {
    const id = useId();

    if (asRadio) {
        return (
            <div className={clsxm('flex flex-col gap-6 p-4 ring-1 ring-gray-200', className)}>
                <div className="pl-2 text-gray-600">{label}</div>
                {options.map(e => (
                    <div
                        aria-hidden
                        key={e.key}
                        className="flex cursor-pointer select-none items-center gap-2"
                        onClick={() => !disabled && onChange(e.key)}>
                        <div
                            className={clsxm(
                                'h-4 w-4 bg-gray-300 ring-1 ring-gray-500 transition',
                                !disabled && value === e.key && 'bg-sky-400 ring-2 ring-sky-600',
                                disabled && value === e.key && 'bg-zinc-400 ring-2 ring-zinc-600',
                            )}
                        />
                        {e.content}
                    </div>
                ))}
                {bottomContent}
            </div>
        );
    }

    return (
        <div className={'flex flex-col gap-6 p-4 ring-1 ring-gray-200'}>
            <label htmlFor={id} className="pl-2 text-gray-600">
                {label}
            </label>
            <select
                id={id}
                value={value}
                onChange={e => onChange(e.target.value)}
                disabled={disabled}
                className={clsxm('outline-none', 'focus:ring-1 focus:ring-sky-400', className)}>
                {options.map(e => (
                    <option
                        value={e.key}
                        key={e.key}
                        className="flex cursor-pointer select-none items-center gap-2">
                        {e.content}
                    </option>
                ))}
            </select>
            {bottomContent}
        </div>
    );
};
