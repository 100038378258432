import { Tooltip, TooltipProps, Fade } from '@mui/material';

const CustomAnimation: typeof Fade = ({ children, ...props }) => (
    <Fade {...props} className={'bg-sky-800 text-sky-50 ring-2 ring-sky-600'}>
        {children}
    </Fade>
);

export const StyledTooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
    return (
        <Tooltip TransitionComponent={CustomAnimation} {...props}>
            {children}
        </Tooltip>
    );
};
