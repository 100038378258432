import { restAxios } from '../restAxios';
import { conformResponse } from '../helper';
import {
    ResponseAllTicketsZod,
    ResponseMyTicketZod,
    ResponseRequestPurchaseTicketDto,
    ResponseTicketsCompetitionStatsDto,
} from './ticket.dto';

export class TicketApi {
    static async getCompetitionStats(competitionId: string) {
        return conformResponse(
            restAxios.get(`mixed/tickets/for-competition/${competitionId}/ticket-stats`),
            ResponseTicketsCompetitionStatsDto,
        );
    }

    static async findMyTicket(competitionId: string) {
        return conformResponse(
            restAxios.get(`private/tickets/for-competition/${competitionId}/find-my-ticket`),
            ResponseMyTicketZod,
        );
    }

    /**
     * for admin
     */
    static async getAllTickets({ competitionId }: { competitionId: string }) {
        return conformResponse(
            restAxios.get(`private/tickets/for-competition/${competitionId}/all-tickets`),
            ResponseAllTicketsZod,
        );
    }

    static async requestPurchase({
        competitionId,
        promoCode,
    }: {
        competitionId: string;
        promoCode?: string;
    }) {
        return conformResponse(
            restAxios.post(`private/tickets/for-competition/${competitionId}/request-purchase`, {
                promoCode,
            }),
            ResponseRequestPurchaseTicketDto,
        );
    }

    static async purchaseFreeTicket(competitionId: string) {
        return conformResponse(
            restAxios.post(`private/tickets/for-competition/${competitionId}/purchase-free-ticket`),
            ResponseMyTicketZod,
        );
    }
}
