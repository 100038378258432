import { PublicApi } from '../api/public';
import { createCachedAsync } from '../utils/async.util';

// 50 min
const CACHE_TIME_FOR_RATES = 50 * 60 * 1e3;
export const getCachedRates = createCachedAsync(
    PublicApi.getCurrencyRates.bind(PublicApi),
    CACHE_TIME_FOR_RATES,
);

// without rounding 79.99*100 is 7998.999999999999
export const convertUnit2Coin = (units: number) => Number((units * 100).toFixed(0));
export const convertCoin2Unit = (coins: number) => coins / 100;
