import { restAxios } from '../restAxios';
import { conformResponse } from '../helper';
import { ResponseLoginUrlDto, ResponseSignInDto } from './dto';
import { routerHelper } from '../../router/router.helper';

export class AuthApi {
    static async generateLoginUrl(continuePage?: string) {
        return conformResponse(
            restAxios.get('auth/generate-login-url', {
                params: {
                    [routerHelper.PARAM_KEY_FOR_CONTINUE_PAGE]: continuePage,
                },
            }),
            ResponseLoginUrlDto,
        );
    }

    static async signIn(code: string, state: string) {
        return conformResponse(
            restAxios.put('auth/sign-in', {
                code,
                state,
            }),
            ResponseSignInDto,
        );
    }
}
