import { useMemo } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { ALL_GENERATED_ROUTES } from './app-generated-routes-config';
import { IModuleConfig, IRouteConfig } from './router.types';
import { RequirePermissions } from 'hoc/RequirePermissions';
import { StyledSection } from 'layout/StyledSection';
import { SignInButton } from 'auth/SignInButton';
import { SectionContainer } from 'layout/SectionContainer';
import { Helmet } from 'react-helmet';

// Remember - render all routes always.
// case with `/competitions/:id` (public page) and `/competitions/my-active` (private page)
// without auth, no private route provided, so "/competitions/my-active" considered as
// "/competitions/:id" causing broken requests to API because "my-active" is not competitionId.

export const RouterRoutesMapping: React.FC<IState> = ({ isAuthorized }) => {
    const config = useMemo(() => {
        return allTypedRoutes
            .map(e =>
                transformPreRoute(e, {
                    isAuthorized,
                }),
            )
            .concat(BASE_REDIRECT_ROUTE);
    }, [isAuthorized]);

    const element = useRoutes(config);
    return element;
};

type PreRouteObject = {
    path: string;
    route?: IRouteConfig;
    module?: IModuleConfig;
    children?: PreRouteObject[];
};

const allTypedRoutes: PreRouteObject[] = ALL_GENERATED_ROUTES;

interface IState {
    isAuthorized?: boolean;
}

const transformPreRoute = (preRoute: PreRouteObject, state: IState): RouteObject => {
    const { route, module } = preRoute;
    const getRouteElement = (ignoreModule?: boolean) => {
        let element: React.ReactElement | undefined;
        if (!ignoreModule && module?.Layout) {
            element = <module.Layout />;
        } else if (route?.Component) {
            element = <route.Component />;
        }
        const anyPermissionRequired = module?.permissionRequired || route?.permissionRequired;
        if ((route?.requireAuth || anyPermissionRequired) && !state.isAuthorized) {
            return (
                <SectionContainer>
                    <StyledSection title="Auth Required">
                        <div className="flex flex-col items-center justify-center gap-4">
                            <div>To view this page, please sign in</div>
                            <SignInButton />
                        </div>
                    </StyledSection>
                </SectionContainer>
            );
        }
        if (anyPermissionRequired) {
            return (
                <RequirePermissions atLeast={anyPermissionRequired}>{element}</RequirePermissions>
            );
        }
        return element;
    };
    const virtualChildren: RouteObject[] | undefined = module &&
        route && [
            {
                path: preRoute.path,
                element: getRouteElement(true),
            },
        ];
    let children: RouteObject[] | undefined;
    if (virtualChildren || preRoute.children) {
        children = [
            ...(virtualChildren || []),
            ...(preRoute.children?.map(e => transformPreRoute(e, state)) || []),
        ];
    }

    const render = () => {
        if (module && !route) {
            return getRouteElement();
        }

        if (!route) {
            return;
        }

        const title = `${route.title ? `${route.title} | ` : ''}Targer`;

        return (
            <div>
                {!false && (
                    <Helmet>
                        <title>{title}</title>
                        {/* TODO */}
                        {/* <meta property="og:title" content="__OG_TITLE__" /> */}
                        {/* <meta property="og:description" content="__OG_DESCRIPTION__" /> */}
                    </Helmet>
                )}
                {getRouteElement()}
            </div>
        );
    };

    return {
        path: preRoute.path,
        element: render(),
        children,
    };
};

const BASE_REDIRECT_ROUTE: RouteObject = {
    path: '*',
    element: <Navigate to="/" replace />,
};
