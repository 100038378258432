import { ThunkResult } from '../types';
import { authActions } from './actions';
import { setSessionToken, finishAuthSession } from '../../utils';
import { UserThunk } from '../user/thunk';
import { AuthApi } from '../../api/auth';

export class AuthThunk {
    static authorize =
        ({ token }: { token: string }): ThunkResult =>
        async dispatch => {
            setSessionToken(token);
            dispatch(authActions.signIn.success({ token }));
            dispatch(UserThunk.getMe());
        };

    static signIn =
        ({
            code,
            state,
            continuePage,
        }: {
            code: string;
            state: string;
            continuePage?: string;
        }): ThunkResult =>
        async dispatch => {
            dispatch(authActions.signIn.request());
            try {
                const res = await AuthApi.signIn(code, state);
                dispatch(AuthThunk.authorize({ token: res.sid }));
                if (continuePage) {
                    window.location.href = continuePage;
                }
            } catch {
                dispatch(authActions.signIn.failure());
            }
        };

    static signOut = (): ThunkResult => async dispatch => {
        finishAuthSession();
        dispatch(authActions.signOut());
    };
}
