import { useEffect, useState } from 'react';
import { GoogleMapsService } from './GoogleMapsService';
import { useGoogleMapScriptInit } from './useGoogleMapScriptInit';

/**
 * useGoogleMapsService
 */
export const useGMS = () => {
    useGoogleMapScriptInit();
    const [gms, setGms] = useState<GoogleMapsService>();

    useEffect(() => {
        const check = () => {
            if (typeof google !== 'undefined') {
                const i = new GoogleMapsService();
                setGms(i);
                return i;
            }
        };
        if (!check()) {
            const timer = setInterval(() => {
                // console.log('start GMS interval');
                const res = check();
                if (res) {
                    clearInterval(timer);
                    // console.log('stop GMS interval');
                }
            }, 100);
            return () => {
                clearInterval(timer);
            };
        }
    }, []);

    return gms;
};
