import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { zodClass } from 'utils/zod.utils';
import { z } from 'zod';

export const useAdminLocalConfig = () => {
    const [state, setState] = useRecoilState(atomState);

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }, [state]);

    return [state, setState] as const;
};

class AdminLocalConfigZod extends zodClass({
    mockLeaderboard: z.boolean().default(false),
    adminLeaderboard: z.boolean().default(false),
    adminPromos: z.boolean().default(false),
    adminTickets: z.boolean().default(false),
    competitionControls: z.boolean().default(false),
}) {}

const LOCAL_STORAGE_KEY = 'adminLocalStorageConfig';

const initialState = (() => {
    try {
        const raw = localStorage.getItem(LOCAL_STORAGE_KEY);
        return new AdminLocalConfigZod(JSON.parse(raw || ''));
    } catch {
        return new AdminLocalConfigZod({});
    }
})();

const atomState = atom({
    key: LOCAL_STORAGE_KEY,
    default: initialState,
});
