import { RequirePermissions } from 'hoc';
import { SectionContainer } from 'layout/SectionContainer';
import { StyledSection } from 'layout/StyledSection';
import { IS_DEV_MODE } from 'typed-env';
import { UserPermission } from 'types';
import { AdminLocalConfigWidget } from './AdminLocalConfigWidget';
import { SitemapDevKitSection } from './sections/SitemapDevKitSection';

export const DevKitPage: React.FC = () => {
    const renderContent = () => {
        return (
            <SectionContainer>
                <StyledSection title="Leaderboard">
                    <AdminLocalConfigWidget />
                </StyledSection>
                <SitemapDevKitSection />
            </SectionContainer>
        );
    };

    if (!IS_DEV_MODE) {
        return (
            <RequirePermissions atLeast={UserPermission.ADMIN}>
                {renderContent()}
            </RequirePermissions>
        );
    }

    return renderContent();
};
