export class GoogleMapsService {
    autocompleteService: google.maps.places.AutocompleteService;

    constructor() {
        this.autocompleteService = new google.maps.places.AutocompleteService();
    }

    async getPlacePredictions(search: string, types: string[] = []): Promise<PlaceType[]> {
        const { predictions } = await this.autocompleteService.getPlacePredictions({
            input: search,
            // TODO
            // sessionToken: this.sessionToken,
            types,
        });

        return predictions.map(p => {
            // extract terms ['locality', 'political', 'geocode']
            const city =
                p.terms[p.types.indexOf('locality') || 0]?.value ||
                p.description.split(',')[0] ||
                'some_city';
            const indexRegion = p.terms.length === 2 ? 0 : p.types.indexOf('political') || 0;
            const region =
                p.terms[indexRegion]?.value ||
                p.description.split(',').pop()?.trim() ||
                'some_region';
            const country =
                p.terms[p.types.indexOf('geocode') || 2]?.value ||
                p.description.split(',').pop()?.trim() ||
                'some_country';
            return {
                ...p,
                placeName: p.structured_formatting.main_text,
                address: p.structured_formatting.secondary_text,
                placeId: p.place_id,
                structured_formatting: p.structured_formatting,
                city,
                region,
                country,
            };
        });
    }
}

export type PlaceType = {
    placeId: string;
    placeName: string;
    address: string;
    description: string;
    terms: { value: string }[];
    types: string[];
    coords?: google.maps.LatLngLiteral;
    phone?: string;
    icon?: string;
    structured_formatting?: google.maps.places.StructuredFormatting;
    weekday_text?: string[];
    originalPlace?: google.maps.places.PlaceResult;
    city: string;
    region: string; // aka oblast
    country: string;
};
