import { clsxm } from 'utils/clsxm';

export const SectionContainer: React.FC<{
    className?: string;
}> = ({ children, className }) => {
    return (
        <div className={clsxm('main-container flex flex-col gap-8 py-4 pt-8', className)}>
            {children}
        </div>
    );
};
