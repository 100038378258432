import { SignInButton } from 'auth/SignInButton';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { ProfileSection } from 'domain/profile/ProfileSection';
import { RequirePermissions } from 'hoc';
import { SectionContainer } from 'layout/SectionContainer';
import { StyledSection } from 'layout/StyledSection';
import { Helmet } from 'react-helmet';
import { TargeriansMapSection } from './TargeriansMapSection';

export const SurveyPage: React.FC = () => {
    return (
        <SectionContainer>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://unpkg.com/leaflet@1.9.3/dist/leaflet.css"
                    integrity="sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI="
                    crossOrigin=""
                />
            </Helmet>
            <StyledSection title="Survey">
                <TargeriansMapSection />
                <PlaceholderBox
                    infoContent={
                        <div className="flex flex-col gap-4">
                            <div>
                                More survey analytics will be shared once enough profiles data
                                provided by users
                            </div>
                            <div>Survey snapshot as of Feb 12 2023 12:00 Kyiv</div>
                        </div>
                    }
                />
            </StyledSection>
            <RequirePermissions
                noAuthContent={
                    <StyledSection title="Your Profile">
                        <PlaceholderBox
                            infoContent={
                                <div className="flex flex-col items-center gap-4">
                                    <div className="p-2 text-center">Are you developer?</div>
                                    <div className="p-2 text-center">
                                        Sign in to configure your profile to participate in survey.
                                    </div>
                                    <SignInButton />
                                </div>
                            }
                        />
                    </StyledSection>
                }>
                <ProfileSection />
            </RequirePermissions>
        </SectionContainer>
    );
};
