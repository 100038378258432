import { restAxios } from 'api/restAxios';
import { ProfileSchema } from './profile.schema';

export class ProfileApi {
    static async getAll() {
        // TODO refactor to paginated DTO
        const { data } = await restAxios.get<{
            items: ProfileSchema[];
            total: number;
        }>('private/profiles/all');
        return data;
    }

    static async getMe() {
        const { data } = await restAxios.get('private/profiles/me');
        return data ? ProfileSchema.parse(data) : null;
    }

    static async saveMe(newProfile: ProfileSchema) {
        const { data } = await restAxios.put('private/profiles/me', newProfile);
        return ProfileSchema.parse(data);
    }
}
