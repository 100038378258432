import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from 'utils';
import { useDispatch } from 'react-redux';
import { AuthThunk } from 'store/auth/thunk';
import { StyledSection } from 'layout/StyledSection';
import { SectionContainer } from 'layout/SectionContainer';
import { routerHelper } from 'router/router.helper';

export const GithubOauthCallbackPage: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {
        code,
        state,
        error,
        error_description,
        [routerHelper.PARAM_KEY_FOR_CONTINUE_PAGE]: continuePage,
    } = parseQueryString(location?.search);

    useEffect(() => {
        if (!code || !state) {
            return;
        }
        dispatch(
            AuthThunk.signIn({
                code,
                state,
                continuePage: continuePage && routerHelper.decodeContinuePage(continuePage),
            }),
        );
    }, [code, state, dispatch, continuePage]);

    if (!code || !state) {
        // TODO show error
        return null;
    }

    if (error || error_description) {
        return (
            // TODO reuse common error container
            <h3>
                Error: {error} {error_description}
            </h3>
        );
    }

    return (
        <SectionContainer>
            <StyledSection title="Authentication">Loading...</StyledSection>
        </SectionContainer>
    );
};
