import { authActions } from './actions';
import { createBetterReducer } from '../../utils';

export class StateModel {
    token = '';
    isAuthorized = false;
    isLoading = false;
}

export const authReducer = createBetterReducer(new StateModel(), [authActions])
    .handleAction(authActions.signIn.request, (state): StateModel => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(authActions.signIn.success, (state, { payload }): StateModel => {
        return {
            ...state,
            token: payload.token,
            isAuthorized: true,
            isLoading: false,
        };
    })
    .handleAction(authActions.signIn.failure, (state): StateModel => {
        return {
            ...state,
            isLoading: false,
        };
    })
    .handleAction(authActions.signOut, (state): StateModel => {
        return {
            ...state,
            token: '',
            isAuthorized: false,
        };
    });
