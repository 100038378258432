export enum UserPermission {
    ADMIN = 'ADMIN',
    DEVELOPER = 'DEVELOPER',
}

export enum CompetitionAccessType {
    URL_ACCESS = 'URL_ACCESS',
    PUBLIC = 'PUBLIC',
    INVITE = 'INVITE',
}

export enum CompetitionStatus {
    DRAFT = 'DRAFT',
    READY = 'READY',
    EXPIRED = 'EXPIRED',
}

export enum TicketStatus {
    PENDING = 'PENDING',
    PAID = 'PAID',
}
