import { createAction, createAsyncAction } from 'typesafe-actions';

enum Names {
    SIGN_IN_REQUEST = 'user/SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS = 'user/SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE = 'user/SIGN_IN_FAILURE',
    SIGN_OUT = 'auth/SIGN_OUT',
}

export const authActions = {
    signIn: createAsyncAction(Names.SIGN_IN_REQUEST, Names.SIGN_IN_SUCCESS, Names.SIGN_IN_FAILURE)<
        void,
        {
            token: string;
        },
        void
    >(),
    signOut: createAction(Names.SIGN_OUT)(),
};
