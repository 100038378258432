import { createAsyncAction } from 'typesafe-actions';
import { ResponseUserZod } from '../../api/user/dto';

enum Names {
    GET_MY_USER_REQUEST = 'user/GET_MY_USER_REQUEST',
    GET_MY_USER_SUCCESS = 'user/GET_MY_USER_SUCCESS',
    GET_MY_USER_FAILURE = 'user/GET_MY_USER_FAILURE',
}

export const getMyUserActions = createAsyncAction(
    Names.GET_MY_USER_REQUEST,
    Names.GET_MY_USER_SUCCESS,
    Names.GET_MY_USER_FAILURE,
)<void, ResponseUserZod, void>();
