import { v } from 'overshom-valid';
import { basePaginatedDtoBuilder } from '../helper';

const RepoSchema = () => ({
    id: v.Number(),
    node_id: v.String(),
    name: v.String(),
    full_name: v.String(),
    private: v.Boolean(),
    owner: v.Object({
        login: v.String(),
        id: v.Number(),
        node_id: v.String(),
        avatar_url: v.String(),
        gravatar_id: v.String(),
        url: v.String(),
        html_url: v.String(),
        followers_url: v.String(),
        following_url: v.String(),
        gists_url: v.String(),
        starred_url: v.String(),
        subscriptions_url: v.String(),
        organizations_url: v.String(),
        repos_url: v.String(),
        events_url: v.String(),
        received_events_url: v.String(),
        type: v.String(),
        site_admin: v.Boolean(),
    }),
    html_url: v.String(),
    description: v.String().optional(),
    fork: v.Boolean(),
    url: v.String(),
    forks_url: v.String(),
    keys_url: v.String(),
    collaborators_url: v.String(),
    teams_url: v.String(),
    hooks_url: v.String(),
    issue_events_url: v.String(),
    events_url: v.String(),
    assignees_url: v.String(),
    branches_url: v.String(),
    tags_url: v.String(),
    blobs_url: v.String(),
    git_tags_url: v.String(),
    git_refs_url: v.String(),
    trees_url: v.String(),
    statuses_url: v.String(),
    languages_url: v.String(),
    stargazers_url: v.String(),
    contributors_url: v.String(),
    subscribers_url: v.String(),
    subscription_url: v.String(),
    commits_url: v.String(),
    git_commits_url: v.String(),
    comments_url: v.String(),
    issue_comment_url: v.String(),
    contents_url: v.String(),
    compare_url: v.String(),
    merges_url: v.String(),
    archive_url: v.String(),
    downloads_url: v.String(),
    issues_url: v.String(),
    pulls_url: v.String(),
    milestones_url: v.String(),
    notifications_url: v.String(),
    labels_url: v.String(),
    releases_url: v.String(),
    deployments_url: v.String(),
    created_at: v.String(),
    updated_at: v.String(),
    pushed_at: v.String(),
    git_url: v.String(),
    ssh_url: v.String(),
    clone_url: v.String(),
    svn_url: v.String(),
    homepage: v.String().optional(),
    size: v.Number(),
    stargazers_count: v.Number(),
    watchers_count: v.Number(),
    language: v.String().optional(),
    has_issues: v.Boolean(),
    has_projects: v.Boolean(),
    has_downloads: v.Boolean(),
    has_wiki: v.Boolean(),
    has_pages: v.Boolean(),
    forks_count: v.Number(),
    archived: v.Boolean(),
    disabled: v.Boolean(),
    open_issues_count: v.Number(),
    forks: v.Number(),
    open_issues: v.Number(),
    watchers: v.Number(),
    default_branch: v.String(),
    permissions: v.Object({
        admin: v.Boolean(),
        push: v.Boolean(),
        pull: v.Boolean(),
    }),
});

export class ResponsePaginatedRepoDto extends basePaginatedDtoBuilder(RepoSchema()) {}
// export class ResponseRepoListDto extends v.class(RepoSchema()) { }
