import { useMutation } from '@tanstack/react-query';
import { CompetitionApi } from 'api/competition';
import { ResponseCompetitionAdvancedInfoDto } from 'api/competition/dto';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { StyledButton } from 'components/form/StyledButton';
import { StyledSection } from 'layout/StyledSection';
import { calcStatusAndLocationDetails } from 'domain/competition/competition.util';
import { useState } from 'react';
import { clsxm } from 'utils/clsxm';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { StyledTooltip } from 'components/styled-tooltip/StyledTooltip';
import { ImCopy } from 'react-icons/im';
import { EnumButtonGroup } from 'components/form/EnumButtonGroup';

enum EUrlMode {
    SSH = 'SSH',
    HTTPS = 'HTTPS',
}

export const CompetitionSectionRepo: React.FC<{
    competition: ResponseCompetitionAdvancedInfoDto;
    fullRefresh: () => Promise<void>;
}> = ({ competition, fullRefresh }) => {
    const [urlMode, setUrlMode] = useState(EUrlMode.SSH);
    const [copied, setCopied] = useState(false);

    const join = useMutation(
        async () => {
            if (!competition) {
                return;
            }
            await CompetitionApi.joinById(competition.id);
        },
        {
            async onSuccess() {
                await fullRefresh();
            },
        },
    );

    const { status } = calcStatusAndLocationDetails({
        startDate: competition.startDate,
        endDate: competition.expireDate,
    });

    const renderLinks = (htmlUrl: string) => {
        let pathFromUrl = '';
        try {
            pathFromUrl = new URL(htmlUrl).pathname.slice(1);
        } catch {}

        const text = htmlUrl
            ? `git clone ${urlMode === 'SSH' ? http2ssh(htmlUrl) : htmlUrl}`
            : 'loading...';

        const renderHint = () => {
            if (status === 'past') {
                return (
                    <div className="my-4 border-2 p-2 text-slate-400">
                        <div>Competition has ended.</div>
                        <div>All further pushes to this GitHub repo will be ignored.</div>
                        <div>Leaderboard ranking is now frozen.</div>
                    </div>
                );
            }

            return (
                <div className="my-4 border-2 p-2 text-slate-400">
                    <div>
                        Once you clone your repository, make your changes and push them to GitHub.
                    </div>
                    <div>
                        It will enqueue your submission for scoring and ranking among other
                        participants.
                    </div>
                    <div>Leaderboard updates your ranking automatically in real time.</div>
                </div>
            );
        };
        return (
            <div className="flex flex-col gap-2 text-center">
                <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
                    <b>Your repo on GitHub:</b>
                    {htmlUrl ? (
                        <ExternalLink href={htmlUrl}>{pathFromUrl}</ExternalLink>
                    ) : (
                        'loading...'
                    )}
                </div>
                <div className="w-full max-w-4xl self-center">
                    <div className="flex flex-col justify-center gap-2 md:flex-row">
                        <div>
                            <EnumButtonGroup
                                options={[EUrlMode.SSH, EUrlMode.HTTPS]}
                                value={urlMode}
                                onChange={e => setUrlMode(e)}
                            />
                        </div>

                        <CopyToClipboard
                            onCopy={() => {
                                setCopied(true);
                            }}
                            text={text}>
                            <StyledTooltip title={copied ? 'Copied!' : 'Click To Copy'}>
                                <div
                                    aria-hidden
                                    className={clsxm(
                                        'inline-flex max-w-full flex-1 shrink cursor-pointer items-center justify-between gap-2 bg-slate-200 p-2 text-slate-800 ring-slate-400',
                                        'hover:ring',
                                        'active:bg-slate-300 active:ring-slate-600',
                                        copied && 'hover:ring-sky-600',
                                    )}
                                    onMouseEnter={() => {
                                        setCopied(false);
                                    }}>
                                    <div className="break-all">{text}</div>
                                    <ImCopy className="pointer-events-none shrink-0" />
                                </div>
                            </StyledTooltip>
                        </CopyToClipboard>
                    </div>
                </div>
                {renderHint()}
            </div>
        );
    };

    const renderJoinButton = () => {
        if (status === 'future') {
            // TODO show placeholder for url
            return <div>Repo setup instructions will be provided after competition start.</div>;
        }

        if (!competition.meParticipant) {
            if (status === 'past') {
                return (
                    <div>
                        Competition has ended and your personal repository was not configured.
                    </div>
                );
            }
            return (
                <div className="flex flex-col items-center gap-4">
                    <div>Click button below to setup repo and follow further instructions</div>
                    <StyledButton onClick={() => join.mutate()} loading={join.isLoading}>
                        setup repo
                    </StyledButton>
                </div>
            );
        }

        return (
            <div className="flex flex-col gap-4">
                {renderLinks(competition.meParticipant.submissionRepoHtmlUrl)}
            </div>
        );
    };

    return (
        <StyledSection title="Repository">
            <div className="flex justify-center gap-4 text-center">{renderJoinButton()}</div>
        </StyledSection>
    );
};

const http2ssh = (htmlUrl: string) => htmlUrl.replace('https://github.com/', 'git@github.com:');

// TODO remove
void `.cloneUrl {
    display: inline-block;
    background: white;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0 0 0 2px #b1a9a9;
}
`;
