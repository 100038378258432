import { isValid } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

const SECOND_MS = 1e3;
const MINUTE_MS = 60 * SECOND_MS;
const HOUR_MS = MINUTE_MS * 60;
const DAY_MS = HOUR_MS * 24;

const genZeroDistance = () => ({
    allMillis: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isPast: false,
    isFuture: false,
    renderCount: 0,
});

type Distance = ReturnType<typeof genZeroDistance>;

export const useTimeDistance = ({
    toDate,
    stopOnPast = true,
    // for testing purposes, configure current date to calculate distance from
    mockCurrentDate,
}: { toDate?: string; stopOnPast?: boolean; mockCurrentDate?: string | number } = {}) => {
    const [renderCount, setRenderCount] = useState(1);

    useEffect(() => {
        if (!toDate) {
            return;
        }
        const endDate = new Date(toDate);

        const timer = setInterval(() => {
            const now =
                mockCurrentDate !== undefined ? new Date(mockCurrentDate).getTime() : Date.now();
            const allMillis = endDate.getTime() - now;
            const isPast = allMillis <= 0;

            setRenderCount(i => i + 1);

            if (stopOnPast && isPast) {
                cleanup();
            }
        }, SECOND_MS);

        const cleanup = () => {
            clearInterval(timer);
        };

        return cleanup;
    }, [toDate, stopOnPast, mockCurrentDate]);

    const memo = useMemo<Distance>(() => {
        if (!toDate) {
            return genZeroDistance();
        }
        const endDate = new Date(toDate);
        if (!isValid(endDate)) {
            return genZeroDistance();
        }
        const now =
            mockCurrentDate !== undefined ? new Date(mockCurrentDate).getTime() : Date.now();
        const allMillis = endDate.getTime() - now;
        const isPast = allMillis <= 0;
        const isFuture = !isPast;

        let rest = allMillis;
        const roundMethod = isPast ? Math.ceil : Math.floor;
        const floatDays = rest / DAY_MS;
        const days = roundMethod(floatDays);
        rest -= days * DAY_MS;
        const hours = roundMethod(rest / HOUR_MS);
        rest -= hours * HOUR_MS;
        const minutes = roundMethod(rest / MINUTE_MS);
        rest -= minutes * MINUTE_MS;
        const seconds = roundMethod(rest / SECOND_MS);

        if (isPast && stopOnPast) {
            return genZeroDistance();
        }

        return {
            allMillis,
            days,
            hours,
            minutes,
            seconds,
            isPast,
            isFuture,
            //   renderCount included so we can use it as dependency of useMemo
            renderCount,
        };
    }, [toDate, renderCount, stopOnPast, mockCurrentDate]);

    return memo;
};
