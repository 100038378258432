import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { HostExecaApi } from 'domain/host-execa/hostExeca.api';
import { StyledInput } from 'components/styled-input/StyledInput';
import { StyledSection } from 'layout/StyledSection';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { clsxm } from 'utils/clsxm';
import { StyledButton } from 'components/form/StyledButton';
import { round } from 'utils/number.util';

export const AdminExecaSection: React.FC = () => {
    const [slice, setSlice] = useState(PAGE_SIZE);
    const [mountTime] = useState(Date.now());
    const queryClient = useQueryClient();
    const { register, handleSubmit } = useForm<FormType>();

    const {
        data: { data, contentLength = 0 } = {},
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [QUERY_HISTORY_KEY],
        queryFn: async () => HostExecaApi.getHistory(),
        retry: false,
    });

    const execMutation = useMutation({
        mutationFn: async (form: FormType) => {
            return (
                HostExecaApi.exec({
                    cmd: form.cmd,
                    // options: {
                    //     shell: true,
                    // },
                })
                    // ignore exec errors
                    .catch(e => void e)
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries([QUERY_HISTORY_KEY]);
        },
    });

    const clearMutation = useMutation({
        mutationFn: async () => {
            return (
                HostExecaApi.clear()
                    // ignore exec errors
                    .catch(e => void e)
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries([QUERY_HISTORY_KEY]);
        },
    });

    const submitHandler = (form: FormType) => {
        if (!form.cmd) {
            return;
        }
        execMutation.mutate(form);
    };

    const hasMoreHistory = data ? data.list.length > slice : false;
    const historySize = data?.list.length || 0;
    const friendlyContentLength = round(contentLength / 1024, 1);

    return (
        <StyledSection title="Host Execa">
            <div className="bg-yellow-300 p-2">WARNING: proceed with caution.</div>
            <form onSubmit={handleSubmit(submitHandler)} className="flex items-end gap-2 py-2">
                <StyledButton loading={execMutation.isLoading} type="submit">
                    run
                </StyledButton>
                <StyledInput
                    label="cmd"
                    placeholder="e.g. ls"
                    {...register('cmd')}
                    className="w-full"
                    rootClassName="flex-1"
                />
            </form>
            <div className="flex flex-col gap-4 py-4">
                <div className="flex items-center gap-4">
                    {historySize} logs
                    <div>{friendlyContentLength} KB</div>
                    <StyledButton
                        onClick={() => clearMutation.mutate()}
                        loading={clearMutation.isLoading}
                        disabled={isLoading || !historySize}>
                        clear
                    </StyledButton>
                    <StyledButton onClick={() => refetch()} loading={isLoading}>
                        refetch
                    </StyledButton>
                </div>
                {data &&
                    data.list.slice(0, slice).map(e => {
                        const fullContent = e.error ? e.error : e.response ? e.response.stdout : '';
                        const chunks = fullContent.split('\n');
                        const hasMore = chunks.length > 3;
                        const shortContent = hasMore ? chunks.slice(0, 3).join('\n') : fullContent;
                        return (
                            <div key={e.timestamp} className="ring-1 ring-gray-200">
                                <div className="flex gap-2 bg-slate-700 p-2 pr-20 text-slate-100">
                                    {e.request.cmd}{' '}
                                    {Boolean(e.request.arguments) && (
                                        <div className="text-slate-200">
                                            {e.request.arguments?.join(' ')}
                                        </div>
                                    )}
                                    <div className="px-2 text-slate-500">{e.duration} ms</div>
                                </div>
                                <ExpandableContent
                                    ShortContent={
                                        <>
                                            <pre
                                                className={clsxm(
                                                    'overflow-auto p-2',
                                                    e.error && 'bg-red-400',
                                                )}>
                                                {shortContent}
                                            </pre>
                                        </>
                                    }
                                    FullContent={
                                        <>
                                            <pre
                                                className={clsxm(
                                                    'overflow-auto p-2',
                                                    e.error && 'bg-red-400',
                                                )}>
                                                {fullContent}
                                            </pre>
                                        </>
                                    }
                                    hasMore={hasMore}
                                    initialExpanded={e.timestamp > mountTime}
                                />
                            </div>
                        );
                    })}
                {hasMoreHistory && (
                    <div
                        className="cursor-pointer bg-slate-300 p-2 text-center"
                        onClick={() => setSlice(slice + PAGE_SIZE)}
                        aria-hidden>
                        show {PAGE_SIZE} more
                    </div>
                )}
            </div>
        </StyledSection>
    );
};

type FormType = {
    cmd: string;
};

const PAGE_SIZE = 10;
const QUERY_HISTORY_KEY = 'HostExecaApi.getHistory';

const ExpandableContent: React.FC<{
    ShortContent: React.ReactNode;
    FullContent: React.ReactNode;
    hasMore: boolean;
    initialExpanded?: boolean;
}> = ({ ShortContent, FullContent, hasMore, initialExpanded = false }) => {
    const [expanded, setExpanded] = useState(initialExpanded);
    return (
        <div className={clsxm('relative')}>
            {expanded ? FullContent : ShortContent}
            {hasMore && (
                <div
                    onClick={() => setExpanded(!expanded)}
                    aria-hidden
                    className={clsxm(
                        'absolute -top-9 right-2 cursor-pointer p-1 text-sky-600 underline',
                    )}>
                    {expanded ? 'collapse' : 'expand'}
                </div>
            )}
        </div>
    );
};
