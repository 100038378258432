import { useState, useEffect } from 'react';
import { AuthorizedSocket } from './types';
import { IS_DEV_MODE, typedEnv } from '../typed-env';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../store/auth/selectors';
import io from 'socket.io-client';
import { socketSingleton } from './socket-singleton';
import { toast } from 'react-toastify';

export const useSocket = () => {
    const [isConnected, setIsConnected] = useState(false);
    const [socket, setSocket] = useState<AuthorizedSocket>();
    const token = useSelector(selectAuthToken);

    useEffect(() => {
        const cached = socketSingleton.getSocket(
            token,
            () =>
                io(typedEnv.REACT_APP_API_URL, {
                    transports: ['websocket'],
                    query: `token=${token}`,
                }) as SocketIOClient.Socket,
        );

        setSocket(cached);

        if (cached) {
            setIsConnected(cached.connected);
            cached
                .on('connect', () => {
                    setIsConnected(true);
                })
                .on('exception', (data: any) => {
                    if (IS_DEV_MODE) {
                        toast.error('WS_EXCEPTION');
                    }
                    // TODO we shall not receive exceptions event because it means unhandled error actually because of lack of context of error
                    console.error('WS_EXCEPTION', data);
                })
                .on('disconnect', () => {
                    setIsConnected(false);
                });
            const unmount = socketSingleton.mount();
            return () => unmount();
        }
    }, [token]);

    return { socket, isConnected };
};
