import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { StyledSection } from 'layout/StyledSection';

export const UISectionPlaceholder: React.FC = () => {
    return (
        <StyledSection title="Placeholder Box">
            <div className="flex flex-col gap-4">
                <PlaceholderBox className="ring" loading />
                <PlaceholderBox className="ring" error={new Error('Something went wrong')} />
                <PlaceholderBox className="ring" noData />
                <PlaceholderBox className="ring" noSearch />
            </div>
        </StyledSection>
    );
};
