import React from 'react';
import { CompetitionList } from './CompetitionList';
import { useAsync } from 'react-use';
import { CompetitionApi } from '../../../api/competition';
import { StyledSection } from 'layout/StyledSection';
import { SectionContainer } from 'layout/SectionContainer';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { animationSleep } from 'utils/async.util';

export const CompetitionsMyActivePage: React.FC = () => {
    const {
        loading,
        error,
        value: page,
    } = useAsync(async () => animationSleep(CompetitionApi.getMyActive()), []);

    const render = () => {
        if (loading || error || !page) {
            return <PlaceholderBox loading={loading} error={error} noData={!page} />;
        }
        return <CompetitionList page={page} />;
    };

    return (
        <SectionContainer>
            <StyledSection title="My Active Competitions">{render()}</StyledSection>
        </SectionContainer>
    );
};
