import { ResponseCompetitionAdvancedInfoDto } from 'api/competition/dto';
import { StyledSection } from 'layout/StyledSection';
import { CountdownTimer } from 'components/timer/CountdownTimer';
import { ChallengeTitle } from 'domain/competition/ChallengeTitle';
import { calcStatusAndLocationDetails } from 'domain/competition/competition.util';
import { clsxm } from 'utils/clsxm';

// TODO handle different event types
// const BADGE_PROPS = { asEventType: 'TypeScript' } as const;
// TODO enum for event type
const BADGE_PROPS = { asEventType: 'JavaScript' } as const;

export const CompetitionSectionOverview: React.FC<{
    info: Pick<
        ResponseCompetitionAdvancedInfoDto,
        'startDate' | 'expireDate' | 'title' | 'description'
    >;
    // testing for preview mode during competition creation
    mockCurrentDate?: string | number;
}> = ({ info, mockCurrentDate }) => {
    const { renderWhenDetails, renderWhereDetails } = calcStatusAndLocationDetails({
        startDate: info.startDate,
        endDate: info.expireDate,
    });

    return (
        <StyledSection
            title="Event Overview"
            className="relative"
            badgeProps={BADGE_PROPS}
            // TODO enum for event type
            color="javascript">
            <ChallengeTitle title={info.title} />
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                <DescriptionRenderer description={info.description} />
                <div className="flex flex-col lg:col-span-2 lg:items-start">
                    <CountdownTimer
                        startDate={info.startDate}
                        endDate={info.expireDate}
                        mockCurrentDate={mockCurrentDate}
                    />
                    <div className="mx-auto grid max-w-xl grid-cols-1 gap-2 py-4 text-center md:grid-cols-2 lg:mx-0">
                        <div>
                            <div className="text-xl font-bold">Where?</div>
                            {renderWhereDetails()}
                        </div>
                        <div>
                            <div className="text-xl font-bold">When?</div>
                            {renderWhenDetails()}
                        </div>
                    </div>
                </div>
            </div>
        </StyledSection>
    );
};

const DescriptionRenderer: React.FC<{
    description?: string;
}> = ({ description }) => {
    return (
        <div
            className={clsxm('py-4', !description && 'text-slate-400')}
            dangerouslySetInnerHTML={{
                __html: description || 'See challenge details in repo README',
            }}
        />
    );
};
