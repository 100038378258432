import { z } from 'zod';
import { ZodRefines } from 'utils/zod.utils';

export const ProfileSchema = z
    .object({
        firstName: z.string(),
        lastName: z.string(),
        birthDate: z
            .string()
            .nullable()
            .refine(
                ZodRefines.isValidDateStringOptional.refineBirthdate,
                ZodRefines.isValidDateStringOptional.message,
            ),
        gender: z.string(),
        genderNote: z.string().optional(),
        locationDescription: z.string().optional().default(''),
        locationPlaceId: z.string().optional().default(''),
        city: z.string(),
        region: z.string(),
        country: z.string(),
        mainFramework: z.string(),
        frameworkNote: z.string().optional(),
        typescriptIsMainLanguage: z.boolean(),
        programmingLanguageNote: z.string(),
        typescriptLevel: z.string(),
        firstPaidDate: z
            .string()
            .nullish()
            .refine(
                ZodRefines.isValidDateStringOptional.refine,
                ZodRefines.isValidDateStringOptional.message,
            ),
        jobTitle: z.string(),
        openToWork: z.boolean(),
        salaryExpectationLow: z.preprocess(v => Number(v) || 0, z.number()).optional(),
        salaryExpectationHigh: z.preprocess(v => Number(v) || 0, z.number()).optional(),
        salaryUsd: z.preprocess(v => Number(v) || 0, z.number()).optional(),
        companySize: z.string(),
        companyName: z.string(),
        operatingSystem: z.string(),
        osNote: z.string().optional(),
        ide: z.string(),
        ideNote: z.string().optional(),
        // DTO fields, not part of form
        updatedDate: z.string().nullish(),
    })
    .superRefine((form, ctx) => {
        const keyLow = 'salaryExpectationLow';
        const keyHigh = 'salaryExpectationHigh';
        const low = form[keyLow] || 0;
        const high = form[keyHigh] || low;

        if (low > high) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'higher than "max"',
                path: [keyLow],
            });
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'lower than "min"',
                path: [keyHigh],
            });
        }
    });

export type ProfileSchema = z.infer<typeof ProfileSchema>;
