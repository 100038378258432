// TODO export from domain specific utils
export const createFrientlyRepoTitle = (str: string) => str.replace(/\W+/g, ' ').trimLeft();

export const maybeShrinkText = (text: string | undefined, maxLen: number, ellipsis = '...') => {
    const endLength = ellipsis.length;
    if (!text) {
        return '';
    }
    if (text.length > maxLen) {
        return text.slice(0, maxLen - endLength) + ellipsis;
    }
    return text;
};
