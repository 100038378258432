import { formatInTimeZone } from 'date-fns-tz';

export const isFirstDateMoreRecent = (firstDate: string | number, otherDate: string | number) =>
    new Date(firstDate).getTime() > new Date(otherDate).getTime();

export const isDateExpired = (date: string | number, mockCurrentDate?: string | number) => {
    const now =
        (mockCurrentDate !== undefined ? new Date(mockCurrentDate).getTime() : Date.now()) + 1e3;
    return isFirstDateMoreRecent(now, date);
};

export const formatInUkraineTz = (date: number | Date, template: string, suffix = ` 'Kyiv'`) =>
    formatInTimeZone(date, 'Europe/Kyiv', `${template}${suffix}`);
