import { ReactComponent as TelegramSvg } from 'assets/logo/telegram.svg';

export const FollowTelegramChannel: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center gap-4 text-center text-slate-400">
            <div>Join us on Telegram</div>
            <a
                className="inline-flex items-center justify-center gap-2 p-2 text-sky-600 hover:bg-sky-100"
                href="https://t.me/targer_dev"
                target="_blank"
                rel="noreferrer">
                <TelegramSvg width={32} height={32} />
                <div>@targer_dev</div>
            </a>
        </div>
    );
};
