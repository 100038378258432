import React, { useEffect, useMemo, useState } from 'react';
import { CompetitionApi } from '../../../api/competition';
import { useAsyncFn } from 'react-use';
import { StyledButton } from '../../../components/form/StyledButton';
import { Leaderboard } from './leaderboard';
import { RequirePermissions } from '../../../hoc';
import { TicketStatus, UserPermission } from '../../../types';
import { useStringParams } from '../../../hooks/router';
import { TicketApi } from '../../../api/ticket/ticket.api';
import { isDateExpired } from '../../../utils/date.util';
import { StyledSection } from '../../../layout/StyledSection';
import { CompetitionSectionOverview } from './sections/CompetitionSectionOverview';
import { useMutation } from '@tanstack/react-query';
import { useRerenderAt } from 'hooks/useRerenderAt.hook';
import { CompetitionSectionRepo } from './sections/CompetitionSectionRepo';
import { SectionContainer } from 'layout/SectionContainer';
import { IoTicketOutline } from 'react-icons/io5';
import { useAuthState } from 'auth/useAuthState.hook';
import { MaybeScrollOnRender } from 'components/ux/MaybeScrollOnRender';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { animationSleep } from 'utils/async.util';
import { Helmet } from 'react-helmet';
import { Ticket } from 'domain/competition/Ticket';
import { ID_FOR_SCROLL_TO_TICKET_SECTION } from 'domain/ticket/ticket.constant';
import { CompetitionPrepareYourselfSection } from './sections/CompetitionPrepareYourselfSection';
import { IS_DEV_MODE } from 'typed-env';
import { clsxm } from 'utils/clsxm';
import { StyledTooltip } from 'components/styled-tooltip/StyledTooltip';
import { EarlyBirdPurpleSvg } from 'domain/ticket/assets';
import { useAdminLocalConfig } from 'pages/dev-kit/-page/useAdminLocalConfig.hook';
import { HowItWorksWidget } from 'pages/-shared/HowItWorksWidget';
import { EPromoType, PromoZod } from 'domain/promo/promo.schema';
import { PromoForm } from 'domain/promo/PromoForm';
import { AdminPromosSection } from './sections/AdminPromosSection';
import { AdminCompetitionControlsSection } from './sections/AdminCompetitionControlsSection';

// TODO handle all async errors and loadings
// TODO refactor useAsyncFn to react query
export const CompetitionInfoPage: React.FC = () => {
    const { competitionId } = useStringParams();
    const user = useAuthState();
    const [infoCompetition, callInfoCompetition] = useAsyncFn(async () => {
        if (!competitionId) {
            return;
        }
        return await animationSleep(CompetitionApi.getById(competitionId));
    }, [competitionId]);

    const [{ value: tickets, error: ticketsError }, refreshTickets] = useAsyncFn(async () => {
        if (!competitionId) {
            return;
        }
        return await TicketApi.getCompetitionStats(competitionId);
    }, [competitionId]);

    const [{ value: myTicket }, refreshMyTicket] = useAsyncFn(async () => {
        if (!competitionId || !user) {
            return;
        }
        return await TicketApi.findMyTicket(competitionId);
    }, [competitionId, user]);

    const [{ loading: infoLoading }, refresh] = useAsyncFn(async () => {
        await Promise.all([callInfoCompetition(), refreshTickets(), refreshMyTicket()]);
    }, [callInfoCompetition, refreshTickets, refreshMyTicket]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        if (!myTicket || !myTicket.ticket) {
            return;
        }
        const { paymentStatus } = myTicket.ticket;
        if (!paymentStatus || paymentStatus === 'failure') {
            return;
        }
        if (paymentStatus === 'success') {
            return;
        }
        const interval = setInterval(() => {
            // TODO invalidateQuery when refactored to react-query
            refreshMyTicket();
        }, 9e3);

        return () => {
            clearInterval(interval);
        };
    }, [myTicket, refreshMyTicket]);

    const [promo, setPromo] = useState<PromoZod>();

    const checkoutMutation = useMutation(
        async () => {
            if (!competitionId) {
                return;
            }
            const promoCode = promo?.code;
            return await TicketApi.requestPurchase({ competitionId, promoCode });
        },
        {
            onSuccess() {
                refresh();
            },
        },
    );
    const invoice = checkoutMutation.data;

    const purchaseFreeTicket = useMutation(
        async () => {
            if (!competitionId) {
                return;
            }
            return await TicketApi.purchaseFreeTicket(competitionId);
        },
        {
            onSuccess() {
                refresh();
            },
        },
    );

    const competition = infoCompetition.value;

    const memoNearestFutureDate = useMemo(() => {
        if (!competition) {
            return;
        }
        const { startDate, expireDate } = competition;
        const now = Date.now();
        const start = startDate ? new Date(startDate).getTime() : now;
        const end = expireDate ? new Date(expireDate).getTime() : now;
        return Math.min(...[start, end].filter(e => e >= now));
    }, [competition]);

    useRerenderAt(memoNearestFutureDate);

    const [adminConfig] = useAdminLocalConfig();
    const isAdminLeaderboardEnabled = adminConfig.adminLeaderboard;
    const isAdminPromoEnabled = adminConfig.adminPromos;

    const [isPromoOpen, setIsPromoOpen] = useState(false);

    // ---------------------- end of hooks

    if (invoice) {
        window.location.href = invoice.invoiceUrl;
    }

    if (!competitionId) {
        return (
            <SectionContainer>
                <StyledSection title="Loading">
                    <PlaceholderBox error={new Error('Competition ID not missing')} />
                </StyledSection>
            </SectionContainer>
        );
    }

    if (!competition || !tickets || infoCompetition.error || ticketsError) {
        return (
            <SectionContainer>
                <StyledSection title="Loading">
                    <PlaceholderBox
                        loading={!infoCompetition.error}
                        error={infoCompetition.error || ticketsError}
                    />
                </StyledSection>
            </SectionContainer>
        );
    }

    if (IS_DEV_MODE) {
        console.log(competition);
    }

    const isCompetitionExpired = isDateExpired(competition.expireDate);
    const iHavePurchasedTicket = myTicket?.ticket?.status === TicketStatus.PAID;
    const isFreeCompetition = !competition.priceCoin;

    const renderTicketButton = (params?: {
        paymentStatus?:
            | ''
            | 'created'
            | 'processing'
            | 'hold'
            | 'success'
            | 'failure'
            | 'reversed'
            | 'expired';
    }) => {
        const calcDisabledCases = () => {
            if (!tickets?.ticketsAvailable) {
                return true;
            }
            if (!myTicket || infoLoading || Boolean(invoice)) {
                return true;
            }
            if (params?.paymentStatus === 'processing') {
                return true;
            }
            return false;
        };

        if (iHavePurchasedTicket) {
            return <div className="text-slate-400">You have the ticket 🙌</div>;
        }

        const isLoading =
            purchaseFreeTicket.isLoading || checkoutMutation.isLoading || Boolean(invoice);

        const getButtonText = () => {
            if (isFreeCompetition) {
                return 'Get Free Ticket';
            }
            const { paymentStatus } = params || {};
            if (paymentStatus === 'created') {
                return 'Complete Payment';
            }
            if (paymentStatus === 'processing') {
                return 'Loading...';
            }
            return 'Buy Ticket Now';
        };

        const shouldShowButton = !isPromoOpen || Boolean(params?.paymentStatus);

        const promoCode = myTicket?.ticket?.promoCode || promo?.code;
        const promoType = myTicket?.ticket?.promoType || promo?.type;

        return (
            <div className="flex flex-col items-center gap-4">
                {promoCode && (
                    <div className="flex flex-col gap-2 text-center">
                        <div className="text-sm text-gray-400">applied promo:</div>
                        <div className="flex flex-wrap justify-center gap-2">
                            <b>{promoCode}</b>
                            {promoType === EPromoType.EXACT_USD_COIN && (
                                <div className="text-gray-400">fixed price 😎</div>
                            )}
                        </div>
                    </div>
                )}
                {!promoCode && !isFreeCompetition && !params?.paymentStatus && (
                    <PromoForm
                        open={isPromoOpen}
                        setOpen={setIsPromoOpen}
                        competitionId={competitionId}
                        onPromoChange={promo => setPromo(promo)}
                    />
                )}
                {shouldShowButton && (
                    <StyledButton
                        requireAuth={!user}
                        scrollToOnAuth={ID_FOR_SCROLL_TO_TICKET_SECTION}
                        onClick={() => {
                            isFreeCompetition
                                ? purchaseFreeTicket.mutate()
                                : checkoutMutation.mutate();
                        }}
                        loading={isLoading}
                        disabled={calcDisabledCases()}>
                        {getButtonText()}
                    </StyledButton>
                )}
            </div>
        );
    };

    const isMyTicketPaid = myTicket?.ticket?.status === TicketStatus.PAID;

    const renderTicketSection = () => {
        if (isCompetitionExpired && !iHavePurchasedTicket) {
            return null;
        }

        const renderTicketStats = () => {
            if (isDateExpired(competition.expireDate)) {
                return null;
            }
            const totalTicketsAvailable =
                (tickets?.ticketsAvailable || 0) + (tickets?.ticketsPending || 0);
            return (
                <div className="inline-flex items-end gap-2 md:items-center">
                    <IoTicketOutline size={48} />
                    <div className="text-5xl font-bold">{totalTicketsAvailable}</div>
                    <div className="text-xl text-slate-400">/ {tickets.ticketsTotal}</div>
                    <div>tickets left</div>
                </div>
            );
        };

        const ticket = myTicket?.ticket;
        const paymentFailure = ticket?.paymentFailureReason;
        const isPaymentCreated = ticket?.paymentStatus === 'created' && !paymentFailure;
        const isPaymentProcessing =
            ticket?.paymentStatus && ticket.paymentStatus !== 'success' && !paymentFailure;

        return (
            <StyledSection
                id={ID_FOR_SCROLL_TO_TICKET_SECTION}
                title="Ticket"
                className="relative overflow-x-clip"
                color="emerald"
                badgeProps={{
                    asTicketPrice: competition.priceCoin,
                    appliedPromo: promo,
                    myTicket,
                }}>
                <div>
                    <div className="flex flex-col items-center gap-4">
                        {adminConfig.adminTickets && <div>{JSON.stringify(tickets, null, 4)}</div>}
                        {/* TODO improve error details */}
                        {infoCompetition.error && <div>Unknown error. Try to refresh page.</div>}
                        <div className="flex flex-col place-items-center justify-center gap-4 pr-0 md:flex-row md:pr-16">
                            {renderTicketStats()}
                            <div>
                                {renderTicketButton({ paymentStatus: ticket?.paymentStatus })}
                            </div>
                        </div>
                        {invoice && <div>Redirecting to payment page...</div>}
                        {isPaymentProcessing && (
                            <div className="bg-sky-200 p-2">
                                {isPaymentCreated
                                    ? '⏳ payment pending'
                                    : '⏳ payment processing...'}
                            </div>
                        )}
                        {paymentFailure && (
                            <div className="bg-red-400 p-2">
                                <b>Payment failed with next error from bank:</b>
                                <br />
                                {/* TODO translate to ENG for consistency */}
                                {paymentFailure}
                                <br />
                                <b>Please, try again.</b>
                            </div>
                        )}
                        {null && !isFreeCompetition && !iHavePurchasedTicket && (
                            /* TODO track competition.saleStage === early_bird */
                            <StyledTooltip title="Price will rise soon. Buy now.">
                                <EarlyBirdPurpleSvg
                                    className={clsxm(
                                        'absolute left-4 top-12 h-24 w-24',
                                        'md:h-36 md:w-36',
                                    )}
                                />
                            </StyledTooltip>
                        )}
                        <Ticket
                            myTicket={myTicket}
                            tickets={tickets}
                            competition={competition}
                            appliedPromo={promo}
                        />
                    </div>
                </div>
            </StyledSection>
        );
    };

    return (
        <SectionContainer>
            <HowItWorksWidget />
            <Helmet>
                {/* TODO reuse title with app suffix */}
                <title>{competition.title} | Targer</title>
                {/* TODO */}
                <meta property="og:title" content="__OG_TITLE__" />
                <meta property="og:description" content="__OG_DESCRIPTION__" />
            </Helmet>
            <MaybeScrollOnRender />
            <CompetitionSectionOverview info={competition} />
            {isMyTicketPaid ? (
                <CompetitionSectionRepo competition={competition} fullRefresh={refresh} />
            ) : (
                <CompetitionPrepareYourselfSection />
            )}
            {competition.meParticipant && (
                <StyledSection title="Live Leaderboard">
                    <Leaderboard competitionId={competitionId} competition={competition} />
                </StyledSection>
            )}
            {/* 
            TODO show Submission timeline section for particular participant.
            Timeline with errors and live statuses icons as on leaderboard.
            Visible errors will be shown here for debug and intuition of participants.
             */}
            {renderTicketSection()}
            {isMyTicketPaid && <CompetitionPrepareYourselfSection />}
            {isAdminLeaderboardEnabled && (
                <RequirePermissions atLeast={UserPermission.ADMIN}>
                    {!competition.meParticipant && (
                        <StyledSection title="Admin Live Leaderboard">
                            <Leaderboard competitionId={competitionId} competition={competition} />
                        </StyledSection>
                    )}
                    {/* TODO */}
                    {/* <StyledSection title="Admin stats">
                        <p>list of all participants including without submissions</p>
                        <p>select participant = list of submmissions by participant</p>
                        <p>select submission = list of test cases in submission</p>
                    </StyledSection> */}
                </RequirePermissions>
            )}
            {isAdminPromoEnabled && <AdminPromosSection competitionId={competitionId} />}
            <AdminCompetitionControlsSection competitionId={competitionId} />
        </SectionContainer>
    );
};
