import { v } from 'overshom-valid';
import { basePaginatedDtoBuilder } from '../helper';
import { CompetitionAccessType, CompetitionStatus } from '../../types';

const CompetitionSchema = () => ({
    id: v.String(),
    createdById: v.String(),
    title: v.String(),
    description: v.String(),
    repoOwnerGithubId: v.Number(),
    sourceRepoHtmlUrl: v.String(),
    accessType: v.Enum(CompetitionAccessType),
    status: v.Enum(CompetitionStatus),
    priceCoin: v.Number(),
    startDate: v.String(),
    expireDate: v.String(),
});

export class ResponseCompetitionDto extends v.class(CompetitionSchema()) {}

export class ResponseCompetitionAdvancedInfoDto extends v.class({
    ...CompetitionSchema(),
    meParticipant: v
        .Object({
            id: v.String(),
            userId: v.String(),
            competitionId: v.String(),
            inveteId: v.String().optional(),
            submissionRepoId: v.Number(),
            submissionRepoHtmlUrl: v.String(),
        })
        .optional(),
}) {}

export class ResponsePaginatedCompetitionDto extends basePaginatedDtoBuilder(CompetitionSchema()) {}
