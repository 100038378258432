import React from 'react';
import { CompetitionList } from '../competitions/-page/CompetitionList';
import { CompetitionApi } from 'api/competition';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { useAsync } from 'react-use';
import { animationSleep } from 'utils/async.util';
import { FollowTelegramChannel } from 'branding/FollowTelegramChannel';

export const CompetitionsPublicExplore: React.FC = () => {
    const {
        loading,
        error,
        value: page,
    } = useAsync(async () => animationSleep(CompetitionApi.getPublicExplore()), []);

    const render = () => {
        if (loading || error || !page) {
            return <PlaceholderBox loading={loading} error={error} noData={!page} />;
        }
        return page.total > 0 ? (
            <CompetitionList page={page} />
        ) : (
            <p className="py-16 text-center">We are working on new challenges for you 💪</p>
        );
    };

    return (
        <div className="flex flex-col items-center justify-center gap-8">
            {render()}
            <FollowTelegramChannel />
        </div>
    );
};
