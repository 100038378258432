import { ResponseMyTicketZod } from 'api/ticket/ticket.dto';
import { LogoTypeScript, LogoJavaScript } from 'assets/logo/svg-logos';
import { StyledTooltip } from 'components/styled-tooltip/StyledTooltip';
import { EPromoType, PromoZod } from 'domain/promo/promo.schema';
import { ConditionalWrapper } from 'hoc/ConditionalWrapper';
import { useIsMobile } from 'hooks/useIsMobile.hook';
import { clsxm } from 'utils/clsxm';
import { convertCoin2Unit } from 'utils/currency.util';

const clipStyle: React.CSSProperties = {
    clipPath: 'polygon(0 0, 100% 0%, 100% 100%)',
};

/**
 * Badge displayed as right corner triangle on desktop and simple box area on mobile
 */
export const TriangleBadge: React.FC<{
    // TODO more types: ML, Data Science
    // TODO enum for event type
    asEventType?: 'TypeScript' | 'JavaScript';
    asTicketPrice?: number;
    wrapClassName?: string;
    small?: boolean;
    appliedPromo?: PromoZod;
    myTicket?: ResponseMyTicketZod;
}> = ({ asEventType, asTicketPrice, wrapClassName, small = false, appliedPromo, myTicket }) => {
    const isMobile = useIsMobile();

    const getContent = () => {
        let wrapStyles = '';
        let content: null | JSX.Element = null;
        const res = () => ({ wrapStyles, content });
        if (asEventType !== undefined) {
            if (asEventType === 'TypeScript') {
                wrapStyles /*tw*/ = isMobile ? 'bg-sky-800' : 'from-sky-800 to-sky-600';
                content = (
                    <>
                        {(!small || isMobile) && asEventType}
                        <LogoTypeScript />
                    </>
                );
                return res();
            }
            if (asEventType === 'JavaScript') {
                wrapStyles /*tw*/ = isMobile ? 'bg-yellow-700' : 'from-yellow-700 to-yellow-500';
                content = (
                    <>
                        {(!small || isMobile) && asEventType}
                        <LogoJavaScript />
                    </>
                );
                return res();
            }
        }

        if (asTicketPrice !== undefined) {
            const originalPrice =
                asTicketPrice > 0 ? `$ ${convertCoin2Unit(asTicketPrice)}` : 'FREE';
            let promoPrice = '';
            const promoCode = myTicket?.ticket?.promoCode || appliedPromo?.code;
            if (myTicket?.ticket) {
                promoPrice = `$ ${convertCoin2Unit(myTicket.ticket.priceCoin)}`;
            } else if (appliedPromo) {
                if (appliedPromo.type === EPromoType.EXACT_USD_COIN) {
                    promoPrice = `$ ${convertCoin2Unit(appliedPromo.effectValue)}`;
                } else {
                    promoPrice = '🤷‍♂️';
                }
            }
            wrapStyles /*tw*/ = isMobile ? 'bg-emerald-800' : 'from-emerald-800 to-emerald-600';
            content = (
                <div className="font-semibold">
                    <ConditionalWrapper
                        condition={!small && !isMobile}
                        wrapper={c => (
                            <div className="rounded-xl bg-emerald-800 p-3 ring-2 ring-yellow-300">
                                {c}
                            </div>
                        )}>
                        <ConditionalWrapper
                            condition={Boolean(promoCode)}
                            wrapper={c => (
                                <StyledTooltip title={`applied promo ${promoCode}`}>
                                    <div>
                                        <div className="text-sm text-gray-400">{c} 🙈</div>
                                        <div>{promoPrice} 🏷️</div>
                                    </div>
                                </StyledTooltip>
                            )}>
                            {originalPrice}
                        </ConditionalWrapper>
                    </ConditionalWrapper>
                </div>
            );
            return res();
        }
        wrapStyles /*tw*/ = isMobile ? 'bg-gray-800' : 'from-gray-800 to-gray-600';
        content = <></>;
        return res();
    };

    const { wrapStyles, content } = getContent();
    return (
        <div
            style={!isMobile ? clipStyle : undefined}
            className={clsxm(
                'text-white',
                isMobile ? 'p-1' : 'absolute right-0 top-0 h-60 w-60 bg-gradient-to-b p-16',
                wrapStyles,
                wrapClassName,
            )}>
            <div
                className={clsxm(
                    'flex items-center justify-center gap-2 p-1 text-xl',
                    !isMobile && 'absolute right-0 top-0 h-2/3 w-3/5 flex-col',
                )}>
                {content}
            </div>
        </div>
    );
};
