import { clsxm } from 'utils/clsxm';

export const StyledSwitch: React.FC<{
    checked: boolean;
    onChange: (value: boolean) => void;
    className?: string;
}> = ({ checked, onChange, children, className }) => {
    return (
        <label
            className={clsxm(
                'inline-flex cursor-pointer select-none items-center justify-center gap-2',
                className,
            )}>
            <input
                type="checkbox"
                className="sr-only"
                checked={checked}
                onChange={() => onChange(!checked)}
            />
            <div className="relative inline-block h-6 w-12 cursor-pointer">
                <div
                    className={clsxm(
                        'absolute left-0 top-0 bottom-0 right-0 bg-slate-500 transition',
                        checked && 'bg-sky-600',
                    )}
                />
                <div
                    className={clsxm(
                        'absolute left-1 top-1 h-4 w-4 bg-white transition',
                        checked && 'translate-x-6',
                    )}
                />
            </div>
            {children}
        </label>
    );
};
