interface GithubEntity {
    githubId: number;
    login: string;
    avatarUrl: string;
    url: string;
    type: string;
    name: string;
    blog: string;
    email?: string;
    hireable?: boolean;
    publicRepos: number;
    publicGists: number;
    twoFactorAuthentication: boolean;
    onGithubCreatedDate: string;
    createdDate: string;
}

export enum SubmissionStatus {
    IN_QUEUE = 'IN_QUEUE',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    SKIP = 'SKIP',
    FAIL = 'FAIL',
}

export interface StateSubmissionEntity {
    id: string;
    participantId: string;
    githubId: number;
    competitionId: string;
    commitId: string;
    commitMessage: string;
    score: number;
    status: SubmissionStatus;
    createdDate: string;
    startedAt?: string;
    completedAt?: string;
    github: GithubEntity;
    maxScore: number;
    firstMaxScoreDate: number;
    // properties after enhancion
    isMe?: boolean;
    percentScore?: number;
}

export interface LeaderboardState {
    rankList: StateSubmissionEntity[];
    total: number;
}
