import { ThunkResult } from '../types';
import { getMyUserActions } from './actions';
import { UserApi } from '../../api/user';

export class UserThunk {
    static getMe = (): ThunkResult => async dispatch => {
        dispatch(getMyUserActions.request());
        try {
            const me = await UserApi.getMe();
            dispatch(getMyUserActions.success(me));
        } catch (e) {
            dispatch(getMyUserActions.failure());
        }
    };
}
