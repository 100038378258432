import { useQuery } from '@tanstack/react-query';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { format } from 'date-fns';
import { ProfileSchema } from 'domain/profile/profile.schema';
import { ProfileApi } from 'domain/profile/ProfileApi';
import { SectionContainer } from 'layout/SectionContainer';
import { StyledSection } from 'layout/StyledSection';
import { useMemo } from 'react';

export const AdminAllProfilesPage: React.FC = () => {
    const { isLoading, data } = useQuery({
        // TODO const for key
        queryKey: ['admin-all-profiles'],
        queryFn: async () => {
            return await ProfileApi.getAll();
        },
    });

    return (
        <SectionContainer>
            <StyledSection title="Admin All Profiles">
                {isLoading || !data ? <PlaceholderBox loading /> : <AllProfilesTable data={data} />}
            </StyledSection>
        </SectionContainer>
    );
};

const AllProfilesTable: React.FC<{
    data: {
        items: ProfileSchema[];
        total: number;
    };
}> = ({ data: { items: profiles, total } }) => {
    const cols = useMemo(() => {
        const p = profiles[0];
        if (!p) {
            return [];
        }
        return (Object.keys(p) as string[]).filter(
            k => !['id', 'createdDate'].includes(k) && !/note/i.test(k),
        );
    }, [profiles]);

    if (!profiles.length) {
        return <PlaceholderBox noData />;
    }

    return (
        <div>
            <div>Total: {total}</div>
            <div className="overflow-auto">
                <table className="border-collapse">
                    <thead>
                        <tr>
                            {cols.map(e => (
                                <th key={e}>{e}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {profiles.map((e, i) => {
                            return (
                                <tr key={i}>
                                    {cols.map(c => {
                                        const note = e[`${c}Note` as keyof typeof e] as
                                            | string
                                            | undefined;
                                        let value = e[c as keyof typeof e];
                                        if (typeof value === 'boolean') {
                                            value = value ? 'Yes' : 'No';
                                        }
                                        if (c === 'updatedDate' && typeof value === 'string') {
                                            value = format(new Date(value), 'PP pp');
                                        }
                                        if (!value) {
                                            value = '';
                                        }
                                        value = String(value);
                                        return (
                                            <td
                                                key={c}
                                                className="border-[1px] border-solid border-gray-300 text-center">
                                                {value}
                                                {Boolean(note) && (
                                                    <div className="italic">note: {note}</div>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
