import { ResponseCompetitionAdvancedInfoDto } from 'api/competition/dto';
import React, { useEffect, useState } from 'react';
import { useSocket } from '../../../../hooks/socket';
import { LeaderboardTable } from './table/LeaderboardTable';
import { LeaderboardState } from './types';

export const Leaderboard: React.FC<{
    competitionId: string;
    competition?: ResponseCompetitionAdvancedInfoDto;
}> = ({ competitionId, competition }) => {
    const [state, setState] = useState<LeaderboardState>();
    const { socket, isConnected } = useSocket();

    useEffect(() => {
        if (!socket || !isConnected) {
            setState(undefined);
            return;
        }
        socket.emit('leaderboard_competition_state', { competitionId }, (res: any) => {
            // TODO validate
            setState(res);
        });
    }, [socket, isConnected, competitionId]);

    if (!state) {
        return null;
    }

    return (
        <LeaderboardTable competitionId={competitionId} state={state} competition={competition} />
    );
};
