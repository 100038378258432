import { AppLogoLink } from 'branding/AppLogoLink';
import { FollowTelegramChannel } from 'branding/FollowTelegramChannel';
import { StyledSection } from 'layout/StyledSection';

export const UISectionBranding: React.FC = () => {
    return (
        <StyledSection title="Branding">
            <div className="m-auto flex max-w-xs flex-col gap-4">
                <AppLogoLink className="inline-flex" />
                <AppLogoLink className="inline-flex bg-zinc-900 text-gray-300" />
                <FollowTelegramChannel />
            </div>
        </StyledSection>
    );
};
