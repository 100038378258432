/**
 * Calculate CSSProperties clipPath for chamfered corners of rectangle
 * @param corners array of scales of corners e.g. [0, 0, 1, 1]
 * @param baseChamfer default 6px
 * @param unit px or rem
 */
export const calcChamferedCorners = (corners: number[], baseChamfer = 6, unit = 'px') => {
    const calcChamfer = (scale: number) => `calc(100% - ${baseChamfer * scale}${unit})`;
    const [tl = 0, tr = 0, br = 0, bl = 0] = corners;
    const parts = [
        // top left
        ...(tl ? [`${baseChamfer * tl}${unit} 0`] : [`0 0`]),
        // top right
        ...(tr ? [`${calcChamfer(tr)} 0`, `100% ${baseChamfer * tr}${unit}`] : [`100% 0`]),
        // bottom right
        ...(br ? [`100% ${calcChamfer(br)}`, `${calcChamfer(br)} 100%`] : [`100% 100%`]),
        // bottom left
        ...(bl ? [`${baseChamfer * bl}${unit} 100%`, `0 ${calcChamfer(bl)}`] : [`0 100%`]),
        // top left
        ...(tl ? [`0 ${baseChamfer * tl}${unit}`] : []),
    ];
    const style: React.CSSProperties = {
        clipPath: `polygon(${parts.join(',')})`,
    };
    return style;
};

export const CHAMFER_PRESETS = {
    // TODO use for participants avatars
    hexagon: calcChamferedCorners([1, 1, 1, 1], 25, '%'),
    // TODO more shapes e.g. diamond
} as const;
