import { SubmissionStatus } from '../types';
import { randi } from 'utils/number.util';

const allStatuses = [
    SubmissionStatus.IN_QUEUE,
    SubmissionStatus.IN_QUEUE,
    SubmissionStatus.IN_PROGRESS,
    SubmissionStatus.FAIL,
    SubmissionStatus.COMPLETE,
    SubmissionStatus.COMPLETE,
];

export const getRandomSubmissionStatus = () => {
    return allStatuses[randi(0, allStatuses.length - 1)] ?? SubmissionStatus.IN_QUEUE;
};

export const mockNicknames = [
    'gaearon',
    'bvaughn',
    'sophiebits',
    'zpao',
    'sebmarkbage',
    'acdlite',
    'trueadm',
    'petehunt',
    'chenglou',
    'vjeux',
    'jimfb',
    'jeffmo',
    'subtleGradient',
    'nhunzaker',
    'necolas',
    'benjamn',
    'yungsters',
    'syranide',
    'flarnie',
    'koba04',
    'aweary',
    'cpojer',
    'marocchino',
    'kohei-takata',
    'TheSavior',
    'chicoxyzzy',
    'lunaruan',
    'keyz',
    'NE-SmallTown',
    'mcsheffrey',
    'joshduck',
    'jquense',
    'aickin',
    'Daniel15',
    'philipp-spiess',
    'iamdustan',
    'hellendag',
    'SimenB',
    'threepointone',
    'sompylasar',
    'raphamorim',
    'fabiomcosta',
    'Simek',
    'cody',
    'bgw',
    'arkist',
    'tomocchino',
    'iamchenxin',
    'mroch',
    'bl00mber',
    'ivanzotov',
    'yangshun',
    'rickhanlonii',
    'lucasecdb',
    'kmeht',
    'fisherwebdev',
    'eps1lon',
    'DarkScorpion',
    'yiminghe',
    'rickbeerendonk',
    'raunofreiberg',
    'joecritch',
    'jaredly',
    'hristo-kanchev',
    'fxxkscript',
    'clemmy',
    'andreypopp',
    'RReverser',
    'Andarist',
    'AnSavvides',
    'zertosh',
    'motiz88',
    'kassens',
    'jgebhardt',
    'jddxf',
    'hzoo',
    'elas7',
    'dittos',
    'camsong',
    'bhamodi',
    'SanderSpies',
    'ianobermiller',
    'hramos',
    'andrewdavey',
    'alexeyraspopov',
    'RaitoBezarius',
    'mzabriskie',
    'mxstbr',
    'mmarkelov',
    'makky3939',
    'lyip1992',
    'jontewks',
    'bspaulding',
    'brigand',
    'awwright',
    'alexmckenley',
    'aaronabramov',
    'Pouja',
    'Jessidhia',
    'dependabot[bot]',
];
