import { EnumButtonGroup } from 'components/form/EnumButtonGroup';
import { StyledSection } from 'layout/StyledSection';
import { useMemo, useState } from 'react';
import { AppGeneratedRoutes } from 'router/app-generated-routes';
import { clsxm } from 'utils/clsxm';
import { MOCK_APP_ROUTES } from './data.mock';

interface INode {
    name: string;
    path: string;
    children: INode[];
}

const getHierarchy = (obj: object, parentPath = '') => {
    const root: INode[] = [];
    Object.entries(obj)
        .sort(([aKey], [bKey]) =>
            aKey === 'index' ? -1 : bKey === 'index' ? 1 : aKey.localeCompare(bKey),
        )
        .forEach(([key, value]) => {
            if (typeof value === 'string') {
                root.push({
                    name: key === 'index' ? '/' : value.replace(/.*\//, '') || '/',
                    path: value,
                    children: [],
                });
            }
            if (typeof value === 'object') {
                const path = `${parentPath}/${key}`;
                const rawChildren = getHierarchy(value, path);
                const shouldHideRoot = rawChildren.length === 1;
                const singleRootChild = shouldHideRoot && rawChildren.find(e => e.name === '/');
                root.push({
                    name: key,
                    path: singleRootChild ? singleRootChild.path : path,
                    children: shouldHideRoot
                        ? rawChildren.filter(e => e.name !== '/')
                        : rawChildren,
                });
            }
        });
    return root;
};

const renderLayer = ({
    node,
    layer = 0,
    key = 0,
    siblings = [],
    paddings = [],
}: {
    node: INode;
    layer?: number;
    key?: number;
    siblings?: number[];
    paddings?: number[];
}) => {
    let graph = '';
    for (let i = 0; i < layer; i++) {
        graph += Array(paddings[i] || 0)
            .fill(' ')
            .join('');
        if (siblings.includes(i)) {
            if (layer === i + 1) {
                graph += '├─ ';
            } else {
                graph += '|  ';
            }
        } else {
            if (layer === i + 1) {
                graph += '└─ ';
            } else {
                graph += '   ';
            }
        }
    }
    const hasChildren = node.children.length > 0;
    return (
        <div key={key}>
            {graph}
            {hasChildren ? (
                node.name
            ) : (
                <a href={node.path} className={clsxm('inline-block text-sky-600 hover:underline')}>
                    {node.name}
                </a>
            )}
            {node.children.map((e, i, arr) => {
                const isLastChild = i === arr.length - 1;
                return renderLayer({
                    node: e,
                    layer: layer + 1,
                    key: i,
                    siblings: isLastChild
                        ? siblings.filter(e => e !== layer)
                        : siblings.concat(layer),
                    paddings: paddings.concat(node.name.length - 1),
                });
            })}
        </div>
    );
};

enum EMode {
    ACTUAL = 'ACTUAL',
    MOCK = 'MOCK',
}
export const SitemapDevKitSection: React.FC = () => {
    const [showMode, setShowMode] = useState(EMode.ACTUAL);
    const hierarchy = useMemo(
        () =>
            showMode === EMode.MOCK
                ? getHierarchy(MOCK_APP_ROUTES)
                : // TODO render from nested array from "src/router/app-generated-routes-config.ts"
                  getHierarchy(AppGeneratedRoutes),
        [showMode],
    );

    return (
        <StyledSection title="Sitemap">
            <EnumButtonGroup
                options={[EMode.ACTUAL, EMode.MOCK]}
                value={showMode}
                onChange={v => setShowMode(v)}
            />
            <div className="whitespace-pre font-mono">
                {renderLayer({
                    node: {
                        name: '.',
                        path: '',
                        children: hierarchy,
                    },
                })}
            </div>
        </StyledSection>
    );
};
