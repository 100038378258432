import React from 'react';
import { clsxm } from 'utils/clsxm';
import { StyledLoader } from './loader/StyledLoader';

export const PlaceholderBox: React.FC<{
    loading?: boolean;
    error?: Error | unknown;
    noData?: boolean;
    noSearch?: boolean;
    infoContent?: React.ReactNode;
    className?: string;
    // TODO
    // retry: () => void
}> = ({ loading, error, noData, noSearch, infoContent, className }) => {
    if (loading) {
        return (
            <div className={clsxm('flex min-h-[120px] items-center justify-center', className)}>
                <StyledLoader />
            </div>
        );
    }
    if (error) {
        return (
            <div
                className={clsxm(
                    'flex min-h-[120px] items-center justify-center text-red-500',
                    className,
                )}>
                ERROR: {error instanceof Error ? error.message : 'something went wrong'}
            </div>
        );
    }
    if (noData) {
        return (
            <div
                className={clsxm(
                    'flex min-h-[120px] items-center justify-center text-slate-400',
                    className,
                )}>
                No data to show
            </div>
        );
    }
    if (noSearch) {
        return (
            <div
                className={clsxm(
                    'flex min-h-[120px] items-center justify-center text-slate-400',
                    className,
                )}>
                No search results
            </div>
        );
    }
    if (infoContent) {
        return (
            <div
                className={clsxm(
                    'flex min-h-[120px] items-center justify-center text-slate-400',
                    className,
                )}>
                {infoContent}
            </div>
        );
    }
    return null;
};
