import { zodClass } from 'utils/zod.utils';
import { z } from 'zod';

export enum EPromoType {
    EXACT_USD_COIN = 'EXACT_USD_COIN',
}

export class PromoZod extends zodClass({
    competitionId: z.string(),
    code: z.string(),
    type: z.nativeEnum(EPromoType),
    effectValue: z.number(),
}) {}

export class ResponseMaybePromoZod extends zodClass({
    promo: PromoZod.optional(),
}) {}

class PromoGeneralStatsZod extends zodClass({
    totalTickets: z.number(),
    totalCoins: z.number(),
    pendingTickets: z.number(),
    pendingCoins: z.number(),
    currency: z.string(),
}) {}

export class ResponseCompetitionAllPromoZod extends zodClass({
    items: z.array(PromoZod.extend({ stats: PromoGeneralStatsZod })),
    total: z.number(),
}) {}
