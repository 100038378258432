import { ResponseUserZod } from 'api/user/dto';
import { v } from 'overshom-valid';
import { zodClass } from 'utils/zod.utils';
import { z } from 'zod';
import { TicketStatus } from '../../types';

export class ResponseTicketsCompetitionStatsDto extends v.class({
    ticketsTotal: v.Number(),
    ticketsPaid: v.Number(),
    ticketsPending: v.Number(),
    ticketsAvailable: v.Number(),
}) {}

export class ResponseRequestPurchaseTicketDto extends v.class({
    invoiceUrl: v.String(),
}) {}

export class ResponseTicketZod extends zodClass({
    status: z.nativeEnum(TicketStatus),
    // TODO refactor when zod upgraded to ".coerce" method
    priceCoin: z.preprocess(v => Number(v), z.number()),
    id: z.string(),
    paymentStatus: z.enum([
        '',
        'created',
        'processing',
        'hold',
        'success',
        'failure',
        'reversed',
        'expired',
    ]),
    paymentFailureReason: z.string(),
    promoCode: z.string(),
    promoType: z.string(),
    user: ResponseUserZod.optional(),
    // TODO enum
    currency: z.string(),
    paidDate: z.string().nullish(),
}) {}

export class ResponseMyTicketZod extends zodClass({
    ticket: ResponseTicketZod.optional(),
}) {}

export class ResponseAllTicketsZod extends zodClass({
    items: z.array(ResponseTicketZod),
    total: z.number(),
}) {}
