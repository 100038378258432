import { StyledButton } from 'components/form/StyledButton';
import { StyledInput } from 'components/styled-input/StyledInput';
import { CountdownTimer } from 'components/timer/CountdownTimer';
import { PolyCircle } from 'components/timer/PolyCircle';
import { StyledSection } from 'layout/StyledSection';
import { useState } from 'react';

const genTime = (shift: number) => new Date(new Date().getTime() + shift).toJSON();

const MAX = 120;
export const UISectionTimer: React.FC = () => {
    const [progress, setProgress] = useState('0');
    const [start, setStart] = useState(genTime(5e3));
    const [end, setEnd] = useState(genTime(15e3));
    const [color, setColor] = useState('brown');

    return (
        <StyledSection title="Timer">
            <div className="flex items-center justify-center gap-4">
                <div className="flex flex-col items-end gap-2">
                    <StyledButton onClick={() => setProgress('')}>Clear</StyledButton>
                    <StyledButton
                        onClick={() => setProgress((Math.random() * MAX * 1.2).toFixed(1))}>
                        Randomize
                    </StyledButton>
                    <StyledButton onClick={() => setProgress(`${MAX}`)}>Full</StyledButton>
                    <div className="flex">
                        <StyledInput
                            value={color}
                            onChange={e => setColor(e.target.value)}
                            label="Color"
                            type="color"
                        />
                        <StyledInput
                            value={progress}
                            onChange={e => setProgress(e.target.value)}
                            label="Progress / 120"
                            type="number"
                            step="1"
                        />
                    </div>
                </div>
                <PolyCircle progress={+progress / MAX || 0} className="w-40" color={color} />
            </div>
            <div className="flex items-center">
                <StyledButton onClick={() => setStart(genTime(5e3))}>Reset</StyledButton>
                <div className="flex-1">
                    <StyledInput
                        value={start}
                        onChange={e => setStart(e.target.value)}
                        label="Start Date"
                        className="w-full"
                    />
                </div>
            </div>
            <div className="flex items-center">
                <StyledButton onClick={() => setEnd(genTime(15e3))}>Reset</StyledButton>
                <div className="flex-1">
                    <StyledInput
                        value={end}
                        onChange={e => setEnd(e.target.value)}
                        label="End Date"
                        className="w-full"
                    />
                </div>
            </div>
            <div className="pb-4" />
            <CountdownTimer startDate={start} endDate={end} />
        </StyledSection>
    );
};
