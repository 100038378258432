import { StyledButton } from 'components/form/StyledButton';
import { StyledInput } from 'components/styled-input/StyledInput';
import { StyledSection } from 'layout/StyledSection';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ProfileSchema } from './profile.schema';
import React, { useMemo } from 'react';
import { StyledSwitch } from 'components/form/StyledSwitch';
import { clsxm } from 'utils/clsxm';
import { StyledSelect } from 'components/form/StyledSelect';
import { NATIVE_FORM_CONSTANTS } from 'utils/zod.utils';
import { isFirstDateMoreRecent } from 'utils/date.util';
import { PROFILE_ELIGIBLE_DATE_SINCE, PROFILE_ELIGIBLE_DAYS } from './profile.constant';
import { GooglePlacesAutocomplete } from '../google/GooglePlacesAutocomplete';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import { NumericFormat } from 'react-number-format';
import { ProfileApi } from './ProfileApi';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { IS_DEV_MODE } from 'typed-env';

const GROUP_CONTAINER_STYLE =
    /*tw*/ 'grid gap-6 text-center sm:grid-cols-2 md:grid-cols-3 items-start';
const COLUMN_FIELDS_STYLE = /*tw*/ 'flex flex-col gap-6';

const QUERY_PROFILE = {
    key: 'profile/me',
};

export const ProfileSection: React.FC = () => {
    // TODO extract to reusable query hook
    const { isFetching, data: profile } = useQuery({
        queryKey: [QUERY_PROFILE.key],
        queryFn: async () => {
            return await ProfileApi.getMe();
        },
        // refetchOnMount: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        // staleTime: 10e3,
    });
    if (isFetching && !profile) {
        return (
            <StyledSection title="My Profile">
                <PlaceholderBox loading />
            </StyledSection>
        );
    }
    // console.log('initial profile', profile);
    return <ProfileSectionBody initialProfile={profile || undefined} />;
};

const ProfileSectionBody: React.FC<{
    initialProfile?: ProfileSchema;
}> = ({ initialProfile }) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
        setValue,
        getValues,
        control,
    } = useForm<ProfileSchema>({
        resolver: zodResolver(ProfileSchema),
        defaultValues: initialProfile,
    });

    const {
        data: profile,
        isLoading,
        mutate,
    } = useMutation({
        mutationFn: async (newProfile: ProfileSchema) => {
            return await ProfileApi.saveMe(newProfile);
        },
        onSuccess: data => {
            reset(data);
        },
    });

    const updatedDate = profile?.updatedDate || initialProfile?.updatedDate;

    // TODO refactor to react-query
    const onSubmit: SubmitHandler<ProfileSchema> = data => {
        if (isLoading) {
            return;
        }
        mutate(data);
        // console.log('data', data);
    };
    // console.log('errors', errors);

    const inputRegister: typeof register = (key, ...args) => {
        return {
            ...register(key, ...args),
            errorMessage: errors[key]?.message,
            disabled: isLoading,
        };
    };

    return (
        <StyledSection title="My Profile">
            <InfoBanner profileUpdatedDate={updatedDate} />
            <form onSubmit={handleSubmit(onSubmit)} className={COLUMN_FIELDS_STYLE}>
                <FieldGroup title="General">
                    <div className={GROUP_CONTAINER_STYLE}>
                        <div className={COLUMN_FIELDS_STYLE}>
                            <StyledInput label="First name" {...inputRegister('firstName')} />
                            <StyledInput label="Last name" {...inputRegister('lastName')} />
                            <StyledInput
                                label="Birthdate"
                                {...inputRegister('birthDate')}
                                type="date"
                                min={NATIVE_FORM_CONSTANTS.MIN_BIRTHDATE}
                                max={NATIVE_FORM_CONSTANTS.MAX_BIRTHDATE}
                            />
                        </div>
                        <Controller
                            name="gender"
                            control={control}
                            defaultValue={KEY_OTHER}
                            render={({ field: { ref: _, ...field } }) => (
                                <StyledSelect
                                    asRadio
                                    disabled={isLoading}
                                    label="Gender"
                                    options={GENDER_OPTIONS}
                                    {...field}
                                    bottomContent={
                                        <StyledInput
                                            label="Gender clarification"
                                            {...inputRegister('genderNote')}
                                            placeholder="Clarify, if needed"
                                            className="w-full"
                                        />
                                    }
                                />
                            )}
                        />
                        <div className={COLUMN_FIELDS_STYLE}>
                            <div className="text-sm text-gray-400">Location</div>
                            <Controller
                                name="locationPlaceId"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <GooglePlacesAutocomplete
                                        value={{
                                            label: getValues('locationDescription'),
                                            value: field.value,
                                        }}
                                        onChange={v => {
                                            if (!v || !v.place) {
                                                setValue('city', '');
                                                setValue('region', '');
                                                setValue('country', '');
                                                setValue('locationDescription', '');
                                                setValue('locationPlaceId', '', {
                                                    shouldDirty: true,
                                                });
                                                return;
                                            }
                                            const { place } = v;
                                            if (IS_DEV_MODE) {
                                                console.log('selected location', place);
                                            }
                                            setValue('city', place.city);
                                            setValue('region', place.region);
                                            setValue('country', place.country);
                                            setValue('locationDescription', place.description);
                                            setValue('locationPlaceId', place.placeId, {
                                                shouldDirty: true,
                                            });
                                        }}
                                    />
                                )}
                            />
                            <StyledInput
                                label="City (from search)"
                                {...inputRegister('city')}
                                readOnly
                            />
                            <StyledInput
                                label="Region (from search)"
                                readOnly
                                {...inputRegister('region')}
                            />
                            <StyledInput
                                label="Country (from search)"
                                readOnly
                                {...inputRegister('country')}
                            />
                        </div>
                    </div>
                </FieldGroup>
                <FieldGroup title="Employment (current or latest)">
                    <div className={GROUP_CONTAINER_STYLE}>
                        <div className={COLUMN_FIELDS_STYLE}>
                            <StyledInput
                                label="Job title"
                                {...inputRegister('jobTitle')}
                                placeholder="e.g. Middle Frontend Developer"
                            />
                            <Controller
                                name="salaryUsd"
                                control={control}
                                defaultValue={0}
                                render={({ field: { ref, onChange, ...field }, fieldState }) => (
                                    <NumericFormat
                                        {...field}
                                        label="Salary"
                                        customInput={StyledInput}
                                        value={field.value || ''}
                                        onValueChange={values => {
                                            onChange(Math.max(Number(values.floatValue), 0));
                                        }}
                                        placeholder="$0"
                                        thousandSeparator
                                        errorMessage={fieldState.error?.message}
                                        decimalScale={0}
                                        prefix="$"
                                        disabled={isLoading}
                                        getInputRef={ref}
                                    />
                                )}
                            />
                            <div className="text-gray-400">USD / month</div>
                        </div>
                        <div className={COLUMN_FIELDS_STYLE}>
                            <Controller
                                name="firstPaidDate"
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                    <StyledInput
                                        label="First paid work"
                                        {...field}
                                        value={
                                            value
                                                ? new Date(value)
                                                      .toJSON()
                                                      // slice only yyyy-mm
                                                      .slice(0, 7)
                                                : ''
                                        }
                                        type="month"
                                        min="1970-01"
                                        max={NATIVE_FORM_CONSTANTS.MAX_FIRST_PAID_DATE}
                                    />
                                )}
                            />
                            <Controller
                                name="firstPaidDate"
                                control={control}
                                render={({ field: { value } }) => (
                                    <WorkExperienceConverter sinceDate={value} />
                                )}
                            />
                        </div>
                        <Controller
                            name="companySize"
                            control={control}
                            defaultValue={COMPANY_SIZE_OPTIONS[0]?.key}
                            render={({ field: { ref: _, ...field } }) => (
                                <StyledSelect
                                    label="Company"
                                    disabled={isLoading}
                                    options={COMPANY_SIZE_OPTIONS}
                                    {...field}
                                    bottomContent={
                                        <StyledInput
                                            label="Company name"
                                            {...inputRegister('companyName')}
                                            placeholder="e.g. Targer"
                                            className="w-full"
                                        />
                                    }
                                />
                            )}
                        />
                    </div>
                </FieldGroup>
                <FieldGroup title="Looking for a job?">
                    <div className={GROUP_CONTAINER_STYLE}>
                        <div className={COLUMN_FIELDS_STYLE}>
                            <Controller
                                name="openToWork"
                                control={control}
                                defaultValue={false}
                                render={({ field: { ref: _, ...field } }) => (
                                    <StyledSwitch checked={field.value} onChange={field.onChange}>
                                        Open to work
                                    </StyledSwitch>
                                )}
                            />
                            <div className="text-gray-400">{`Just for statistics. We don't share your personal contacts with recruiters or other companies.`}</div>
                        </div>
                        <div className={COLUMN_FIELDS_STYLE}>
                            <Controller
                                name="salaryExpectationLow"
                                control={control}
                                defaultValue={0}
                                render={({ field: { ref, onChange, ...field }, fieldState }) => (
                                    <NumericFormat
                                        {...field}
                                        label="Expected salary (min)"
                                        customInput={StyledInput}
                                        value={field.value || ''}
                                        onValueChange={values => {
                                            onChange(Math.max(Number(values.floatValue), 0));
                                        }}
                                        placeholder="$0"
                                        thousandSeparator
                                        errorMessage={fieldState.error?.message}
                                        decimalScale={0}
                                        prefix="$"
                                        disabled={isLoading}
                                        getInputRef={ref}
                                    />
                                )}
                            />
                            <Controller
                                name="salaryExpectationHigh"
                                control={control}
                                defaultValue={0}
                                render={({ field: { ref, onChange, ...field }, fieldState }) => (
                                    <NumericFormat
                                        {...field}
                                        label="Expected salary (max)"
                                        customInput={StyledInput}
                                        value={field.value || ''}
                                        onValueChange={values => {
                                            onChange(Math.max(Number(values.floatValue), 0));
                                        }}
                                        placeholder="$0"
                                        thousandSeparator
                                        errorMessage={fieldState.error?.message}
                                        decimalScale={0}
                                        prefix="$"
                                        disabled={isLoading}
                                        getInputRef={ref}
                                    />
                                )}
                            />
                            <div className="text-gray-400">USD / month</div>
                        </div>
                    </div>
                </FieldGroup>
                <FieldGroup title="Tech">
                    <div className={GROUP_CONTAINER_STYLE}>
                        <Controller
                            name="mainFramework"
                            control={control}
                            defaultValue={KEY_OTHER}
                            render={({ field: { ref: _, ...field } }) => (
                                <StyledSelect
                                    label="Main framework"
                                    disabled={isLoading}
                                    options={FRAMEWORK_OPTIONS}
                                    {...field}
                                    bottomContent={
                                        <StyledInput
                                            label="Framework clarification"
                                            {...inputRegister('frameworkNote')}
                                            placeholder="Clarify, if needed"
                                            className="w-full"
                                        />
                                    }
                                />
                            )}
                        />
                        <div className={clsxm(COLUMN_FIELDS_STYLE, 'p-4 ring-1 ring-gray-200')}>
                            <Controller
                                name="typescriptIsMainLanguage"
                                control={control}
                                defaultValue={false}
                                render={({ field: { ref: _, ...field } }) => (
                                    <StyledSwitch checked={field.value} onChange={field.onChange}>
                                        TypeScript is my
                                        <br /> main language
                                    </StyledSwitch>
                                )}
                            />
                            <StyledInput
                                label="Programming language note"
                                {...inputRegister('programmingLanguageNote')}
                                placeholder="Clarify, if needed"
                                className="w-full"
                            />
                        </div>
                        <Controller
                            name="typescriptLevel"
                            control={control}
                            defaultValue={TYPESCRIPT_LEVEL_OPTIONS[2]?.key}
                            render={({ field: { ref: _, ...field } }) => (
                                <StyledSelect
                                    asRadio
                                    disabled={isLoading}
                                    label="TypeScript level"
                                    options={TYPESCRIPT_LEVEL_OPTIONS}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="operatingSystem"
                            control={control}
                            defaultValue={KEY_OTHER}
                            render={({ field: { ref: _, ...field } }) => (
                                <StyledSelect
                                    asRadio
                                    disabled={isLoading}
                                    label="Operating System"
                                    options={OS_OPTIONS}
                                    {...field}
                                    bottomContent={
                                        <StyledInput
                                            label="OS clarification"
                                            {...inputRegister('osNote')}
                                            placeholder="Clarify, if needed"
                                            className="w-full"
                                        />
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="ide"
                            control={control}
                            defaultValue={KEY_OTHER}
                            render={({ field: { ref: _, ...field } }) => (
                                <StyledSelect
                                    asRadio
                                    disabled={isLoading}
                                    label="IDE"
                                    options={IDE_OPTIONS}
                                    {...field}
                                    bottomContent={
                                        <StyledInput
                                            label="IDE clarification"
                                            {...inputRegister('ideNote')}
                                            placeholder="Clarify, if needed"
                                            className="w-full"
                                        />
                                    }
                                />
                            )}
                        />
                    </div>
                </FieldGroup>
                <div className="sticky bottom-0 flex justify-center gap-6 p-4 backdrop-blur">
                    <StyledButton
                        secondary
                        disabled={!isDirty || isLoading}
                        onClick={() => reset()}
                        type="button">
                        Cancel
                    </StyledButton>
                    <StyledButton disabled={!isDirty} loading={isLoading} type="submit">
                        Save
                    </StyledButton>
                </div>
            </form>
            <InfoBanner profileUpdatedDate={updatedDate} />
        </StyledSection>
    );
};

const KEY_OTHER = 'other';

const FRAMEWORK_OPTIONS = [
    { key: 'react', content: 'React' },
    { key: 'reactnative', content: 'React Native' },
    { key: 'nextjs', content: 'NextJs' },
    { key: 'vue', content: 'Vue' },
    { key: 'nuxtjs', content: 'NuxtJs' },
    { key: 'angular', content: 'Angular' },
    { key: 'nestjs', content: 'NestJs' },
    { key: 'express', content: 'Express' },
    { key: KEY_OTHER, content: 'Other' },
];

const GENDER_OPTIONS = [
    { key: 'male', content: 'Male' },
    { key: 'female', content: 'Female' },
    { key: KEY_OTHER, content: 'Other' },
];

const OS_OPTIONS = [
    { key: 'macos', content: 'MacOS' },
    { key: 'windows', content: 'Windows' },
    { key: 'ubuntu', content: 'Ubuntu' },
    { key: KEY_OTHER, content: 'Other' },
];

const IDE_OPTIONS = [
    { key: 'vscode', content: 'VS Code' },
    { key: 'webstorm', content: 'WebStorm' },
    { key: KEY_OTHER, content: 'Other' },
];

const TYPESCRIPT_LEVEL_OPTIONS = [
    { key: 'fluent', content: 'Fluent' },
    { key: 'middle', content: 'Middle, lots to google and learn' },
    { key: 'none', content: 'No confidence' },
];

const COMPANY_SIZE_OPTIONS = [
    { key: 'notEmployed', content: 'Not employed' },
    { key: 'selfEmployed', content: 'Self employed' },
    { key: 'upto10', content: '2-10 people' },
    { key: 'upto30', content: '11-30 people' },
    { key: 'upto50', content: '31-50 people' },
    { key: 'upto100', content: '51-100 people' },
    { key: 'over100', content: '100+ people' },
];

const FieldGroup: React.FC<{ title: string; className?: string }> = ({
    title,
    children,
    className,
}) => {
    return (
        <div className={clsxm(COLUMN_FIELDS_STYLE, className)}>
            <div className="pl-4 text-lg text-gray-600 shadow-[0_1px] shadow-sky-600">{title}</div>
            {children}
        </div>
    );
};

const InfoBanner: React.FC<{
    surveyDate?: string | null;
    profileUpdatedDate?: string | null;
}> = ({ surveyDate, profileUpdatedDate }) => {
    const memo = useMemo(() => {
        const res = {
            noProfile: false,
            profileOutdated: false,
            profileScheduled: false,
        };
        if (!profileUpdatedDate) {
            res.noProfile = true;
            return res;
        }
        if (!surveyDate) {
            res.profileScheduled = true;
            return res;
        }
        if (isFirstDateMoreRecent(profileUpdatedDate, PROFILE_ELIGIBLE_DATE_SINCE.toJSON())) {
            res.profileOutdated = true;
            return res;
        }
        res.profileScheduled = isFirstDateMoreRecent(profileUpdatedDate, surveyDate);
        return res;
    }, [surveyDate, profileUpdatedDate]);
    if (memo.noProfile) {
        return (
            <div className="py-6 text-center text-gray-400">
                🔵 Please, configure your profile and it will participate in survey.
            </div>
        );
    }
    const friendlyTime =
        profileUpdatedDate && `Profile updated at ${format(new Date(profileUpdatedDate), 'PP pp')}`;
    if (!friendlyTime) {
        return null;
    }
    if (memo.profileOutdated) {
        return (
            <div className="py-6 text-center text-gray-400">
                🙅‍♂️ Your profile does not participate in survey because it is older than{' '}
                {PROFILE_ELIGIBLE_DAYS} days. Please, refresh your profile.
                <div>{friendlyTime}</div>
            </div>
        );
    }
    if (memo.profileScheduled) {
        return (
            <div className="py-6 text-center text-gray-400">
                ✅ Your profile will be included in survey on next snapshot. Thanks for keeping it
                fresh.
                <div>{friendlyTime}</div>
            </div>
        );
    }
    return null;
};

const WorkExperienceConverter: React.FC<{
    sinceDate?: string | null;
}> = ({ sinceDate: value }) => {
    if (!value) {
        return <div className="text-gray-400">No experience</div>;
    }
    const then = new Date(value).getTime();
    const now = Date.now();
    const deltaMonths = Math.round((now - then) / ROUGH_MONTH_MS);
    if (deltaMonths < 0) {
        return <div className="text-gray-400">Invalid experience</div>;
    }
    if (deltaMonths < 12) {
        return (
            <div className="text-gray-400">{pluralize('month', deltaMonths, true)} experience</div>
        );
    }
    const fullYears = Math.floor(deltaMonths / MONTHS_PER_YEAR);
    const restMonths = deltaMonths - fullYears * MONTHS_PER_YEAR;
    return (
        <div className="text-gray-400">
            {pluralize('year', fullYears, true)}{' '}
            {Boolean(restMonths) && pluralize('month', restMonths, true)} experience
        </div>
    );
};
const ROUGH_MONTH_MS = 1e3 * 60 * 60 * 24 * 30;
const MONTHS_PER_YEAR = 12;
