import { getMyUserActions } from './actions';
import { createBetterReducer } from '../../utils';
import { ResponseUserZod } from '../../api/user/dto';
import { authActions } from '../auth/actions';

export class StateModel {
    me?: ResponseUserZod;
}

export const userReducer = createBetterReducer(new StateModel(), [getMyUserActions, authActions])
    .handleAction(getMyUserActions.success, (state, { payload }): StateModel => {
        return {
            ...state,
            me: payload,
        };
    })
    .handleAction(authActions.signOut, (state): StateModel => {
        return {
            ...state,
            me: undefined,
        };
    });
