import React from 'react';
import { UserPermission } from '../types';
import { useSelector } from 'react-redux';
import { selectMyUser } from '../store/user/selectors';

export const RequirePermissions: React.FC<{
    atLeast?: UserPermission;
    noAuthContent?: React.ReactNode;
}> = ({ children, atLeast, noAuthContent }) => {
    const user = useSelector(selectMyUser);
    if (!user) {
        return noAuthContent ? <>{noAuthContent}</> : null;
    }

    if (atLeast && user.permissions !== atLeast) {
        return null;
    }

    return <>{children}</>;
};
