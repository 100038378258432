import { StyledSection } from 'layout/StyledSection';
import { SectionContainer } from 'layout/SectionContainer';
import { AdminExecaSection } from './sections/AdminExecaSection';

export const AdminDockerContainersPage: React.FC = () => {
    return (
        <SectionContainer>
            <StyledSection title="Docker Containers">
                <div>{`PURPOSE - needed to kill stuck containers if any. Critical for quick action during TCC.`}</div>
                <div>{`TCC page - for ADMIN ONLY - should name of containers used to run submission.`}</div>
                <div>{`Maybe even a quick button directly on TCC page to kill container = cancel execution.`}</div>
                <div>{`TODO: button "refresh list"`}</div>
                <div>{`TODO: highlight tcc containers running for more than 10 min`}</div>
                <div>TODO: show list of active containers</div>
            </StyledSection>
            <AdminExecaSection />
        </SectionContainer>
    );
};
