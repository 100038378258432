import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { EnumButtonGroup } from 'components/form/EnumButtonGroup';
import { SectionContainer } from 'layout/SectionContainer';
import { StyledSection } from 'layout/StyledSection';
import { useEffect, useState } from 'react';
import { clsxm } from 'utils/clsxm';

enum ETaskMode {
    NONE = 'NONE',
    EASY = 'EASY',
    HARD = 'HARD',
}

export const BenchmarkPage: React.FC = () => {
    const [mode, setMode] = useState(ETaskMode.NONE);
    const [run, setRun] = useState(false);

    return (
        <SectionContainer>
            <StyledSection title="Benchmark">
                <div className="flex flex-col gap-4">
                    <div>
                        <div
                            aria-hidden
                            onClick={() => setRun(!run)}
                            className={clsxm(
                                'inline-block cursor-pointer p-2',
                                run ? 'bg-amber-600 text-amber-300' : 'bg-gray-600 text-gray-300',
                            )}>
                            {run ? 'Running' : 'Paused'}
                        </div>
                    </div>
                    <div>
                        <EnumButtonGroup
                            options={ARR_OF_MODES}
                            value={mode}
                            onChange={v => setMode(v)}
                        />
                    </div>
                    <BenchMonitor mode={mode} run={run} />
                </div>
            </StyledSection>
            <StyledSection title="Demo TCC">
                <ExternalLink href="https://targer.dev/competitions/652506d9-0d5b-4780-8137-32c4e424e47d">
                    TCC: Test 2 Dec 5
                </ExternalLink>
            </StyledSection>
        </SectionContainer>
    );
};

const ARR_OF_MODES = Object.keys(ETaskMode) as ETaskMode[];

const BenchMonitor: React.FC<{
    run: boolean;
    mode?: ETaskMode;
}> = ({ mode = ETaskMode.NONE, run }) => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!ref || !run) {
            return;
        }

        let mounted = true;

        const HISTORY_SIZE = 20;
        const LOG_PERIOD_MS = 450;
        let arrOfDeltas: number[] = [];
        let prevTime = Date.now();
        let lastLogTime = 0;
        const render = () => {
            if (!mounted || !ref) {
                return;
            }
            requestAnimationFrame(render);
            if (mode === ETaskMode.EASY) {
                runTaskMapper.easyTask();
            }
            if (mode === ETaskMode.HARD) {
                runTaskMapper.hardTask();
            }
            const now = Date.now();
            const delta = now - prevTime;
            if (!delta) {
                return;
            }
            prevTime = now;
            arrOfDeltas.push(1e3 / delta);
            arrOfDeltas = arrOfDeltas.slice(-HISTORY_SIZE);
            const avg = arrOfDeltas.reduce((a, v) => a + v, 0) / arrOfDeltas.length;
            if (now - lastLogTime > LOG_PERIOD_MS) {
                lastLogTime = now;
                ref.innerText = `${avg.toFixed(1)} FPS`;
            }
        };
        requestAnimationFrame(render);

        return () => {
            mounted = false;
        };
    }, [ref, mode, run]);

    return (
        <div className="p-2">
            <div ref={setRef}></div>
        </div>
    );
};

const runTaskMapper = {
    easyTask() {
        const arr: number[] = [];
        for (let i = 0; i < 1e6; i++) {
            arr.push(Math.random() * 1.5 + 2.39839484);
        }
        setTimeout(() => {
            arr.length = 0;
        }, 5);
    },
    hardTask() {
        const arr: number[] = [];
        for (let i = 0; i < 3e7; i++) {
            arr.push(Math.random() * 1.5 + 2.39839484);
        }
        setTimeout(() => {
            arr.length = 0;
        }, 5);
    },
};
