import { useQuery } from '@tanstack/react-query';
import { TicketApi } from 'api/ticket/ticket.api';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { RequirePermissions } from 'hoc';
import { StyledSection } from 'layout/StyledSection';
import { useAdminLocalConfig } from 'pages/dev-kit/-page/useAdminLocalConfig.hook';
import { TicketStatus, UserPermission } from 'types';
import { clsxm } from 'utils/clsxm';
import { convertCoin2Unit } from 'utils/currency.util';
import { formatInUkraineTz } from 'utils/date.util';
import { maybeShrinkText } from 'utils/string';

export const AdminCompetitionControlsSection: React.FC<{
    competitionId: string;
}> = ({ competitionId }) => {
    const [config] = useAdminLocalConfig();
    if (!config.competitionControls) {
        return null;
    }

    return (
        <RequirePermissions atLeast={UserPermission.ADMIN}>
            <Body competitionId={competitionId} />
        </RequirePermissions>
    );
};

const Body: React.FC<{
    competitionId: string;
}> = ({ competitionId }) => {
    const { isLoading, data, error } = useQuery({
        queryKey: ['TicketApi.getAllTickets'],
        queryFn: async () => TicketApi.getAllTickets({ competitionId }),
    });

    const renderContent = () => {
        if (isLoading || !data || !data.total || error) {
            return (
                <PlaceholderBox loading={isLoading} noData={!data || !data.total} error={error} />
            );
        }

        const totalPaid = data.items
            .filter(e => e.status === TicketStatus.PAID)
            .reduce((a, v) => a + v.priceCoin, 0);
        const totalPending = data.items
            .filter(e => e.status === TicketStatus.PENDING)
            .reduce((a, v) => a + v.priceCoin, 0);
        return (
            <div>
                <div>all tickets</div>
                <div className="flex gap-4">
                    {/* TODO handle other currencies in future */}
                    total: <b>{data.total}</b> tickets,
                    <div className="font-bold text-green-600">
                        paid <b>{convertCoin2Unit(totalPaid)} USD</b>
                    </div>
                    {totalPending > 0 && (
                        <div className="font-bold text-yellow-600">
                            pending <b>{convertCoin2Unit(totalPending)} USD</b>
                        </div>
                    )}
                </div>
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th>user</th>
                            <th>promo code</th>
                            <th>paid date</th>
                            <th>price</th>
                            <th>status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.items.map((e, i) => {
                            return (
                                <tr key={i}>
                                    <td className="border-[1px] border-solid border-gray-300 p-1 text-center">
                                        github: {e.user?.githubId}, {e.user?.github.login}
                                        <br />
                                        user: {maybeShrinkText(e.user?.id, 16)}
                                    </td>
                                    <td className="border-[1px] border-solid border-gray-300 p-1 text-center">
                                        {e.promoCode}
                                        <br />
                                        {e.promoType}
                                    </td>
                                    <td className="border-[1px] border-solid border-gray-300 p-1 text-center">
                                        {e.paidDate &&
                                            formatInUkraineTz(new Date(e.paidDate), 'PP pp')}
                                    </td>
                                    <td className="border-[1px] border-solid border-gray-300 p-1 text-center">
                                        {convertCoin2Unit(e.priceCoin)} {e.currency}
                                        <br />
                                        {e.paymentFailureReason}
                                    </td>
                                    <td
                                        className={clsxm(
                                            'border-[1px] border-solid border-gray-300 p-1 text-center font-bold',
                                            e.status === TicketStatus.PAID && 'text-green-600',
                                            e.status === TicketStatus.PENDING && 'text-yellow-600',
                                        )}>
                                        {e.status}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div>
            <StyledSection title="Admin Competition Controls">{renderContent()}</StyledSection>
        </div>
    );
};
