import React from 'react';
import { clsxm } from 'utils/clsxm';

export const WelcomeBanner: React.FC = () => {
    return (
        <div className="bg-slate-800 text-white">
            <div className="main-container">
                <div className="p-4 text-slate-200">
                    <div className="text-center text-xl md:text-4xl">
                        <div>
                            <div
                                className={clsxm(
                                    'mr-2 inline-block h-3 w-3 animate-pulse rounded-full bg-lime-400',
                                    'md:mb-1 md:mr-4 md:h-4 md:w-4',
                                )}
                            />
                            Live Development Competitions
                        </div>
                    </div>
                    <div className="p-2 text-center text-xs text-slate-500 md:text-sm">
                        TypeScript / Algorithms / Machine Learning / Object-Oriented Programming
                        (OOP) / Functional Programming (FP)
                    </div>
                </div>
            </div>
        </div>
    );
};
