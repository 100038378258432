import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { StyledButton } from 'components/form/StyledButton';
import { StyledInput } from 'components/styled-input/StyledInput';
import {
    DATE_FORMAT_DAY_DATE,
    DATE_FORMAT_DAY_DATE_TIME,
    DATE_FORMAT_SHORT_DATE,
} from 'constants/format.constant';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { StyledSection } from 'layout/StyledSection';

const TODAY = new Date();
const TODAY_STR = TODAY.toJSON();

export const UISectionFormatting: React.FC = () => {
    const [date, setDate] = useState(TODAY_STR);
    const [customFormat, setCustomFormat] = useState('');

    const renderFormatSafely = (template: string) => {
        if (!template) {
            return 'specify format';
        }
        try {
            return format(new Date(date), template);
        } catch (e) {
            if (e instanceof Error) {
                return smartInjectLink(e.message);
            }
            return 'unknown error';
        }
    };
    return (
        <StyledSection title="Formatting">
            <div>
                See format specs at{' '}
                <ExternalLink href="https://date-fns.org/v2.29.2/docs/format">
                    https://date-fns.org/v2.29.2/docs/format
                </ExternalLink>
            </div>
            <div className="flex items-center">
                <StyledButton onClick={() => setDate(TODAY_STR)}>Reset</StyledButton>
                <div className="flex-1">
                    <StyledInput
                        value={date}
                        onChange={e => setDate(e.target.value)}
                        label="Date"
                        className="w-full"
                    />
                </div>
            </div>
            <div className="flex items-center">
                <StyledButton onClick={() => setCustomFormat('')}>Clear</StyledButton>
                <div className="flex-1">
                    <StyledInput
                        value={customFormat}
                        onChange={e => setCustomFormat(e.target.value)}
                        label="Custom Format"
                        className="w-full"
                    />
                </div>
            </div>
            <div className="mb-2 flex items-center gap-2">
                <div className="w-40 bg-gray-200 p-1">custom format: </div>
                <div>{renderFormatSafely(customFormat)}</div>
            </div>
            <div className="flex flex-col gap-2">
                {[
                    { name: 'DATE_FORMAT_DAY_DATE', template: DATE_FORMAT_DAY_DATE },
                    { name: 'DATE_FORMAT_DAY_DATE_TIME', template: DATE_FORMAT_DAY_DATE_TIME },
                    { name: 'DATE_FORMAT_SHORT_DATE', template: DATE_FORMAT_SHORT_DATE },
                ].map(({ name, template }, i) => {
                    return (
                        <div key={i} className="flex items-center gap-2">
                            <div>{name}</div>
                            <div
                                aria-hidden
                                onClick={() => setCustomFormat(template)}
                                className="cursor-pointer bg-gray-200 p-2 hover:bg-sky-200">
                                {template}
                            </div>
                            <div>{renderFormatSafely(template)}</div>
                        </div>
                    );
                })}
            </div>
            {/* TODO currencies formatting */}
        </StyledSection>
    );
};

const smartInjectLink = (text: string) => {
    // simple regex to match URLs
    const regex = /https?:\/\/\S+|\S+\.\S+/g;
    let match = regex.exec(text);
    const res: {
        type: 'content' | 'link';
        value: string;
    }[] = [];
    let prevIndex = 0;
    while (match != null) {
        // matched text: match[0]
        // match start: match.index
        // capturing group n: match[n]
        // console.log(match[0], match.index)
        const link = match[0];
        if (link) {
            res.push({
                type: 'content',
                value: text.slice(prevIndex, match.index),
            });
            prevIndex = match.index + link.length;
            res.push({
                type: 'link',
                value: text.slice(match.index, prevIndex),
            });
        }
        match = regex.exec(text);
    }
    res.push({
        type: 'content',
        value: text.slice(prevIndex),
    });
    return (
        <>
            {res.map((e, i) => {
                if (e.type === 'content') {
                    return <React.Fragment key={i}>{e.value}</React.Fragment>;
                }
                return (
                    <ExternalLink key={i} href={e.value}>
                        {e.value}
                    </ExternalLink>
                );
            })}
        </>
    );
};
