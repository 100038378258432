import { isDateExpired } from 'utils/date.util';
import { DATE_FORMAT_DAY_DATE } from 'constants/format.constant';
import { format } from 'date-fns';
import { IoCalendarOutline } from 'react-icons/io5';
import { HiOutlineLocationMarker } from 'react-icons/hi';

export const calcStatusAndLocationDetails = ({
    startDate,
    endDate,
    mockCurrentDate,
}: {
    startDate: string;
    endDate: string;
    mockCurrentDate?: string | number;
}): {
    status: 'future' | 'past' | 'ending';
    text: string;
    targetDate?: string;
    renderWhenDetails: () => JSX.Element | undefined;
    renderWhereDetails: () => JSX.Element;
} => {
    const renderWhereDetails = () => {
        return (
            <div className="flex items-center justify-center gap-2">
                <HiOutlineLocationMarker />
                Online
            </div>
        );
    };
    const renderWhenDetails = () => {
        if (startDate && endDate) {
            const objStart = new Date(startDate);
            const objEnd = new Date(endDate);
            const isSameDate =
                format(objStart, DATE_FORMAT_DAY_DATE) === format(objEnd, DATE_FORMAT_DAY_DATE);
            if (isSameDate) {
                return (
                    <>
                        <div className="flex items-center justify-center gap-2">
                            <IoCalendarOutline />
                            {format(objStart, 'E')}
                            <div className="font-bold">{format(objStart, 'dd MMM')}</div>
                            <div className="text-slate-400">{format(objStart, 'yyyy')}</div>
                        </div>
                        <div className="flex justify-center font-bold text-sky-800">
                            {format(objStart, 'p')} - {format(objEnd, 'p')}
                        </div>
                    </>
                );
            }
            return (
                <>
                    <div className="flex flex-wrap items-center justify-center gap-2">
                        <IoCalendarOutline />
                        {format(objStart, 'E')}
                        <div className="font-bold">{format(objStart, 'dd MMM')}</div>
                        <div className="text-slate-400">{format(objStart, 'yyyy')}</div>
                        <div className="font-bold text-sky-800">{format(objStart, 'p')}</div>
                    </div>
                    <div className="flex justify-center text-slate-500">👆🏻 start | end 👇🏻</div>
                    <div className="flex flex-wrap items-center justify-center gap-2">
                        <IoCalendarOutline />
                        {format(objEnd, 'E')}
                        <div className="font-bold">{format(objEnd, 'dd MMM')}</div>
                        <div className="text-slate-400">{format(objEnd, 'yyyy')}</div>
                        <div className="font-bold text-sky-800">{format(objEnd, 'p')}</div>
                    </div>
                </>
            );
        }
    };

    const isFuture = !isDateExpired(startDate, mockCurrentDate);
    if (isFuture) {
        return {
            status: 'future',
            text: 'Start in ⏱',
            targetDate: startDate,
            renderWhenDetails,
            renderWhereDetails,
        };
    }

    const isPast = isDateExpired(endDate, mockCurrentDate);
    return {
        status: isPast ? 'past' : 'ending',
        text: isPast ? 'Ended ✅' : 'Ongoing ⏳',
        targetDate: endDate,
        renderWhenDetails,
        renderWhereDetails,
    };
};
