import { useEffect, useState } from 'react';

/**
 * Cause component rerender via state update at specified time
 */
export const useRerenderAt = (atTime?: string | number | Date) => {
    const [, setRenderTrigger] = useState(0);

    useEffect(() => {
        if (!atTime) {
            return;
        }
        let mounted = true;
        const target = new Date(atTime);
        const delta = target.getTime() - Date.now();
        // ignore past dates
        if (delta < 0) {
            return;
        }
        const timer = setTimeout(() => {
            if (!mounted) {
                return;
            }
            setRenderTrigger(i => i + 1);
        }, delta);
        return () => {
            mounted = false;
            clearTimeout(timer);
        };
    }, [atTime]);
};
