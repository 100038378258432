import React from 'react';
import './apply-side-effects';
import { Router } from './router/Router';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { rootStore } from './store';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import { theme } from 'styles/material-theme';
import { PullToRefresh } from 'components/ux/PullToRefresh';
import { RecoilRoot } from 'recoil';

const queryClient = new QueryClient();

const App: React.FC = () => {
    return (
        <Sentry.ErrorBoundary fallback={'An error has occurred'}>
            <RecoilRoot>
                <PullToRefresh />
                <Provider store={rootStore}>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={theme}>
                            <BrowserRouter>
                                <Router />
                            </BrowserRouter>
                        </ThemeProvider>
                    </QueryClientProvider>
                </Provider>
                <ToastContainer />
            </RecoilRoot>
        </Sentry.ErrorBoundary>
    );
};

export default App;
