import { z } from 'zod';

export const ExecaHistorySchema = z.object({
    list: z.array(
        z.object({
            error: z.string().optional(),
            request: z.object({
                cmd: z.string(),
                arguments: z.array(z.string()).optional(),
            }),
            response: z
                .object({
                    stdout: z.string(),
                    exitCode: z.number(),
                    failed: z.boolean(),
                    killed: z.boolean(),
                    timedOut: z.boolean(),
                })
                .optional(),
            duration: z.number(),
            timestamp: z.number(),
        }),
    ),
    maxSize: z.number(),
});
