import { StyledSection } from 'layout/StyledSection';
import { useState } from 'react';
import { GiLaptop, GiKeyLock, GiWifiRouter, GiBrain } from 'react-icons/gi';
import { clsxm } from 'utils/clsxm';

export const CompetitionPrepareYourselfSection: React.FC = () => {
    return (
        <StyledSection title="Prepare Yourself">
            <div className="grid grid-cols-2 justify-around gap-4 text-lg md:grid-cols-4 md:text-xl">
                <div className="group flex flex-col items-center gap-2 text-center transition hover:scale-110">
                    <div className="h-16 w-16 bg-white p-4 ring-1 ring-sky-100 transition group-hover:ring-4 md:h-24 md:w-24">
                        <GiLaptop className="h-full w-full fill-sky-800" />
                    </div>
                    IDE
                    <div className="text-xs text-slate-400">
                        VS Code, WebStorm <br />
                        or any other you like
                    </div>
                </div>
                <div className="group flex flex-col items-center gap-2 text-center transition hover:scale-110">
                    <div className="h-16 w-16 bg-white p-4 ring-1 ring-sky-100 transition group-hover:ring-4 md:h-24 md:w-24">
                        <GiKeyLock className="h-full w-full fill-sky-800" />
                    </div>
                    SSH Key
                    <br />
                    For GitHub
                    <div className="text-xs text-slate-400">
                        Pull and Push <br /> your private repos
                    </div>
                </div>
                <div className="group flex flex-col items-center gap-2 text-center transition hover:scale-110">
                    <div className="h-16 w-16 bg-white p-4 ring-1 ring-sky-100 transition group-hover:ring-4 md:h-24 md:w-24">
                        <GiWifiRouter className="h-full w-full fill-sky-800" />
                    </div>
                    Good Internet <br />
                    Connection
                    <div className="text-xs text-slate-400">
                        10+ Mb <br />
                        Fast internet could <br />
                        help you win
                    </div>
                </div>
                <div className="group flex flex-col items-center gap-2 text-center transition hover:scale-110">
                    <div className="h-16 w-16 bg-white p-4 ring-1 ring-sky-100 transition group-hover:ring-4 md:h-24 md:w-24">
                        <GiBrain className="h-full w-full fill-sky-800" />
                    </div>
                    Clear Your Mind
                    <div className="text-xs text-slate-400">
                        And showcase <br />
                        the power
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-4 pt-4 text-center">
                <div className="text-xl font-bold">FAQ - Frequently Asked Questions</div>
                <div className="grid shrink grid-cols-1 items-start justify-center gap-4 md:grid-cols-2">
                    {FAQ.map((e, i) => (
                        <QuestionAnswer key={i} {...e} />
                    ))}
                </div>
            </div>
        </StyledSection>
    );
};

const FAQ: {
    question: string;
    answer: React.ReactNode;
}[] = [
    {
        question: 'Can I finish all tasks earlier?',
        answer: (
            <div>
                <div>
                    <b>Absolutely,</b> with great skills you can solve all tasks much faster than
                    event duration.
                </div>
                <br />
                <div>
                    Participants have different level of skills so{' '}
                    <b>duration of an event is intentionally longer</b> than needed on average so
                    everyone can make most of their time.
                </div>
            </div>
        ),
    },
    {
        question: 'Can I take pauses during an event?',
        answer: (
            <div>
                <div>
                    <b>Definitely.</b> Take pause at anytime.
                </div>
                <div>Start later or complete earlier.</div>
                <div>You control your time during an event.</div>
                <br />
                <div>
                    But remember - <b>the faster you solve tasks the more chances to win</b> you
                    have.
                </div>
            </div>
        ),
    },
    {
        question: 'What tasks to expect?',
        answer: (
            <div>
                <div>Exact tasks of challenge are confidential.</div>
                <br />
                <div>To give you an idea of what it might be here are examples:</div>
                <ol className="list-decimal ps-8">
                    <li>Pure functions</li>
                    <li>Not pure function (e.g. closures, mutable arguments, side effects)</li>
                    <li>Promises and other async stuff</li>
                    <li>Classes</li>
                    <li>DOM elements</li>
                    <li>Regular expressions</li>
                    <li>Timers</li>
                </ol>
            </div>
        ),
    },
    {
        question: 'Can junior developer win?',
        answer: (
            <div>
                <div>
                    <b>Yes!</b> Even junior developers can win a competition.
                </div>
                <div>
                    There is <b>no restriction or accent</b> on seniority level.
                </div>
                <div>Challenge consists of tasks of different complexity.</div>
                <br />
                <div>
                    It is worth noting that seniority level is{' '}
                    <i>a subjective label and differs from company to company.</i>
                </div>
                <br />
                <div>So, some junior developers are more skilled than other senior developers.</div>
                <br />
                <div>Maybe it is about you? Real competition will show.</div>
            </div>
        ),
    },
    {
        question: 'How to win?',
        answer: (
            <div>
                <div>
                    Become <b>top ranked</b> in leaderboard.
                </div>
                <br />
                <div>For that - solve challenge tasks faster and better than others.</div>
                <br />
                <div>Focus on key requirements of tasks.</div>
                <div>Think about derived restrictions and requirements to handle.</div>
                <br />
                <div>Push your code regularly to see your progress.</div>
            </div>
        ),
    },
    {
        question: 'Where to get promo code?',
        answer: (
            <div>
                <div>Follow our Telegram channel.</div>
                <br />
                <div>
                    Be proactive and friendly on social media with relation to targer - and you will
                    be noticed.
                </div>
            </div>
        ),
    },
];

const QuestionAnswer: React.FC<{
    question: string;
    answer: React.ReactNode;
}> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={clsxm('flex flex-col gap-4 bg-gray-100 p-2', !isOpen && 'cursor-pointer')}>
            <div
                className={clsxm(isOpen && 'font-bold')}
                onClick={() => setIsOpen(true)}
                aria-hidden>
                {question}
            </div>
            {isOpen && (
                <div className="mx-auto max-w-lg text-left">
                    {answer}
                    <div
                        className="mt-2 cursor-pointer bg-gray-300 p-1 text-center transition hover:bg-gray-400"
                        onClick={() => setIsOpen(false)}
                        aria-hidden>
                        Nice, got it
                    </div>
                </div>
            )}
        </div>
    );
};
