import { sleep } from './async.util';

export const silent = async <T>(p: Promise<T>) => {
    return p.catch(() => undefined);
};

export const silentArray = async <T>(
    p: Promise<T>,
): Promise<readonly [T | undefined, Error | undefined]> => {
    try {
        const v = await p;
        return [v, undefined] as const;
    } catch (err) {
        const reason = err instanceof Error ? err : new Error(String(err));
        return [undefined, reason] as const;
    }
};

export const delayPromise = async <T>(p: Promise<T>, ms: number) => {
    await sleep(ms);
    return p;
};
