import { calcStatusAndLocationDetails } from 'domain/competition/competition.util';
import { useTimeDistance } from 'hooks/time.hook';
import pluralize from 'pluralize';
import React from 'react';
import { clsxm } from 'utils/clsxm';
import { lerpHexColor } from 'utils/color.util';
import { clamp, doubleDigit } from 'utils/number.util';
import { PolyCircle } from './PolyCircle';

const GRADIENT: [string, number][] = [
    ['#a61414', 0.0],
    ['#b0169b', 0.5],
    ['#1956ac', 0.8],
    ['#1495c4', 1.0],
];

const ONE_HOUR = 60e3 * 60;
const DEBUG_MODE = false;

export const CountdownTimer: React.FC<{
    startDate: string;
    endDate: string;
    mockCurrentDate?: string | number;
}> = ({ startDate, endDate, mockCurrentDate }) => {
    const { text, targetDate } = calcStatusAndLocationDetails({
        startDate,
        endDate,
        mockCurrentDate,
    });
    const { days, hours, minutes, seconds, allMillis } = useTimeDistance({
        toDate: targetDate,
        mockCurrentDate,
    });
    const color = getGradientColor(GRADIENT, clamp(0, 1, allMillis / ONE_HOUR));

    const [d1 = 0, d2 = 0, d3 = 0] = `${days}`;
    const [h1 = 0, h2 = 0] = doubleDigit(hours);
    const [m1 = 0, m2 = 0] = doubleDigit(minutes);
    const [s1 = 0, s2 = 0] = doubleDigit(seconds);

    return (
        <div className="flex flex-col gap-2">
            <div className="text-center text-gray-500">{text}</div>
            {DEBUG_MODE && <Line />}
            <div className="flex justify-center md:gap-2">
                <div className="relative w-20 md:w-28">
                    <PolyCircle progress={Math.abs(days / 30)} color={color} />
                    <div className="absolute top-0 flex h-full w-full flex-col items-center justify-center">
                        {days <= 999 ? (
                            <div
                                className={clsxm(
                                    'flex text-center text-xl font-semibold',
                                    !days && 'text-slate-400',
                                )}>
                                <div className="min-w-[15px]">{d1}</div>
                                {Boolean(d2) && <div className="min-w-[15px]">{d2}</div>}
                                {Boolean(d3) && <div className="min-w-[15px]">{d3}</div>}
                            </div>
                        ) : (
                            <div
                                className={clsxm(
                                    'flex text-center text-xl font-semibold',
                                    !days && 'text-slate-400',
                                )}>
                                999+
                            </div>
                        )}
                        <div className="text-xs text-slate-400">{pluralize('day', days)}</div>
                    </div>
                </div>
                <div className="relative w-20 md:w-28">
                    <PolyCircle progress={Math.abs(hours / 24)} color={color} />
                    <div className="absolute top-0 flex h-full w-full flex-col items-center justify-center">
                        {
                            <div
                                className={clsxm(
                                    'flex text-center text-xl font-semibold',
                                    !hours && 'text-slate-400',
                                )}>
                                <div className="min-w-[15px]">{h1}</div>
                                <div className="min-w-[15px]">{h2}</div>
                            </div>
                        }
                        <div className="text-xs text-slate-400">{pluralize('hr', hours)}</div>
                    </div>
                </div>
                <div className="relative w-20 md:w-28">
                    <PolyCircle progress={Math.abs(minutes / 60)} color={color} />
                    <div className="absolute top-0 flex h-full w-full flex-col items-center justify-center">
                        {
                            <div
                                className={clsxm(
                                    'flex text-center text-xl font-semibold',
                                    !minutes && 'text-slate-400',
                                )}>
                                <div className="min-w-[15px]">{m1}</div>
                                <div className="min-w-[15px]">{m2}</div>
                            </div>
                        }
                        <div className="text-xs text-slate-400">min</div>
                    </div>
                </div>
                <div className="relative w-20 md:w-28">
                    <PolyCircle progress={Math.abs(seconds / 60)} color={color} />
                    <div className="absolute top-0 flex h-full w-full flex-col items-center justify-center">
                        {
                            <div
                                className={clsxm(
                                    'flex text-center text-xl font-semibold',
                                    !seconds && 'text-slate-400',
                                )}>
                                <div className="min-w-[15px]">{s1}</div>
                                <div className="min-w-[15px]">{s2}</div>
                            </div>
                        }
                        <div className="text-xs text-slate-400">sec</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// component for debug purposes
const Line: React.FC = () => {
    const g = (progress: number) => getGradientColor(GRADIENT, progress);
    // const g = (progress: number) => lerpHexColor('#911e1e', '#e7a108', progress);
    return (
        <div className="flex">
            {Array(20)
                .fill(1)
                .map((_, i) => (
                    <div
                        key={i}
                        className="h-4 w-4"
                        style={{
                            background: g(i / 20),
                        }}></div>
                ))}
        </div>
    );
};

/**
 * @param gradient [color, stop][] e.g. ['#000000', 0.0]
 * Order of stops is important
 * @param progress from 0 to 1
 */
const getGradientColor = (gradient: [string, number][], progress: number) => {
    let from = '#000000';
    let to = '#000000';
    let alpha = 0;
    let prevStop = 0;
    for (const [color, stop] of gradient) {
        if (progress >= stop) {
            from = color;
            prevStop = stop;
        } else {
            to = color;
            alpha = (progress - prevStop) / (stop - prevStop);
            break;
        }
    }
    return lerpHexColor(from, to, alpha);
};
