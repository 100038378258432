import { z } from 'zod';

export const typedEnv = z
    .object({
        NODE_ENV: z.string(),
        REACT_APP_API_URL: z.string(),
        REACT_APP_GOOGLE_MAPS_API_KEY: z.string(),
    })
    .parse(process.env);

export const IS_DEV_MODE = typedEnv.NODE_ENV === 'development';
export const IS_PROD_MODE = typedEnv.NODE_ENV === 'production';
