import { StyledTooltip } from 'components/styled-tooltip/StyledTooltip';

export const ChallengeTitle: React.FC<{
    title: string;
}> = ({ title }) => {
    return (
        <div className="text-2xl font-bold">
            <StyledTooltip title="Targer Challenge Competition">
                <span className="rounded bg-sky-100 px-2 py-1 font-normal text-sky-600">TCC:</span>
            </StyledTooltip>{' '}
            {title}
        </div>
    );
};
