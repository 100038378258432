import { useQuery } from '@tanstack/react-query';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { PromoApi } from 'domain/promo/promo.api';
import { RequirePermissions } from 'hoc';
import { StyledSection } from 'layout/StyledSection';
import { UserPermission } from 'types';
import { convertCoin2Unit } from 'utils/currency.util';

export const AdminPromosSection: React.FC<{
    competitionId: string;
}> = ({ competitionId }) => {
    return (
        <RequirePermissions atLeast={UserPermission.ADMIN}>
            <Body competitionId={competitionId} />
        </RequirePermissions>
    );
};

const Body: React.FC<{
    competitionId: string;
}> = ({ competitionId }) => {
    const { isLoading, data, error } = useQuery({
        queryKey: ['PromoApi.getCompetitionPromos'],
        queryFn: async () => PromoApi.getCompetitionPromos({ competitionId }),
    });

    const renderContent = () => {
        if (isLoading || !data || !data.total || error) {
            return (
                <PlaceholderBox loading={isLoading} noData={!data || !data.total} error={error} />
            );
        }

        return (
            <div>
                <div>total: {data.total}</div>
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th>code</th>
                            <th>total / pending tickets</th>
                            <th>total / pending amount</th>
                            <th>view stats link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.items.map((e, i) => {
                            return (
                                <tr key={i}>
                                    <td className="border-[1px] border-solid border-gray-300 p-1 text-center">
                                        {e.code}
                                    </td>
                                    <td className="border-[1px] border-solid border-gray-300 p-1 text-center">
                                        {e.stats.totalTickets} / {e.stats.pendingTickets}
                                    </td>
                                    <td className="border-[1px] border-solid border-gray-300 p-1 text-center">
                                        {e.stats.currency} {convertCoin2Unit(e.stats.totalCoins)} /{' '}
                                        {convertCoin2Unit(e.stats.pendingCoins)}
                                    </td>
                                    <td className="border-[1px] border-solid border-gray-300 p-1 text-center">
                                        TODO view stats link
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div>
            <StyledSection title="Admin promos">{renderContent()}</StyledSection>
        </div>
    );
};
