import React from 'react';
import { SignOutButton } from '../auth/SignOutButton';
import { useSelector } from 'react-redux';
import { selectIsAuthorized } from '../store/auth/selectors';
import { selectMyUser } from '../store/user/selectors';
import { SignInButton } from '../auth/SignInButton';
import { Popover } from 'components/popover/Popover';
import { HeaderNavigation } from './HeaderNavigation';
import { AppLogoLink } from 'branding/AppLogoLink';
import { useIsMobile } from 'hooks/useIsMobile.hook';
import { AdminMenu } from './AdminMenu';

export const Header = React.memo(() => {
    const isAuthorized = useSelector(selectIsAuthorized);
    const isMobile = useIsMobile();

    return (
        <div className="sticky top-0 z-10 bg-white py-1 pr-2 shadow">
            <div className="main-container flex items-center justify-between gap-2">
                <AppLogoLink className="min-w-[120px] justify-start md:min-w-[262px]" />
                <HeaderNavigation />
                {/* min-w-112 = 120 - pr-2 */}
                {/* min-w-254 = 262 - pr-2 */}
                <div className="flex min-w-[112px] items-center justify-end gap-2 lg:min-w-[254px]">
                    {isAuthorized ? <DesktopMenu /> : <SignInButton smallText={isMobile} />}
                </div>
            </div>
        </div>
    );
});

export const DesktopMenu: React.FC = () => {
    const me = useSelector(selectMyUser);

    const renderLinks = () => {
        if (!me) {
            return null;
        }
        return (
            <>
                <div className="cursor-default">{me.github.name}</div>
                <div className="font-bold">{me.github.login}</div>
            </>
        );
    };

    return (
        <Popover
            content={
                <>
                    {renderLinks()}
                    <SignOutButton />
                    <AdminMenu />
                </>
            }>
            <div className="cursor-default font-bold">{me ? me.github.login : 'loading...'}</div>
        </Popover>
    );
};
