import { useMemo } from 'react';

// TODO refactor deprecated
/**
 * @deprecated use useId from react@18
 */
export const useId = () => {
    return useMemo(() => {
        const r = `id_${Math.random()}`;
        return r;
    }, []);
};
