import { useLoadScript } from '@react-google-maps/api';
import { typedEnv } from 'typed-env';

const LOAD_OPTIONS = {
    googleMapsApiKey: typedEnv.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: 'en',
    libraries: ['places' as const],
};

export const useGoogleMapScriptInit = () => {
    return useLoadScript(LOAD_OPTIONS);
};
