import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useSocketSubscription } from '../../../../../hooks/socket-subscription';
import { LeaderboardState, SubmissionStatus } from '../types';
import style from './index.module.scss';
import { useSelector } from 'react-redux';
import { selectMyUser } from '../../../../../store/user/selectors';
import { randi } from 'utils/number.util';
import { mockNicknames, getRandomSubmissionStatus } from './mock';
import { useInterval } from 'react-use';
import { useAdminLocalConfig } from 'pages/dev-kit/-page/useAdminLocalConfig.hook';
import { clsxm } from 'utils/clsxm';
import { withQA } from 'debug/withQA';
import { StyledTooltip } from 'components/styled-tooltip/StyledTooltip';
import { format } from 'date-fns';
import { maybeShrinkText } from 'utils/string';
import { calcStatusAndLocationDetails } from 'domain/competition/competition.util';
import { ResponseCompetitionAdvancedInfoDto } from 'api/competition/dto';

// TODO mobile responsive
export const LeaderboardTable = withQA(
    {
        name: 'LeaderboardTable',
        debugProps: ({ asString, asBoolean }) => ({
            isDisplayMock: asBoolean(false),
            mockRefreshInterval: asString('1e3'),
            // inputSize: asEnum(['small', 'large', 'medium']),
            // type: asEnum(['text', 'password']),
        }),
    },
    React.forwardRef<
        HTMLDivElement,
        {
            competitionId: string;
            state: LeaderboardState;
            displayMock?: boolean;
            mockRefreshInterval?: string;
            competition?: ResponseCompetitionAdvancedInfoDto;
        }
    >(
        (
            {
                competitionId,
                state: initialState,
                displayMock = false,
                mockRefreshInterval = '',
                competition,
            },
            ref,
        ) => {
            const me = useSelector(selectMyUser);
            const [{ mockLeaderboard: configDisplayMock }] = useAdminLocalConfig();
            const isDisplayMock = configDisplayMock || displayMock;
            const enhancedInitialState = useMemo(() => {
                const rankList = enhanceRankList(isDisplayMock, initialState, me);
                return {
                    ...initialState,
                    rankList,
                };
            }, [initialState, me, isDisplayMock]);
            const [state, setState] = useState<LeaderboardState>(enhancedInitialState);

            useInterval(
                () => {
                    renderState({
                        state: {
                            ...initialState,
                        },
                    });
                },
                isDisplayMock ? +mockRefreshInterval || 5e3 : null,
            );

            const renderState = useCallback(
                ({ state }: { state: LeaderboardState }) => {
                    // TODO validate
                    state.rankList = enhanceRankList(isDisplayMock, state, me);
                    setState({ ...state });
                },
                [me, isDisplayMock],
            );

            useEffect(() => {
                renderState({ state: { ...initialState } });
            }, [renderState, initialState]);

            // TODO refactor to constants
            useSocketSubscription(`leaderboard_competition:${competitionId}`, renderState);

            const { status = undefined } = competition
                ? calcStatusAndLocationDetails({
                      startDate: competition.startDate,
                      endDate: competition.expireDate,
                  })
                : {};

            if (!state.rankList.length) {
                return (
                    <div className="text-center">
                        <div>Realtime rank will appear after first submission.</div>
                        <div className="text-slate-400">
                            Push your solution to become the first 😉
                        </div>
                    </div>
                );
            }

            return (
                // read more about overflow-anchor issue at https://stackoverflow.com/a/73514605/5843293
                <div className="mx-auto max-w-2xl px-4 [overflow-anchor:none]" ref={ref}>
                    {state.rankList.map((e, i) => {
                        return (
                            <div
                                key={e.github.login}
                                className={clsxm(
                                    style.row,
                                    'relative flex flex-col gap-2 p-3 px-4 text-white ring-sky-500',
                                    'hover:z-10 hover:opacity-95 hover:ring-4',
                                    e.isMe ? `${style.isMe} px-8` : 'w-full',
                                )}>
                                <div className="flex justify-evenly">
                                    <div className="relative flex flex-1 items-center">
                                        <div
                                            className={clsxm(
                                                'mr-2 select-none font-bold text-zinc-400',
                                            )}>
                                            {i + 1}.
                                        </div>
                                        <b>{e.github.login}</b>
                                        <StyledTooltip
                                            title={
                                                <div className="text-center">
                                                    {format(new Date(e.createdDate), 'PP pp')}
                                                    <br />
                                                    <div className="text-sky-500">
                                                        latest submit
                                                    </div>
                                                    <div>{maybeShrinkText(e.commitId, 16)}</div>
                                                    <div>
                                                        {/* TODO link to commit on GitHub */}
                                                        {'"'}
                                                        {maybeShrinkText(e.commitMessage, 24)}
                                                        {'"'}
                                                    </div>
                                                    {e.status === SubmissionStatus.COMPLETE && (
                                                        <div className="text-sky-300">
                                                            {e.score}{' '}
                                                            {+e.score === 1 ? 'point' : 'points'}
                                                        </div>
                                                    )}
                                                </div>
                                            }>
                                            <div
                                                className={clsxm(
                                                    style.status,
                                                    'absolute right-0 flex select-none items-center',
                                                    SubmissionStatus.COMPLETE === e.status &&
                                                        style.COMPLETE,
                                                    SubmissionStatus.FAIL === e.status &&
                                                        status === 'past' &&
                                                        style.ENDED,
                                                )}>
                                                <span className="text-xs font-normal text-gray-400">
                                                    {nameFromStatus(e.status)}
                                                </span>
                                                <span
                                                    className={clsxm(style.circle, {
                                                        [style.IN_QUEUE]:
                                                            SubmissionStatus.IN_QUEUE === e.status,
                                                        [style.IN_PROGRESS]:
                                                            SubmissionStatus.IN_PROGRESS ===
                                                            e.status,
                                                        [style.COMPLETE]:
                                                            SubmissionStatus.COMPLETE === e.status,
                                                        [style.SKIP]:
                                                            SubmissionStatus.SKIP === e.status,
                                                        [style.FAIL]:
                                                            SubmissionStatus.FAIL === e.status,
                                                    })}
                                                />
                                            </div>
                                        </StyledTooltip>
                                    </div>
                                    <StyledTooltip
                                        title={
                                            <div className="text-center">
                                                {format(new Date(e.firstMaxScoreDate), 'PP pp')}
                                                <br />
                                                <div className="text-sky-500">first reached at</div>
                                                {/* TODO show first commitId with max score */}
                                                {/* <div className="text-sky-300">{e.firstMax.commitId}</div> */}
                                            </div>
                                        }>
                                        <div className="flex max-w-[150px] flex-1 items-center justify-end gap-2 font-bold">
                                            {e.maxScore}
                                            <span className="text-xs font-normal text-gray-400">
                                                {e.maxScore === 1 ? 'point' : 'points'}
                                            </span>
                                        </div>
                                    </StyledTooltip>
                                </div>
                                <div
                                    className="h-1 min-w-[2px] bg-gradient-to-r from-orange-600 to-yellow-500"
                                    style={{ width: `${e.percentScore}%` }}
                                />
                            </div>
                        );
                    })}
                </div>
            );
        },
    ),
);

const nameFromStatus = (status: SubmissionStatus) => {
    switch (status) {
        case SubmissionStatus.IN_QUEUE:
            return 'in queue';
        case SubmissionStatus.IN_PROGRESS:
            return 'in progress';
        case SubmissionStatus.FAIL:
            return 'fail';
        case SubmissionStatus.COMPLETE:
            return 'complete';
        default:
            return 'undefined';
    }
};

const enhanceRankList = (
    isDisplayMock: boolean,
    state: LeaderboardState,
    me?: {
        githubId: number;
    },
) => {
    const list = state.rankList.map(e => ({
        ...e,
        isMe: me?.githubId === e.githubId,
    }));
    const first = list[0];
    if (!first) {
        return list;
    }
    if (isDisplayMock) {
        for (let i = 0; i < 9; i++) {
            const githubId = first.githubId + i;
            list.push({
                ...first,
                githubId,
                github: {
                    ...first.github,
                    githubId,
                    login: mockNicknames[i] || 'fakelogin',
                },
                maxScore: Math.floor(randi(0, first.maxScore * 1.2 + 100)),
                status: getRandomSubmissionStatus(),
                isMe: false,
            });
        }
    }

    const bestScore = list.reduce((a, v) => Math.max(a, v.maxScore), 0);

    if (isDisplayMock) {
        list.forEach(e => {
            if (e.isMe) {
                e.maxScore = bestScore - 1;
            }
        });
    }

    return list
        .map(e => ({
            ...e,
            percentScore: (e.maxScore / bestScore) * 100,
        }))
        .sort((a, b) => {
            const delta = b.maxScore - a.maxScore;
            if (delta !== 0) {
                return delta;
            }
            // for same score, show earlier submitters first
            return (
                new Date(a.firstMaxScoreDate).getTime() - new Date(b.firstMaxScoreDate).getTime()
            );
        });
};
