import { AxiosResponse } from 'axios';
import { v } from 'overshom-valid';
import { ObjectWithValidators } from 'overshom-valid/dist/types';
import { toast } from 'react-toastify';
import { IS_DEV_MODE } from 'typed-env';

export const conformResponse = async <R>(
    res: Promise<AxiosResponse<unknown>>,
    dto: new (source: any) => R,
): Promise<R> => {
    const { data } = await res;
    try {
        return new dto(data);
    } catch (e) {
        if (IS_DEV_MODE) {
            console.error(`DTO validation error ${dto.name}`, e);
            toast.error(`DTO validation error ${dto.name}`);
        }
        throw e;
    }
};

export const basePaginatedDtoBuilder = <T extends ObjectWithValidators>(schema: T) =>
    v.class({
        items: v.Array(v.Object(schema)),
        total: v.Number(),
    });
