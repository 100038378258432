import AsyncSelect from 'react-select/async';
import { Props } from 'react-select';
import { clsxm } from 'utils/clsxm';
import { PlaceType } from './GoogleMapsService';
import { useGMS } from './useGMS';

type OptionPlaceType = {
    label: string;
    value: string;
    place?: PlaceType;
};

export const GooglePlacesAutocomplete: React.FC<
    Omit<Props<OptionPlaceType, false>, 'value'> & { value?: OptionPlaceType }
> = ({ value, onChange }) => {
    const gms = useGMS();

    return (
        <AsyncSelect<OptionPlaceType>
            loadOptions={async search => {
                if (!gms || search.length < 3) {
                    return [];
                }
                const data = await gms.getPlacePredictions(search, ['locality']);
                const res = data.map(e => ({
                    place: e,
                    label: e.description,
                    value: e.placeId,
                }));
                return res;
            }}
            cacheOptions
            isLoading={!gms}
            placeholder="Search city"
            defaultOptions
            value={value?.label ? value : undefined}
            onChange={onChange}
            isClearable={Boolean(value?.label)}
            classNames={
                /*tw*/ {
                    control: () => clsxm('rounded-none outline-none', 'focus:ring-1'),
                    menu: () => clsxm('rounded-none outline-none', 'focus:ring-1'),
                    placeholder: () => 'text-gray-400',
                }
            }
        />
    );
};
