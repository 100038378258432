import { useMemo } from 'react';
import { clsxm } from 'utils/clsxm';

/**
 * @props progress {number} from 0 to 1
 */
export const PolyCircle: React.VFC<
    React.SVGProps<SVGSVGElement> & {
        progress?: number;
        thickness?: number;
        color?: string;
    }
> = ({ thickness = 4, progress = 1, color, className, ...props }) => {
    let delta = -0.8;
    if (progress < 0) {
        progress = 0;
        delta = 0;
    } else if (progress > 1) {
        progress = 1;
        delta = 0;
    }
    const dashoffset = PATHLENGTH * (1 - progress) + delta || 0;
    const idMask = useMemo(() => `mask_${Math.random()}`, []);

    return (
        <svg
            viewBox={VIEWBOX}
            xmlns="http://www.w3.org/2000/svg"
            className={clsxm('-rotate-90', className)}
            {...props}>
            {/* 
            TODO fix rendering of almost full progress e.g. 0.999
            currently, still some artifact left and too much stroke masked for 0.999 */}
            <mask id={idMask}>
                <polygon
                    fill="none"
                    stroke="white"
                    pathLength={PATHLENGTH}
                    strokeDashoffset={dashoffset}
                    strokeDasharray={PATHLENGTH}
                    strokeWidth={thickness * 2}
                    strokeLinecap="butt"
                    points={MASK_POINTS}
                    className="transition-[stroke-dashoffset]"
                />
                {/* Trick to mask artifact when progress 0 or 1 */}
                <rect
                    width={SCALE * 0.09}
                    height={SCALE * 0.03}
                    x={SCALE * 0.9}
                    y={SCALE * 0.47}
                    fill={progress === 1 ? 'white' : 'black'}
                />
            </mask>
            <polygon
                fill="none"
                stroke="#ededed"
                pathLength={PATHLENGTH}
                strokeWidth={thickness}
                strokeLinecap="square"
                points={POINTS}
            />
            <polygon
                fill="none"
                stroke={color}
                pathLength={PATHLENGTH}
                strokeWidth={thickness}
                strokeLinecap="butt"
                points={POINTS}
                mask={`url(#${idMask})`}
            />
        </svg>
    );
};

const CONVEX = 1;
const generateShapePoints = ({
    count = 3,
    radius = 15,
    shift = 20,
    vertexDelta = 0,
}: { count?: number; radius?: number; shift?: number; vertexDelta?: number } = {}) => {
    const points: string[] = [];
    for (let i = 0; i < count; i++) {
        const k = i % 2 === 0 ? 1 : CONVEX;
        if (!vertexDelta) {
            const x = Math.cos((i / count) * Math.PI * 2) * radius * k + shift;
            const y = Math.sin((i / count) * Math.PI * 2) * radius * k + shift;
            points.push(`${x},${y}`);
        } else {
            {
                const x = Math.cos(((i - vertexDelta) / count) * Math.PI * 2) * radius * k + shift;
                const y = Math.sin(((i - vertexDelta) / count) * Math.PI * 2) * radius * k + shift;
                points.push(`${x},${y}`);
            }
            {
                const x = Math.cos(((i + vertexDelta) / count) * Math.PI * 2) * radius * k + shift;
                const y = Math.sin(((i + vertexDelta) / count) * Math.PI * 2) * radius * k + shift;
                points.push(`${x},${y}`);
            }
        }
    }
    return points.join(' ');
};

const PATHLENGTH = 100;
const SCALE = 100;
const VIEWBOX = `0 0 ${SCALE} ${SCALE}`;
// provide even count of vertices
const POINTS = generateShapePoints({
    count: 6,
    radius: SCALE * 0.45,
    shift: SCALE * 0.5,
});
const MASK_POINTS = generateShapePoints({
    count: 6,
    radius: SCALE * 0.45,
    shift: SCALE * 0.5,
    vertexDelta: 0.05,
});
