import { useAuthState } from 'auth/useAuthState.hook';
import { useHandler } from 'hooks/useHandler.hook';
import { useIsMobile } from 'hooks/useIsMobile.hook';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AppGeneratedRoutes } from '../router/app-generated-routes';
import { clsxm } from '../utils/clsxm';
import { TfiMenu } from 'react-icons/tfi';
import { ClickAwayListener } from '@mui/material';
import { ConditionalWrapper } from 'hoc/ConditionalWrapper';
import { SignOutButton } from 'auth/SignOutButton';
import { AnimatedItem } from 'components/ux/AnimatedItem';

export const HeaderNavigation = React.memo(() => {
    return (
        <div className="flex items-center gap-2">
            <AnimatedLinks cacheKey="header" />
        </div>
    );
});

const ACTIVE_CLASS = '_active';

// workaround to keep animation styles on remount
// TODO remove when remount on navigation fixed
const cacheStyleMap = new Map<string, React.CSSProperties>();

// TODO extract to reusable component
// TODO fix remount, probably because of old version of Router
const AnimatedLinks = React.memo<{
    cacheKey: string;
}>(({ cacheKey }) => {
    const [style = cacheStyleMap.get(cacheKey), setStyle] = useState<React.CSSProperties>();
    const user = useAuthState();
    const prevContainerRef = useRef<Element | undefined | null>();
    const location = useLocation();
    const isMobile = useIsMobile();
    const [isOpen, setIsOpen] = useState(false);

    if (style) {
        cacheStyleMap.set(cacheKey, style);
    }

    const animate = useHandler((e?: null | Element, mode?: 'small') => {
        if (!(e instanceof HTMLElement) || !e.parentElement || isMobile) {
            setStyle(curr => ({
                ...curr,
                opacity: 0,
            }));
            return;
        }
        const parent = e.parentElement;
        if (!parent) {
            return;
        }
        const prect = parent.getBoundingClientRect();
        const rect = e.getBoundingClientRect();
        const width = mode === 'small' ? Math.max(rect.width * 0.4, 8) : rect.width;
        const calcResponsiveValue = (value: number, relativeTo = prect.width) =>
            `${(value / relativeTo) * 100}%`;
        setStyle({
            top: calcResponsiveValue(rect.top - prect.top + rect.height * 1, prect.height),
            left: calcResponsiveValue(rect.left - prect.left + rect.width * 0.5 - width * 0.5),
            width: calcResponsiveValue(width),
            opacity: 1,
        });
    });

    const checkContainer = useHandler(() => {
        const e = prevContainerRef.current;
        if (!e) {
            animate();
            return;
        }
        const node = [...e.children].find(e => e.classList.contains(ACTIVE_CLASS));
        animate(node, 'small');
    });

    const setRef = useHandler((e: HTMLElement | null) => {
        prevContainerRef.current = e;
        checkContainer();
    });

    useEffect(() => {
        if (isOpen && !isMobile) {
            setIsOpen(false);
        }
    }, [isOpen, isMobile]);

    useEffect(() => {
        checkContainer();
    }, [user, checkContainer, location, isMobile]);

    const enterHandler = useHandler<React.MouseEventHandler<HTMLAnchorElement>>(e => {
        animate(e.currentTarget);
    });

    const leaveHandler = useHandler<React.MouseEventHandler<HTMLDivElement>>(e => {
        prevContainerRef.current = e.currentTarget;
        checkContainer();
    });

    const awayHandler = useHandler(() => {
        if (isOpen) {
            setIsOpen(false);
        }
    });

    const memoLinks = useMemo(() => {
        const links: {
            to: string;
            content: JSX.Element | string;
        }[] = [
            {
                to: AppGeneratedRoutes.index,
                content: 'Home',
            },
        ];
        if (user) {
            links.push(
                ...[
                    {
                        to: AppGeneratedRoutes.competitions.my_active,
                        content: 'My Active',
                    },
                    {
                        to: AppGeneratedRoutes.competitions.my_completed,
                        content: 'My Completed',
                    },
                ],
            );
        }
        links.push({
            to: AppGeneratedRoutes.survey.index,
            content: 'Survey',
        });
        return links.map((e, i) => {
            return (
                <NavLink
                    key={`${i}${isOpen ? 'open' : 'not'}`}
                    to={e.to}
                    className={({ isActive }) =>
                        clsxm(
                            'p-2 text-gray-800',
                            isActive && 'font-bold text-sky-800',
                            isActive && ACTIVE_CLASS,
                            isMobile && isActive && 'shadow-[0_2px]',
                        )
                    }
                    onMouseEnter={enterHandler}
                    onClick={awayHandler}>
                    {isMobile && isOpen ? (
                        <AnimatedItem index={i}>{e.content}</AnimatedItem>
                    ) : (
                        e.content
                    )}
                </NavLink>
            );
        });
    }, [user, enterHandler, awayHandler, isMobile, isOpen]);

    return (
        // TODO show mobile burger menu
        <ClickAwayListener onClickAway={awayHandler}>
            <div className={clsxm('md:flex', isMobile ? '' : 'relative')}>
                {isMobile && <TfiMenu onClick={() => setIsOpen(!isOpen)} />}
                <div
                    // remount component to reset animation transition to avoid glitches
                    key={isMobile ? 'mob' : 'desk'}
                    aria-hidden
                    className={clsxm(
                        'flex transition duration-300',
                        isMobile &&
                            'absolute left-0 top-16 z-10 w-full flex-col bg-white p-4 shadow-main',
                        isMobile && !isOpen && 'pointer-events-none opacity-0',
                    )}
                    ref={setRef}
                    onMouseLeave={leaveHandler}>
                    {/* TODO NavLink - Ideology:
                    - Qualities of top developer
                    - Skill categories for frontend dev
                    - How to grow as developer
                    */}
                    <ConditionalWrapper
                        condition={isMobile}
                        wrapper={e => (
                            <div className="mx-auto flex max-w-sm flex-col gap-4 pb-4 text-center">
                                {e}
                            </div>
                        )}>
                        {memoLinks}
                        {Boolean(user) && isMobile && <SignOutButton className="mt-4" />}
                    </ConditionalWrapper>
                </div>
                {!isMobile && (
                    <div
                        className="pointer-events-none absolute top-full h-1 w-[8px] bg-sky-800 transition-all"
                        style={style}
                    />
                )}
            </div>
        </ClickAwayListener>
    );
});
