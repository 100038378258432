import { YouTubeIconSvg } from 'assets/logo/svg-logos';
import { ExternalLink } from 'components/ExternalLink/ExternalLink';

// TODO embed YouTube instead of external link
export const HowItWorksWidget: React.FC = () => {
    return (
        <div className="main-container p-2">
            <div className="flex justify-center md:justify-end">
                <ExternalLink href="https://youtu.be/Iq8Sho6WM-0">
                    <YouTubeIconSvg className="h-12 w-12" /> Як це працює? (укр 🇺🇦)
                </ExternalLink>
            </div>
        </div>
    );
};
