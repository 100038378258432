import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { routerHelper } from '../../router/router.helper';

// TODO refactor name to universal, not only auth
// component will scroll page after auth if special search param provided
export const MaybeScrollOnRender: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    // console.log('sp', searchParams, [...searchParams.entries()]);

    useEffect(() => {
        const scrollTo = searchParams.get(routerHelper.PARAM_KEY_FOR_SCROLL_TO);
        if (scrollTo) {
            const elem = document.querySelector(`#${scrollTo}`);
            if (!(elem instanceof HTMLElement)) {
                return;
            }
            window.scrollTo({ top: elem.offsetTop - 120 });
            searchParams.delete(routerHelper.PARAM_KEY_FOR_SCROLL_TO);
            setSearchParams(searchParams, {
                replace: true,
            });
        }
    }, [searchParams, setSearchParams]);

    return null;
};
