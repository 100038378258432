import { StyledButton } from 'components/form/StyledButton';
import { AnimatedItem } from 'components/ux/AnimatedItem';
import { StyledSection } from 'layout/StyledSection';
import { useState } from 'react';

export const UISectionAnimations: React.FC = () => {
    const [count, setCount] = useState(0);

    return (
        <StyledSection title="Animations">
            <StyledButton onClick={() => setCount(count + 1)}>animate</StyledButton>
            <div className="flex flex-col gap-2 p-2" key={count}>
                {LIST.map((e, i) => (
                    <AnimatedItem index={i} key={i} className="transition-all hover:pl-4">
                        <div className="bg-sky-800 p-2 text-sky-200">{e}</div>
                    </AnimatedItem>
                ))}
            </div>
        </StyledSection>
    );
};

const LIST = 'hello this is animated list of items'.split(' ');
