import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AdminGithubApi } from 'api/github';
import { StyledButton } from 'components/form/StyledButton';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { format } from 'date-fns';
import { SectionContainer } from 'layout/SectionContainer';
import { StyledSection } from 'layout/StyledSection';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const AdminGitHubPage: React.FC = () => {
    const { data, isLoading, error, refetch } = useQuery({
        // TODO constants
        queryKey: ['AdminGithubApi.getAdminHeaders'],
        queryFn: async () => AdminGithubApi.getAdminHeaders(),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const queryClient = useQueryClient();

    const refreshMutation = useMutation({
        mutationFn: async () => authBody && AdminGithubApi.refreshAdminGitHubToken(authBody),
        onSuccess: () => {
            queryClient.invalidateQueries(
                // TODO constants
                ['AdminGithubApi.getAdminHeaders'],
            );
            setParams({}, { replace: true });
        },
    });

    const [authBody, setAuthBody] = useState<{
        code: string;
        state: string;
    }>();

    // TODO refactor to react search params
    const [params, setParams] = useSearchParams();
    useEffect(() => {
        // console.log(params);
        const code = params.get('code');
        const state = params.get('state');
        if (code && state) {
            setAuthBody({ code, state });
        } else {
            setAuthBody(undefined);
        }
    }, [params]);

    const renderHeaders = () => {
        if ((isLoading && !data) || !data || error) {
            return (
                <PlaceholderBox
                    loading={isLoading}
                    noData={!data}
                    error={error instanceof Error ? error : new Error('something went wrong')}
                />
            );
        }
        const rawTimeForLimitReset = data && data['x-ratelimit-reset'];
        const timeForLimitReset = rawTimeForLimitReset
            ? format(+rawTimeForLimitReset * 1e3, 'PP pp')
            : null;
        return (
            <div>
                <pre>{JSON.stringify(data, null, 4)}</pre>
                {timeForLimitReset && (
                    <div>
                        <b>Limit reset at:</b> {timeForLimitReset}
                    </div>
                )}
            </div>
        );
    };

    const authMutation = useMutation({
        mutationFn: async () => AdminGithubApi.generateAdminLoginURL(),
        onSuccess: data => {
            window.location.href = data.loginUrl;
        },
    });

    const renderAuthSection = () => {
        if (!authBody) {
            return (
                <div>
                    <StyledButton
                        onClick={() => authMutation.mutate()}
                        loading={authMutation.isLoading}>
                        generate admin token
                    </StyledButton>
                </div>
            );
        }

        return (
            <div className="flex flex-col gap-4">
                <pre>{JSON.stringify(authBody, null, 4)}</pre>
                <div className="flex gap-4">
                    <StyledButton
                        onClick={() => {
                            setParams({}, { replace: true });
                        }}
                        disabled={refreshMutation.isLoading}>
                        cancel
                    </StyledButton>
                    <StyledButton
                        onClick={() => refreshMutation.mutate()}
                        loading={refreshMutation.isLoading}>
                        confirm new token
                    </StyledButton>
                </div>
            </div>
        );
    };

    return (
        <SectionContainer>
            <StyledSection title="Admin GitHub">
                <div className="flex flex-col gap-4">
                    <div>
                        <StyledButton onClick={() => refetch()}>refetch headers</StyledButton>
                    </div>
                    {renderHeaders()}
                </div>
            </StyledSection>
            <StyledSection title="Admin oAuth">
                <div className="flex flex-col gap-4">
                    <div className="bg-yellow-300 p-2">
                        WARNING: dangerous operation. Proceed with caution. Refresh oAuth admin
                        token with a new one if any issues with existing token observed.
                    </div>
                    {renderAuthSection()}
                </div>
            </StyledSection>
        </SectionContainer>
    );
};
