import { restAxios } from 'api/restAxios';
import { ExecaHistorySchema } from './hostExeca.schema';
import { HostExecaOptions } from './hostExeca.types';

export class HostExecaApi {
    static async exec(body: { cmd: string; args?: string[]; options?: HostExecaOptions }) {
        // TODO validate response schema
        const { data } = await restAxios.post('/private/host-execa/exec', body);
        return data;
    }

    static async getHealth() {
        // TODO validate response schema
        const { data } = await restAxios.get('/private/host-execa/health');
        return data;
    }

    static async getHistory() {
        // TODO validate response schema
        const res = await restAxios.get('/private/host-execa/history');
        const contentLength = +(res.headers['content-length'] || 0);
        const data = ExecaHistorySchema.parse(res.data);
        return { data, contentLength };
    }

    static async clear() {
        // TODO validate response schema
        const { data } = await restAxios.post('/private/host-execa/clear');
        return ExecaHistorySchema.parse(data);
    }
}
