import { NavLink } from 'react-router-dom';
import { AppGeneratedRoutes } from 'router/app-generated-routes';
import { clsxm } from '../utils/clsxm';
import { RequirePermissions } from 'hoc';
import { UserPermission } from 'types';
import { AdminLocalConfigWidget } from 'pages/dev-kit/-page/AdminLocalConfigWidget';
import { Popover } from 'components/popover/Popover';

export const AdminMenu: React.FC = () => {
    return (
        <RequirePermissions atLeast={UserPermission.ADMIN}>
            <Popover
                width={400}
                content={
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        <div>
                            <AdminLocalConfigWidget />
                        </div>
                        <div className="flex flex-col divide-y">
                            {ADMIN_LINKS.map((e, i) => {
                                return (
                                    <NavLink
                                        key={i}
                                        to={e.link}
                                        className={({ isActive }) =>
                                            clsxm(
                                                'px-2 py-1 text-gray-800',
                                                'hover:bg-sky-100',
                                                isActive && 'underline',
                                            )
                                        }>
                                        {e.title}
                                    </NavLink>
                                );
                            })}
                        </div>
                    </div>
                }>
                <div className="cursor-default font-bold">{`<- admin ->`}</div>
            </Popover>
        </RequirePermissions>
    );
};

const ADMIN_LINKS: {
    link: string;
    title: string;
}[] = [
    {
        link: AppGeneratedRoutes.admin.create,
        title: '+ competition',
    },
    {
        link: AppGeneratedRoutes.admin.explore_url,
        title: 'explore url',
    },
    {
        link: AppGeneratedRoutes.admin.admin_all_profiles,
        title: 'Admin All Profiles',
    },
    {
        link: AppGeneratedRoutes.admin.github,
        title: 'GitHub',
    },
    {
        link: AppGeneratedRoutes.admin.docker_containers,
        title: 'Docker Containers',
    },
    {
        link: AppGeneratedRoutes.dev_kit.index,
        title: 'Dev Kit',
    },
    {
        link: AppGeneratedRoutes.ui_kit.index,
        title: 'UI Kit',
    },
    {
        link: AppGeneratedRoutes.partnership.school_837593,
        title: 'Partnership - schools',
    },
    {
        link: AppGeneratedRoutes.benchmark,
        title: 'Benchmark',
    },
];
