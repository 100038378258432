import { StyledSection } from 'layout/StyledSection';
import { GiLaptop, GiKeyLock, GiWifiRouter } from 'react-icons/gi';

export const UISectionIcons: React.FC = () => {
    return (
        <StyledSection title="Icons">
            <div>Any icon from {`"react-icons"`}. No preference for a specific collection.</div>
            <div className="my-8 flex justify-around gap-2">
                <div className="flex flex-col items-center">
                    <div className="h-24 w-24 bg-white p-4">
                        <GiLaptop className="h-full w-full fill-sky-800" />
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <div className="h-24 w-24 bg-white p-4">
                        <GiKeyLock className="h-full w-full fill-sky-800" />
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <div className="h-24 w-24 bg-white p-4">
                        <GiWifiRouter className="h-full w-full fill-sky-800" />
                    </div>
                </div>
            </div>
        </StyledSection>
    );
};
