import { SectionContainer } from 'layout/SectionContainer';
import { HowItWorksWidget } from 'pages/-shared/HowItWorksWidget';
import React from 'react';
import { StyledSection } from '../../layout/StyledSection';
import { CompetitionsPublicExplore } from './CompetitionsPublicExplore';
import { LandingStep } from './landing/step';
import { WelcomeBanner } from './WelcomeBanner';

export const HomePage: React.FC = () => {
    return (
        <>
            <WelcomeBanner />
            <SectionContainer>
                <HowItWorksWidget />
                <StyledSection title="Events To Join">
                    <CompetitionsPublicExplore />
                </StyledSection>
                <StyledSection title="How It Works?">
                    {/* TODO embed video 1 min to highlight the ease of process */}
                    <LandingStep number={1} title="Clone repo to your machine 💻">
                        As simple as{' '}
                        <span className="bg-slate-200 p-2 py-1 text-slate-600">
                            git clone git@github.com:targer-competition-url
                        </span>
                    </LandingStep>
                    <LandingStep number={2} title="Solve problems described in README 🎯 🧠">
                        Same as on real life software development projects.
                    </LandingStep>
                    <LandingStep number={3} title="Push your solution ✅">
                        Your submission will run through a series of tests automatically.
                    </LandingStep>
                    <LandingStep
                        number={4}
                        title="Gain maximum points before competition end 💯 ⏳">
                        Push regularly. Watch your rank updated in real time.
                    </LandingStep>
                    <LandingStep number={5} title="🎉 Celebrate your score 🚀">
                        Become top ranked developer globally.
                    </LandingStep>
                </StyledSection>
            </SectionContainer>
        </>
    );
};
