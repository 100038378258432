import { StyledSwitch } from 'components/form/StyledSwitch';
import { useAdminLocalConfig } from './useAdminLocalConfig.hook';

export const AdminLocalConfigWidget: React.FC = () => {
    const [config, setConfig] = useAdminLocalConfig();

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-wrap items-center gap-4 p-2">
                <StyledSwitch
                    checked={config.mockLeaderboard}
                    onChange={val => setConfig(prev => ({ ...prev, mockLeaderboard: val }))}
                />
                <div>Mock Leaderboard</div>
            </div>
            <div className="flex flex-wrap items-center gap-4 p-2">
                <StyledSwitch
                    checked={config.adminLeaderboard}
                    onChange={val => setConfig(prev => ({ ...prev, adminLeaderboard: val }))}
                />
                <div>Admin Leaderboard</div>
            </div>
            <div className="flex flex-wrap items-center gap-4 p-2">
                <StyledSwitch
                    checked={config.competitionControls}
                    onChange={val => setConfig(prev => ({ ...prev, competitionControls: val }))}
                />
                <div>Competition Controls</div>
            </div>
            <div className="flex flex-wrap items-center gap-4 p-2">
                <StyledSwitch
                    checked={config.adminTickets}
                    onChange={val => setConfig(prev => ({ ...prev, adminTickets: val }))}
                />
                <div>Admin Tickets</div>
            </div>
            <div className="flex flex-wrap items-center gap-4 p-2">
                <StyledSwitch
                    checked={config.adminPromos}
                    onChange={val => setConfig(prev => ({ ...prev, adminPromos: val }))}
                />
                <div>Admin Promos</div>
            </div>
        </div>
    );
};
