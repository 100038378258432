import React, { useEffect, useState } from 'react';
import { AuthApi } from '../api/auth';
import GitHub from '../assets/logo/github.svg';
import { useAsyncFn } from 'react-use';
import { silent } from '../utils/promise.util';
import { useSelector } from 'react-redux';
import { selectIsAuthLoading } from '../store/auth/selectors';
import { clsxm } from '../utils/clsxm';
import { calcChamferedCorners } from 'styles/style.util';
import { routerHelper } from 'router/router.helper';

const style = calcChamferedCorners([1, 0, 1, 0]);

export const SignInButton: React.FC<{
    smallText?: boolean;
    scrollToOnAuth?: string;
}> = ({ smallText, scrollToOnAuth }) => {
    const [stateLoading, setStateLoading] = useState(false);
    const isAuthLoading = useSelector(selectIsAuthLoading);
    // TODO refactor to react-query
    const [{ value: auth, loading }, signIn] = useAsyncFn(async () => {
        const continuePage = new URL(window.location.href);
        continuePage.search = '';
        if (scrollToOnAuth) {
            continuePage.searchParams.set(routerHelper.PARAM_KEY_FOR_SCROLL_TO, scrollToOnAuth);
        }
        return silent(
            AuthApi.generateLoginUrl(encodeURIComponent(encodeURIComponent(continuePage.href))),
        );
    }, [scrollToOnAuth]);

    useEffect(() => {
        if (!auth) {
            setStateLoading(false);
            return;
        }
        setStateLoading(true);
        window.location.href = auth.loginUrl;
        const timer = setTimeout(() => {
            setStateLoading(false);
        }, 2e3);
        return () => {
            clearTimeout(timer);
        };
    }, [auth]);

    return (
        <button
            style={style}
            onClick={signIn}
            disabled={loading || isAuthLoading || stateLoading}
            className={clsxm(
                'flex items-center justify-center gap-2',
                'bg-zinc-800 p-2 capitalize text-white transition',
                'hover:bg-zinc-700',
                'active:bg-zinc-900',
                'disabled:bg-gray-500 disabled:text-gray-300',
            )}>
            <img src={GitHub} alt="github logo" />
            {smallText ? (
                'Sign In'
            ) : (
                <>
                    Sign in with <b>GitHub</b>
                </>
            )}
        </button>
    );
};
