import { IModuleConfig } from 'router/router.types';
import { Outlet } from 'react-router-dom';
import { Layout } from 'layout/Layout';

export const routeConfig: IModuleConfig = {
    Layout: () => (
        <Layout>
            <Outlet />
        </Layout>
    ),
};
