import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsAuthorized } from '../store/auth/selectors';
import { getSessionToken } from '../utils';
import { AuthThunk } from '../store/auth/thunk';
import { RouterRoutesMapping } from './RouterRoutesMapping';
import { useLocation } from 'react-router-dom';

export const Router: React.FC = () => {
    const isAuthorized = useSelector(selectIsAuthorized);
    const dispatch = useDispatch();

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if (!isAuthorized) {
        const token = getSessionToken();
        if (token) {
            dispatch(AuthThunk.authorize({ token }));
            return null;
        }
    }

    return <RouterRoutesMapping isAuthorized={isAuthorized} />;
};
