import { Link } from 'react-router-dom';
import { clsxm } from 'utils/clsxm';
import Logo from 'branding/targer.svg';

export const AppLogoLink: React.FC<{
    hideTitleOnMobile?: boolean;
    className?: string;
}> = ({ hideTitleOnMobile = true, className }) => {
    return (
        <Link
            to="/"
            className={clsxm(
                'flex justify-center gap-2 p-2 text-[32px] font-bold',
                'hover:bg-gray-100 hover:text-gray-700',
                'active:bg-gray-200 active:text-gray-900',
                className,
            )}>
            <img src={Logo} alt="Targer logo" />
            <div className={clsxm(hideTitleOnMobile && 'hidden md:block')}>targer.dev</div>
        </Link>
    );
};
