export const createCachedAsync = <F extends (...args: any) => Promise<any>>(
    f: F,
    expirationMs: number,
): F => {
    let lastCall = 0;
    let cache: any;
    const wrap: (...args: any) => Promise<any> = async (...args) => {
        const now = Date.now();
        const delta = now - lastCall;
        if (delta > expirationMs || !cache) {
            lastCall = now;
            const res = await f(...(args as any));
            cache = res;
            return res;
        }
        return cache;
    };
    return wrap as any;
};

/**
 * Asynchronously await for desired ms time
 */
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Used to provide minimum await time for sync actions
 * primarily to show nice loader animation
 */
export const animationSleep = async <T>(p: Promise<T>, ms = 200): Promise<T> => {
    const [res] = await Promise.all([p, sleep(ms)]);
    return res;
};
