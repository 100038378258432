import { restAxios } from '../restAxios';
import { conformResponse } from '../helper';
import { ResponsePaginatedRepoDto } from './dto';
import { ResponseLoginUrlDto } from 'api/auth/dto';

export class AdminGithubApi {
    static async generateAdminLoginURL() {
        return conformResponse(
            restAxios.get('private/admin-auth/generate-admin-login-url'),
            ResponseLoginUrlDto,
        );
    }

    // TODO refactor to SignInReqSchema
    static async refreshAdminGitHubToken(body: {
        code: string;
        state: string;
    }): Promise<Record<string, string>> {
        return (await restAxios.post('private/github/refresh-admin-token', body)).data;
    }

    static async getAdminHeaders(): Promise<Record<string, string>> {
        return (await restAxios.get('private/github/admin-headers')).data;
    }

    static async getTargerRepos() {
        return conformResponse(
            restAxios.get('private/github/targer-repos'),
            ResponsePaginatedRepoDto,
        );
    }

    // TODO remove it from prod. Only dev purposes.
    static async deleteTargerRepo(repoHtmlUrl: string) {
        return restAxios.delete(`private/github/delete-repo?repoHtmlUrl=${repoHtmlUrl}`);
    }
}
