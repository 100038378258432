import { useSocket } from './socket';
import { useEffect } from 'react';

export const useSocketSubscription = <T>(room: string, handler: (data: T) => void) => {
    const { socket, isConnected } = useSocket();

    useEffect(() => {
        if (socket && isConnected) {
            socket.emit(`subscribe`, { room });
            socket.on(room, handler);
            return () => {
                socket.emit(`unsubscribe`, { room });
                socket.off(room, handler);
            };
        }
    }, [room, socket, isConnected, handler]);
};
