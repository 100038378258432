import { SignInButton } from 'auth/SignInButton';
import { Popover } from 'components/popover/Popover';
import { ConditionalWrapper } from 'hoc/ConditionalWrapper';
import React from 'react';
import { Link } from 'react-router-dom';
import { calcChamferedCorners } from 'styles/style.util';
import { clsxm } from '../../utils/clsxm';
import styles from './button.module.scss';

const style = calcChamferedCorners([1, 0, 1, 0]);

export const StyledButton: React.FC<
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
        loading?: boolean;
        linkTo?: string;
        requireAuth?: boolean;
        scrollToOnAuth?: string;
        secondary?: boolean;
    }
> = ({
    children,
    loading,
    className,
    linkTo,
    requireAuth,
    scrollToOnAuth,
    secondary,
    ...other
}) => {
    const button = (
        <ConditionalWrapper
            condition={Boolean(linkTo)}
            wrapper={children => <Link to={linkTo || ''}>{children}</Link>}>
            <div className="relative inline-flex">
                {loading && (
                    <div
                        className={clsxm(
                            styles.loading,
                            'absolute left-0 top-0 h-full w-full blur-sm',
                        )}
                    />
                )}
                <button
                    style={style}
                    className={clsxm(
                        !secondary && 'bg-sky-800 text-white',
                        !secondary && 'hover:bg-sky-700',
                        !secondary && 'active:bg-sky-900',
                        secondary && 'bg-sky-100 text-sky-800',
                        secondary && 'hover:bg-sky-200',
                        secondary && 'active:bg-blue-200',
                        'p-2 px-4 capitalize transition',
                        loading
                            ? 'disabled:bg-sky-700/50'
                            : [
                                  !requireAuth && 'disabled:bg-gray-500 disabled:text-gray-300',
                                  requireAuth && 'pointer-events-none',
                              ],
                        className,
                    )}
                    {...other}
                    disabled={requireAuth || other.disabled || loading}>
                    {children}
                </button>
            </div>
        </ConditionalWrapper>
    );
    if (!requireAuth) {
        return button;
    }
    return (
        <Popover
            content={
                <>
                    <div>sure, but first</div>
                    <SignInButton smallText scrollToOnAuth={scrollToOnAuth} />
                </>
            }
            className="-right-6"
            contentClassName="bg-zinc-500 text-white">
            {button}
        </Popover>
    );
};
