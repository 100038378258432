import { conformResponse } from 'api/helper';
import { restAxios } from 'api/restAxios';
import { EPromoType, ResponseCompetitionAllPromoZod, ResponseMaybePromoZod } from './promo.schema';

export class PromoApi {
    static async checkPromo({ code, competitionId }: { code: string; competitionId: string }) {
        const res = await conformResponse(
            restAxios.get(`mixed/promos/for-competition/${competitionId}/find-one/${code}`),
            ResponseMaybePromoZod,
        );
        if (res.promo && res.promo.type !== EPromoType.EXACT_USD_COIN) {
            // implement new types if needed
            throw new Error('invalid promo type');
        }
        return res;
    }

    static async getCompetitionPromos({ competitionId }: { competitionId: string }) {
        const res = await conformResponse(
            restAxios.get(`private/promos/for-competition/${competitionId}/all-code-stats`),
            ResponseCompetitionAllPromoZod,
        );
        return res;
    }
}
