import { Popover } from 'components/popover/Popover';
import { StyledTooltip } from 'components/styled-tooltip/StyledTooltip';
import { StyledSection } from 'layout/StyledSection';
import { UISectionTimer } from './featured/UISectionTimer';

export const UISectionFeatured: React.FC = () => {
    return (
        <div>
            <UISectionTimer />
            <StyledSection title="Popover and Tooltip">
                <div className="flex divide-x">
                    <div className="p-2">
                        <Popover content="hi there" triggerClassName="inline-block" width={120}>
                            hello, hover me
                        </Popover>
                    </div>
                    <div className="p-2">
                        <StyledTooltip title="content">
                            <span>MUI tooltip</span>
                        </StyledTooltip>
                    </div>
                    <div className="p-2">
                        <StyledTooltip title="useful for hovering tooltip" leaveDelay={400}>
                            <span>Delayed MUI tooltip</span>
                        </StyledTooltip>
                    </div>
                </div>
            </StyledSection>
        </div>
    );
};
