export const MOCK_APP_ROUTES = {
    index: '/',
    auth: {
        'sign-up': 'sign-up',
        'forgot-password': {
            index: '/',
            confirm: 'confirm',
        },
        'sign-in': {
            index: '/',
            '2fa': '2fa',
        },
    },
    admin: {
        'user-management': {
            customers: 'customers',
            admins: 'admins',
        },
        sessions: 'sessions',
        dashboard: {
            main: 'main',
            other: 'other',
        },
        foo: {
            bar: {
                bar: 'bar',
                bag: {
                    bar: {
                        baz: 'baz',
                        foo: {
                            jaz: 'jaz',
                        },
                    },
                },
                jaz: 'jaz',
                foo: 'foo',
                baz: 'baz',
            },
        },
        bar: {
            foo: 'foo',
        },
    },
    customer: {
        profile: 'profile',
    },
    'dev-kit': {
        index: '/',
        sitemap: {
            index: '/',
            export: 'export',
        },
    },
    'ui-kit': {
        index: '/',
    },
};
