import { useMutation } from '@tanstack/react-query';
import { StyledButton } from 'components/form/StyledButton';
import { StyledInput } from 'components/styled-input/StyledInput';
import { PromoApi } from 'domain/promo/promo.api';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { animationSleep } from 'utils/async.util';
import { PromoZod } from './promo.schema';

export const PromoForm: React.FC<{
    competitionId: string;
    onPromoChange: (promo?: PromoZod) => void;
    open: boolean;
    setOpen: (bool: boolean) => void;
}> = ({ competitionId, onPromoChange, open, setOpen }) => {
    const { handleSubmit } = useForm();
    const [code, setCode] = useState('');
    const [input, setInput] = useState<HTMLInputElement | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const promoParam = searchParams.get(PROMO_SEARCH_PARAM);

    const {
        isLoading,
        mutate: checkPromo,
        data,
    } = useMutation(
        async () => {
            if (!code || !competitionId) {
                return;
            }
            return animationSleep(PromoApi.checkPromo({ code, competitionId }), 900);
        },
        {
            onSuccess: res => {
                onPromoChange(res?.promo);
                if (res?.promo) {
                    setOpen(false);
                    setSearchParams(
                        {
                            [PROMO_SEARCH_PARAM]: res.promo.code,
                        },
                        { replace: true },
                    );
                }
            },
        },
    );

    useEffect(() => {
        if (input && open) {
            input.focus();
        }
    }, [input, open]);

    useEffect(() => {
        if (!promoParam) {
            return;
        }
        const normalCode = normalizePromoCode(promoParam);
        if (normalCode) {
            setCode(normalCode);
        }
    }, [promoParam]);

    useEffect(() => {
        if (!promoParam || !code) {
            return;
        }
        const normalCode = normalizePromoCode(promoParam);
        if (normalCode === code) {
            checkPromo();
        }
    }, [code, promoParam, checkPromo]);

    return (
        <div className="flex flex-col items-center gap-4">
            {open ? (
                <>
                    <form
                        onSubmit={handleSubmit(() => {
                            checkPromo();
                        })}>
                        <StyledInput
                            ref={setInput}
                            label="Get discount"
                            placeholder="PROMO_CODE"
                            value={code}
                            disabled={isLoading}
                            onChange={e => setCode(normalizePromoCode(e.target.value))}
                        />
                    </form>
                    <div className="flex gap-4">
                        <StyledButton
                            secondary
                            disabled={isLoading}
                            onClick={() => {
                                setCode('');
                                setOpen(false);
                            }}>
                            Cancel
                        </StyledButton>
                        <StyledButton
                            disabled={!code}
                            loading={isLoading}
                            onClick={() => checkPromo()}>
                            Apply
                        </StyledButton>
                    </div>
                    <div>
                        {!isLoading && data && !data.promo && (
                            <div className="text-orange-300">Not found. Maybe expired</div>
                        )}
                    </div>
                </>
            ) : (
                <div
                    className="cursor-pointer text-sky-600 underline"
                    onClick={() => setOpen(true)}
                    aria-hidden>
                    Have a promo?
                </div>
            )}
        </div>
    );
};

const PROMO_SEARCH_PARAM = 'promo';

const normalizePromoCode = (code?: string) =>
    code?.trim().replaceAll(/\s+/g, '').toUpperCase() || '';
