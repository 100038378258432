import { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { format } from 'date-fns';
import { generatePath } from 'react-router-dom';
import { clsxm } from 'utils/clsxm';
import { AppLogoLink } from 'branding/AppLogoLink';
import { StyledTooltip } from 'components/styled-tooltip/StyledTooltip';
import { ResponseMyTicketZod, ResponseTicketsCompetitionStatsDto } from 'api/ticket/ticket.dto';
import { ResponseCompetitionAdvancedInfoDto } from 'api/competition/dto';
import { convertCoin2Unit } from 'utils/currency.util';
import { DATE_FORMAT_DAY_DATE } from 'constants/format.constant';
import { useIsMobile } from 'hooks/useIsMobile.hook';
import { AppGeneratedRoutes } from 'router/app-generated-routes';
import { TicketStatus } from 'types';
import { LogoJavaScript } from 'assets/logo/svg-logos';
import { calcChamferedCorners } from 'styles/style.util';
import { EPromoType, PromoZod } from 'domain/promo/promo.schema';

/**
 * Rendering optimization notes:
 * - constant React.CSSProperties
 * - check for node visibility on screen, skip when hidden
 * - requestAnimationFrame
 * -- skip render on same timestamp
 * - pointer-events-none
 * - css prop "will-change: 'transform'" to create isolated layer
 * - mutate dom node styles directly instead of useState
 * - passive event listener
 */

export const Ticket: React.FC<{
    competition: ResponseCompetitionAdvancedInfoDto;
    tickets?: ResponseTicketsCompetitionStatsDto;
    myTicket?: ResponseMyTicketZod;
    appliedPromo?: PromoZod;
}> = ({ myTicket, tickets, competition, appliedPromo }) => {
    const isMobile = useIsMobile();
    const [ticket, setTicket] = useState<null | HTMLDivElement>(null);
    const [refCard, setRefCard] = useState<null | HTMLDivElement>(null);
    const [refGloss, setRefGloss] = useState<null | HTMLDivElement>(null);
    const isPurchased = myTicket?.ticket?.status === TicketStatus.PAID;
    const isSoldOut = !isPurchased && (tickets?.ticketsAvailable || 0) < 1;
    const isOnSale = !isPurchased && !isSoldOut;

    const style = isMobile ? TICKET_MOBILE_STYLE : TICKET_DESKTOP_STYLE;
    const glossStyle = GLOSS_STYLE;

    useEffect(() => {
        if (!ticket) {
            return;
        }

        let clientX = 0;
        let clientY = 0;
        let noMouseMoved = true;

        const MAX_DEG = 65;
        /**
         * set minimum FPS rate for animation to run.
         * if actual FPS is lower than animation will be disabled.
         */
        const ANIMATION_MINIMUM_FPS = 40;
        // ms time between frames to count as fast renders
        const animationFrameDelayThreshold = 1e3 / ANIMATION_MINIMUM_FPS;
        const FRAMES_TO_TRACK = 40;
        const MINIMUM_FAST_RENDERS_COUNT = FRAMES_TO_TRACK * 0.6;
        let prevTimestamp = 0;
        let fastRenders = 0;
        let totalRenders = 0;
        let isAnimationFPSRatePrettyHigh = true;

        const render: FrameRequestCallback = timestamp => {
            if (!isAnimationFPSRatePrettyHigh) {
                return;
            }
            if (prevTimestamp === timestamp) {
                // console.log('skip ticket render', timestamp);
                return;
            }
            const deltaTime = timestamp - prevTimestamp;
            prevTimestamp = timestamp;
            if (!ticket.offsetParent || !ticket.offsetWidth || !refCard || !refGloss) {
                return;
            }
            const { x, y, width, height } = ticket.getBoundingClientRect();
            const isHiddenFromTop = y + height < 0;
            const isHiddenFromBottom = y > document.documentElement.clientHeight;
            const isHidden = isHiddenFromTop || isHiddenFromBottom;
            if (isHidden) {
                return;
            }

            totalRenders++;
            if (totalRenders < FRAMES_TO_TRACK) {
                if (deltaTime < animationFrameDelayThreshold) {
                    fastRenders++;
                }
                if (totalRenders === FRAMES_TO_TRACK) {
                    if (fastRenders < MINIMUM_FAST_RENDERS_COUNT) {
                        // console.log('disable animation, not enough fast renders');
                        isAnimationFPSRatePrettyHigh = false;
                    }
                    // console.log('fps stats', { totalRenders, fastRenders });
                }
            }

            const centerPoint = {
                x: x + width / 2,
                y: y + height / 2,
            };

            if (noMouseMoved || isMobile) {
                clientX = document.documentElement.clientWidth / 2;
                clientY = document.documentElement.clientHeight / 2;
            }

            let dx = clientX - centerPoint.x;
            let dy = clientY - centerPoint.y;

            // reset effects for FPS devices
            if (!isAnimationFPSRatePrettyHigh) {
                dx = 0;
                dy = 0;
            }

            const degreeX = -clamp(-MAX_DEG, MAX_DEG, dy * 0.08);
            const degreeY = isMobile ? 0 : clamp(-MAX_DEG, MAX_DEG, dx * 0.04);
            // console.log(degreeX, degreeY);

            refCard.style.transform = `perspective(1000px) rotateX(${degreeX}deg) rotateY(${degreeY}deg)`;

            const glossSize = Math.max(width, height);
            const kGloss = { x: 2, y: 4 };

            refGloss.style.top = `${
                height / 2 -
                glossSize / 2 +
                (height / 2 - glossSize * 0.01) * clamp(-1, 1, (degreeX / MAX_DEG) * kGloss.x)
            }px`;
            refGloss.style.left = `${
                width / 2 -
                glossSize / 2 -
                (width / 2 - glossSize * 0.1) * clamp(-1, 1, (degreeY / MAX_DEG) * kGloss.y)
            }px`;
            refGloss.style.width = `${glossSize}px`;
            refGloss.style.height = `${glossSize}px`;
        };

        const requestRender = () => requestAnimationFrame(render);

        // initial render
        requestRender();

        const mouseHandler = (e: MouseEvent) => {
            noMouseMoved = false;
            clientX = e.clientX;
            clientY = e.clientY;
            requestRender();
        };
        window.addEventListener('mousemove', mouseHandler, { passive: true });
        window.addEventListener('scroll', requestRender, { passive: true });
        return () => {
            window.removeEventListener('mousemove', mouseHandler);
            window.removeEventListener('scroll', requestRender);
        };
    }, [ticket, isMobile, refCard, refGloss]);

    const usdPrice = (() => {
        if (myTicket?.ticket) {
            return convertCoin2Unit(myTicket.ticket.priceCoin);
        }
        if (appliedPromo) {
            if (appliedPromo.type === EPromoType.EXACT_USD_COIN) {
                return convertCoin2Unit(appliedPromo.effectValue);
            }
            return -1;
        }
        return convertCoin2Unit(competition.priceCoin);
    })();

    const promoCode = myTicket?.ticket?.promoCode || appliedPromo?.code;

    return (
        <div className="flex w-full justify-center p-2 px-0 md:p-4 md:py-12">
            <div
                ref={setTicket}
                className="group pointer-events-none w-full max-w-xs select-none drop-shadow-xl md:max-w-xl">
                <div
                    ref={setRefCard}
                    className={clsxm(
                        'relative p-4 text-slate-100 transition-opacity duration-300',
                        'bg-gradient-to-r from-zinc-900 to-slate-900',
                        'group-hover:opacity-100',
                    )}
                    style={style}>
                    <div className="flex min-h-[240px] flex-col justify-between gap-4">
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col items-center gap-4 md:flex-row">
                                <div className="text-xs text-slate-400">LIVE EVENT TICKET</div>
                                {/* TODO match color with lang */}
                                <div className="flex-1 text-center text-3xl font-bold text-yellow-400">
                                    {competition.title}
                                </div>
                                <div>
                                    {/* TODO match icon with lang */}
                                    <LogoJavaScript style={langIconStyle} />
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center gap-4 md:flex-row md:gap-16">
                                <div className="flex flex-col gap-4 text-center">
                                    <div className="relative flex text-xl">
                                        <div
                                            className={clsxm(
                                                'absolute -left-16 h-full w-16 bg-gradient-to-r from-transparent',
                                                isOnSale && 'to-cyan-900',
                                                isPurchased && 'to-emerald-900',
                                                isSoldOut && 'to-red-900',
                                            )}
                                        />
                                        <div
                                            className={clsxm(
                                                'w-full',
                                                isOnSale && 'bg-cyan-900',
                                                isPurchased && 'bg-emerald-900',
                                                isSoldOut && 'bg-red-900',
                                            )}>
                                            {isOnSale && 'AVAILABLE'}
                                            {isPurchased && 'PURCHASED'}
                                            {isSoldOut && 'SOLD OUT'}
                                        </div>
                                        <div
                                            className={clsxm(
                                                'absolute -right-16 h-full w-16 bg-gradient-to-r',
                                                isOnSale && 'from-cyan-900',
                                                isPurchased && 'from-emerald-900',
                                                isSoldOut && 'from-red-900',
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-sm text-slate-600">start at</div>
                                        {format(
                                            new Date(competition.startDate),
                                            DATE_FORMAT_DAY_DATE,
                                        )}
                                    </div>
                                    <div>{format(new Date(competition.startDate), 'p')}</div>
                                </div>
                                <StyledTooltip title={competition.id}>
                                    <div className="pointer-events-auto">
                                        <QRCode
                                            className="pointer-events-none"
                                            // TODO refactor to typed generatePath util
                                            value={`${window.location.protocol}//${
                                                window.location.host
                                            }${generatePath(
                                                AppGeneratedRoutes.competitions._competitionId_,
                                                { competitionId: competition.id },
                                            )}`}
                                            size={128}
                                        />
                                    </div>
                                </StyledTooltip>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <AppLogoLink className="pointer-events-none text-slate-500" />
                            <StyledTooltip title={promoCode ? `applied promo ${promoCode}` : ''}>
                                <div className="pointer-events-auto flex-1 text-right text-2xl font-bold text-teal-400 md:text-center">
                                    {usdPrice} USD
                                </div>
                            </StyledTooltip>
                        </div>
                    </div>
                    <div
                        style={isMobile ? STRIP_MOBILE_STYLE : STRIP_DESKTOP_STYLE}
                        className="absolute">
                        <div
                            className={clsxm(
                                'flex h-full w-full items-center justify-center gap-4 text-xl font-bold',
                                !isMobile && 'rotate-90',
                            )}>
                            <div className="text-slate-700">ID:</div>
                            <div className="whitespace-nowrap text-3xl uppercase">
                                {myTicket?.ticket ? myTicket.ticket.id.slice(0, 5) : '----'}
                            </div>
                            <StyledTooltip title={myTicket?.ticket?.id || ''}>
                                <div
                                    className={clsxm(
                                        'inline-block h-4 w-4 shrink-0 cursor-help rounded-full ring-2 ring-slate-700',
                                        myTicket?.ticket?.id && 'pointer-events-auto',
                                    )}
                                />
                            </StyledTooltip>
                        </div>
                    </div>
                    <div
                        ref={setRefGloss}
                        style={glossStyle}
                        className="pointer-events-none absolute"
                    />
                </div>
            </div>
        </div>
    );
};

const clamp = (low: number, high: number, value: number) => Math.max(low, Math.min(high, value));

const langIconStyle = calcChamferedCorners([1, 1, 1, 1]);

const TICKET_COMMON_STYLE: React.CSSProperties = {
    willChange: 'transform',
};

const ID_DESKTOP_PAD = 120;
const TICKET_DESKTOP_STYLE: React.CSSProperties = {
    ...TICKET_COMMON_STYLE,
    paddingRight: ID_DESKTOP_PAD + 16,
    clipPath: `polygon(${[
        `0 8px`,
        `8px 0`,
        `calc(100% - ${ID_DESKTOP_PAD + 12}px) 0`,
        `calc(100% - ${ID_DESKTOP_PAD}px) ${12}px`,
        `calc(100% - ${ID_DESKTOP_PAD - 12}px) 0`,
        `calc(100% - 8px) 0`,
        `100% 8px`,
        `100% calc(100% - 8px)`,
        `calc(100% - 8px) 100%`,
        `calc(100% - ${ID_DESKTOP_PAD - 12}px) 100%`,
        `calc(100% - ${ID_DESKTOP_PAD}px) calc(100% - ${12}px)`,
        `calc(100% - ${ID_DESKTOP_PAD + 12}px) 100%`,
        `8px 100%`,
        `0 calc(100% - 8px)`,
    ].join(',')})`,
};
const ID_MOBILE_PAD = 90;
const TICKET_MOBILE_STYLE: React.CSSProperties = {
    ...TICKET_COMMON_STYLE,
    paddingBottom: ID_MOBILE_PAD + 16,
    clipPath: `polygon(${[
        `0 8px`,
        `8px 0`,
        `calc(100% - 8px) 0`,
        `100% 8px`,
        `100% calc(100% - ${ID_MOBILE_PAD + 12}px)`,
        `calc(100% - ${12}px) calc(100% - ${ID_MOBILE_PAD}px)`,
        `100% calc(100% - ${ID_MOBILE_PAD - 12}px)`,
        `100% calc(100% - 8px)`,
        `calc(100% - 8px) 100%`,
        `8px 100%`,
        `0 calc(100% - 8px)`,
        `0 calc(100% - ${ID_MOBILE_PAD - 12}px)`,
        `${12}px calc(100% - ${ID_MOBILE_PAD}px)`,
        `0 calc(100% - ${ID_MOBILE_PAD + 12}px)`,
    ].join(',')})`,
};

const STRIP_DESKTOP_STYLE: React.CSSProperties = {
    top: 16,
    right: 0,
    width: ID_DESKTOP_PAD + 0.75,
    height: 'calc(100% - 32px)',
    backgroundImage:
        'linear-gradient(rgb(255, 255, 255, 0) 30%, rgb(255, 255, 255) 30%, rgb(255, 255, 255) 60%, rgba(255, 255, 255, 0) 60%)',
    backgroundPosition: 'left',
    backgroundSize: '1.5px 16px',
    backgroundRepeat: 'repeat-y',
};
const STRIP_MOBILE_STYLE: React.CSSProperties = {
    bottom: 0,
    left: 16,
    width: 'calc(100% - 32px)',
    height: ID_MOBILE_PAD + 0.75,
    backgroundImage:
        'linear-gradient(to left, rgb(255, 255, 255, 0) 30%, rgb(255, 255, 255) 30%, rgb(255, 255, 255) 60%, rgba(255, 255, 255, 0) 60%)',
    backgroundPosition: 'top',
    backgroundSize: '16px 1.5px',
    backgroundRepeat: 'repeat-x',
};

const GLOSS_STYLE: React.CSSProperties = {
    background: 'radial-gradient(circle, rgba(255, 255, 255, 0.13) 0%, rgba(0,212,255,0) 70%)',
};
