import React from 'react';
import { CompetitionList } from '../../competitions/-page/CompetitionList';
import { useAsync } from 'react-use';
import { CompetitionApi } from '../../../api/competition';
import { StyledSection } from 'layout/StyledSection';
import { SectionContainer } from 'layout/SectionContainer';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';

export const AdminCompetitionsExploreUrlPage: React.FC = () => {
    const {
        loading,
        error,
        value: page,
    } = useAsync(async () => CompetitionApi.getAdminExploreUrl(), []);

    const render = () => {
        if (loading || error || !page) {
            return <PlaceholderBox loading={loading} error={error} noData={!page} />;
        }
        return <CompetitionList page={page} />;
    };

    return (
        <SectionContainer>
            <StyledSection title="My Completed Competitions">{render()}</StyledSection>
        </SectionContainer>
    );
};
