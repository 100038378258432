import { restAxios } from '../restAxios';
import { conformResponse } from '../helper';
import {
    ResponsePaginatedCompetitionDto,
    ResponseCompetitionDto,
    ResponseCompetitionAdvancedInfoDto,
} from './dto';
import { CompetitionAccessType } from '../../types';

export class CompetitionApi {
    static async getPublicExplore() {
        return conformResponse(
            restAxios.get('mixed/competitions/public-explore'),
            ResponsePaginatedCompetitionDto,
        );
    }

    static async getAdminExploreUrl() {
        return conformResponse(
            restAxios.get('private/competitions/admin-explore-url'),
            ResponsePaginatedCompetitionDto,
        );
    }

    static async getMyActive() {
        return conformResponse(
            restAxios.get('private/competitions/my-active'),
            ResponsePaginatedCompetitionDto,
        );
    }

    static async getMyCompleted() {
        return conformResponse(
            restAxios.get('private/competitions/my-completed'),
            ResponsePaginatedCompetitionDto,
        );
    }

    static async getById(id: string) {
        return conformResponse(
            restAxios.get(`mixed/competitions/${id}`),
            ResponseCompetitionAdvancedInfoDto,
        );
    }

    static async create(body: {
        title: string;
        description: string;
        sourceRepoId: number;
        accessType: CompetitionAccessType;
        priceCoin: number;
        maxParticipants: number;
        startDate?: string;
        expireDate?: string;
    }) {
        return conformResponse(
            restAxios.post('private/competitions', body),
            ResponseCompetitionDto,
        );
    }

    static async joinById(id: string) {
        return conformResponse(
            restAxios.post(`private/competitions/${id}/join`),
            ResponseCompetitionAdvancedInfoDto,
        );
    }
}
