import { format } from 'date-fns';
import { RELEASE_DATE, RELEASE_VERSION } from 'dev/release.constant';
import { useIsMobile } from 'hooks/useIsMobile.hook';
import { useEffect, useState } from 'react';
import { clsxm } from 'utils/clsxm';

export const PullToRefresh: React.FC = () => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [loading, setLoading] = useState(false);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (!ref || loading) {
            return;
        }

        const decelerationFactor = 0.5;
        let dragStartPoint = createTouchCoordinates(0, 0);

        const isDraggingForPullToRefresh = (yMovement: number) =>
            window.scrollY <= 0 && yMovement <= 0;

        let pullToRefreshElementHeight = ref.offsetHeight;
        const dragUpdate = (dragMovement: number | null, opacity: number) => {
            if (dragMovement !== null) {
                const top = Math.min(dragMovement, 0);
                ref.style.top = `${top}px`;
                if (top === 0) {
                    setLoading(true);
                    window.location.reload();
                }
            }
            ref.style.visibility = opacity === 0 ? 'hidden' : 'visible';
            ref.style.opacity = `${opacity}`;
            if (!opacity) {
                visible = false;
            }
        };

        const startHandler = (event: TouchEvent) => {
            pullToRefreshElementHeight = ref.offsetHeight;
            dragStartPoint = getTouchesCoordinatesFrom(event);
        };

        const moveHandler = (event: TouchEvent) => {
            const dragCurrentPoint = getTouchesCoordinatesFrom(event);
            const yMovement = (dragStartPoint.y - dragCurrentPoint.y) * decelerationFactor;
            const yAbsoluteMovement = Math.abs(yMovement);

            if (isDraggingForPullToRefresh(yMovement)) {
                event.preventDefault();
                event.stopPropagation();

                dragUpdate(
                    yAbsoluteMovement - pullToRefreshElementHeight,
                    yAbsoluteMovement / (pullToRefreshElementHeight * 0.5),
                );
            }
        };

        const endHandler = () => {
            if (!loading) {
                ref.style.visibility = 'hidden';
            }
        };

        let isMouseDown = false;
        let clickY = 0;
        // TODO better name
        const SIZE = 40;
        let visible = false;
        const mouseHandler = (event: MouseEvent) => {
            if (isMobile) {
                return;
            }
            const { clientY } = event;
            if (clientY < SIZE || isMouseDown) {
                visible = true;
                const shift = isMouseDown ? SIZE - clickY + (clientY - clickY) : SIZE - clientY;
                dragUpdate(-pullToRefreshElementHeight + shift, 1);
            } else {
                if (visible) {
                    dragUpdate(-1, 0);
                }
            }
        };

        const mouseDownHandler = (event: MouseEvent) => {
            if (isMobile) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            isMouseDown = true;
            clickY = event.clientY;
            mouseHandler(event);
        };

        const mouseUpHandler = (event: MouseEvent) => {
            if (isMobile) {
                return;
            }
            isMouseDown = false;
            mouseHandler(event);
        };

        let animate = false;
        const runFadeAnimationLoop = (opacity: number) => {
            if (!animate) {
                return;
            }
            const normalOpacity = Math.max(opacity - 0.05, 0);
            dragUpdate(null, normalOpacity);
            if (opacity > 0) {
                requestAnimationFrame(() => runFadeAnimationLoop(normalOpacity));
            } else {
                animate = false;
            }
        };

        const mouseLeaveHandler = (event: MouseEvent) => {
            if (isMouseDown) {
                return;
            }
            // mouse out of window
            if (event.clientY <= 0) {
                if (animate) {
                    // already animating
                    return;
                }
                if (visible) {
                    animate = true;
                    runFadeAnimationLoop(1);
                }
            } else {
                animate = false;
            }
        };

        document.addEventListener('touchmove', moveHandler, { passive: false });
        ref.addEventListener('mousedown', mouseDownHandler, { passive: false });

        document.addEventListener('touchstart', startHandler, { passive: true });
        document.addEventListener('touchend', endHandler, { passive: true });
        document.addEventListener('mousemove', mouseHandler, { passive: true });
        document.addEventListener('mouseup', mouseUpHandler, { passive: true });
        document.addEventListener('mouseleave', mouseLeaveHandler, { passive: true });

        return () => {
            document.removeEventListener('touchstart', startHandler);
            document.removeEventListener('touchmove', moveHandler);
            document.removeEventListener('touchend', endHandler);
            document.removeEventListener('mousemove', mouseHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
            document.removeEventListener('mouseleave', mouseLeaveHandler);
            ref.removeEventListener('mousedown', mouseDownHandler);
            animate = false;
        };
    }, [ref, loading, isMobile]);

    return (
        <div
            className={clsxm(
                'fixed -top-96 z-50 flex w-full flex-col justify-center pb-8 pt-12',
                'invisible cursor-ns-resize bg-gradient-to-b text-center',
                !loading && 'from-slate-400 to-white',
                loading && 'from-slate-800 to-sky-400 text-sky-100',
            )}
            ref={setRef}
            style={STYLE}>
            <div className={clsxm('text-xs', loading ? 'text-sky-300' : 'text-slate-500')}>
                release {format(new Date(RELEASE_DATE), 'ddMMMy').toLowerCase()}
            </div>
            <div className="font-bold">{loading ? 'Refreshing...' : 'Pull To Refresh'}</div>
            <div className={clsxm('text-xs', loading ? 'text-sky-300' : 'text-slate-400')}>
                release {RELEASE_VERSION}
            </div>
        </div>
    );
};

const STYLE: React.CSSProperties = {
    clipPath: `polygon(${[
        `calc(50% - 80px) 0`,
        `50% 32px`,
        `calc(50% + 80px) 0`,
        `calc(50% + 80px) calc(100% - 32px)`,
        `50% 100%`,
        `calc(50% - 80px) calc(100% - 32px)`,
        `calc(50% - 80px) 0%`,
    ].join(', ')})`,
};

const getTouchesCoordinatesFrom = (event: TouchEvent) => {
    const first = event.targetTouches[0];
    if (!first) {
        return createTouchCoordinates(0, 0);
    }
    return createTouchCoordinates(first.screenX, first.screenY);
};

const createTouchCoordinates = (x: number, y: number) => ({ x, y });
