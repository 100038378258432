import { EnumButtonGroup } from 'components/form/EnumButtonGroup';
import { SectionContainer } from 'layout/SectionContainer';
import { useState } from 'react';
import { UISectionAnimations } from './sections/UISectionAnimations';
import { UISectionBranding } from './sections/UISectionBranding';
import { UISectionColors } from './sections/UISectionColors';
import { UISectionFeatured } from './sections/UISectionFeatured';
import { UISectionFormatting } from './sections/UISectionFormatting';
import { UISectionForms } from './sections/UISectionForms';
import { UISectionIcons } from './sections/UISectionIcons';
import { UISectionLayout } from './sections/UISectionLayout';
import { UISectionPlaceholder } from './sections/UISectionPlaceholder';

export const UIKitPage: React.FC = () => {
    const [tab, setTab] = useState(DEFAULT_SECTION);

    return (
        <SectionContainer>
            <div className="flex justify-center">
                <EnumButtonGroup options={TABS} value={tab} onChange={setTab} />
            </div>
            {SECTIONS.find(e => e.title === tab)?.jsx}
        </SectionContainer>
    );
};

const SECTIONS: {
    title: string;
    jsx: React.ReactNode;
}[] = [
    { title: 'Branding', jsx: <UISectionBranding /> },
    { title: 'Colors', jsx: <UISectionColors /> },
    { title: 'Layout', jsx: <UISectionLayout /> },
    { title: 'Formatting', jsx: <UISectionFormatting /> },
    { title: 'Animations', jsx: <UISectionAnimations /> },
    { title: 'Forms', jsx: <UISectionForms /> },
    { title: 'Placeholders', jsx: <UISectionPlaceholder /> },
    { title: 'Icons', jsx: <UISectionIcons /> },
    { title: 'Featured', jsx: <UISectionFeatured /> },
];

const DEFAULT_SECTION = SECTIONS[0]?.title || '';
const TABS = SECTIONS.map(e => e.title);
