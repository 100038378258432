import { zodClass } from 'utils/zod.utils';
import { z } from 'zod';
import { UserPermission } from '../../types';

export class ResponseUserZod extends zodClass({
    id: z.string(),
    githubId: z.number(),
    createdDate: z.string(),
    permissions: z.nativeEnum(UserPermission),
    github: z.object({
        githubId: z.number(),
        login: z.string(),
        avatarUrl: z.string(),
        url: z.string(),
        type: z.string(),
        name: z.string(),
        blog: z.string(),
        email: z.string().nullish(),
        hireable: z.boolean().nullish(),
        publicRepos: z.number(),
        publicGists: z.number(),
        twoFactorAuthentication: z.boolean(),
        onGithubCreatedDate: z.string(),
        createdDate: z.string(),
    }),
}) {}
