import { getSessionToken } from '../utils';
import { restAxios } from './restAxios';
import { rootStore } from 'store';
import { AuthThunk } from '../store/auth/thunk';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

restAxios.interceptors.request.use(req => {
    if (req.headers) {
        req.headers['authorization'] = getSessionToken();
    }
    return req;
});

restAxios.interceptors.response.use(
    r => r,
    err => {
        if (err.response) {
            switch (err.response.status) {
                case 401:
                    // TODO fix linter
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    rootStore.dispatch(AuthThunk.signOut());
                    break;
                // TODO handle auto sign out
                case 403:
                default:
                    if (err instanceof AxiosError) {
                        toast.error(err.message);
                    } else {
                        // TODO clarify messages for more cases
                        toast.error('Something went wrong');
                    }
            }
        }
        console.log('catch', err);
        throw err;
    },
);
