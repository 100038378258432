import styles from './spinner.module.scss';

export const StyledLoader: React.FC = () => {
    return (
        <svg id="triangle" className={styles.svg} width={160} height={160} viewBox={VIEWBOX}>
            <defs>
                <radialGradient id={ID_FILL_GRADIENT}>
                    <stop offset="0%" stopColor="white" />
                    <stop offset="70%" stopColor="#8dbfe1" />
                </radialGradient>
            </defs>
            <polygon
                fill={`url('#${ID_FILL_GRADIENT}')`}
                stroke="#055699"
                pathLength={100}
                strokeWidth={2}
                strokeLinecap="square"
                points={POINTS}
            />
        </svg>
    );
};
const ID_FILL_GRADIENT = `fill_gradient_${Math.random()}`;

const CONVEX = 0.75;
const generateShapePoints = (count = 3, radius = 15, shift = 20) => {
    const points: string[] = [];
    for (let i = 0; i < count; i++) {
        const k = i % 2 === 0 ? 1 : CONVEX;
        const x = Math.cos((i / count) * Math.PI * 2) * radius * k + shift;
        const y = Math.sin((i / count) * Math.PI * 2) * radius * k + shift;
        points.push(`${x},${y}`);
    }
    return points.join(' ');
};

const scale = 80;
const VIEWBOX = `0 0 ${scale} ${scale}`;
// provide even count of vertices
const POINTS = generateShapePoints(12, scale * 0.45, scale * 0.5);
