import { AuthorizedSocket } from './types';

class SocketSingleton {
    private token = '';
    private socket?: AuthorizedSocket;
    private subs: object[] = [];

    getSocket(token: string, init: () => SocketIOClient.Socket) {
        if (!token) {
            this.token = '';
            this.socket = undefined;
            return;
        }
        if (this.token !== token) {
            if (this.socket) {
                this.socket.close();
            }
            this.token = token;
            this.socket = init() as AuthorizedSocket;
            this.socket.token = token;
        }
        if (!this.socket) {
            this.socket = init() as AuthorizedSocket;
            this.socket.token = token;
        }
        return this.socket;
    }

    mount() {
        const instance = {};
        this.subs.push(instance);
        return () => {
            this.subs = this.subs.filter(e => e !== instance);
            if (this.subs.length < 1 && this.socket) {
                this.socket.close();
                this.socket = undefined;
            }
        };
    }
}

export const socketSingleton = new SocketSingleton();

// TODO only for dev purposes
(window as any).single = socketSingleton;
