import { useId } from 'hooks/useId.hook';
import React from 'react';
import { clsxm } from 'utils/clsxm';

export const StyledInput = React.forwardRef<
    HTMLInputElement,
    React.InputHTMLAttributes<HTMLInputElement> & {
        label: string;
        className?: string;
        rootClassName?: string;
        errorMessage?: string;
        placeholderOnZero?: boolean;
    }
>(({ label, className, rootClassName, errorMessage, placeholderOnZero, value, ...props }, ref) => {
    const id = useId();
    let placeholder = props.placeholder;
    if (placeholderOnZero && (!value || value === '0')) {
        placeholder = props.placeholder || '0';
        value = '';
    }
    return (
        <div className={clsxm('relative pt-6', rootClassName)}>
            <label
                htmlFor={id}
                className={clsxm(
                    'absolute top-0 select-none text-sm text-gray-500',
                    errorMessage && 'text-red-500',
                )}>
                {label}
            </label>
            <input
                id={id}
                className={clsxm(
                    'p-1 px-2 outline-none ring-1 ring-gray-400 transition',
                    'focus:ring-sky-600',
                    'disabled:bg-gray-300',
                    'read-only:bg-gray-300',
                    'w-[200px]',
                    errorMessage && 'ring-red-500',
                    className,
                )}
                ref={ref}
                {...props}
                placeholder={placeholder}
                value={value}
            />
            {errorMessage && <div className="p-2 text-sm text-red-600">{errorMessage}</div>}
        </div>
    );
});
