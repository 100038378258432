import { z } from 'zod';

const MIN_AGE_TO_USE_APP = 14;
const THIS_YEAR = new Date().getFullYear();
const LATEST_BIRTHDATE_YEAR = THIS_YEAR - MIN_AGE_TO_USE_APP;
const MIN_BIRTHDATE = new Date('2 Jan 1950').toJSON().split('T')[0];
const MAX_BIRTHDATE = (() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - MIN_AGE_TO_USE_APP);
    return date.toJSON().split('T')[0];
})();
// restriction to calculate work experience
const MAX_FIRST_PAID_DATE = new Date().toJSON().split('-').slice(0, 2).join('-');

export const NATIVE_FORM_CONSTANTS = { MIN_BIRTHDATE, MAX_BIRTHDATE, MAX_FIRST_PAID_DATE };

const refineBirthdate = (s: string) => {
    const date = new Date(s);
    if (typeof s !== 'string' || isNaN(+date)) {
        return false;
    }
    const y = date.getFullYear();
    return y > 1950 && y <= LATEST_BIRTHDATE_YEAR;
};
export const ZodRefines = {
    isValidDateStringOptional: {
        refine: (s?: string | null) => !s || (typeof s === 'string' && !isNaN(+new Date(s))),
        refineBirthdate: (s?: string | null) => !s || refineBirthdate(s),
        message: 'Invalid date',
    },
    isValidDateString: {
        refineBirthdate,
        refine: (s: string) => typeof s === 'string' && !isNaN(+new Date(s)),
        message: 'Invalid date',
    },
};

/**
 * convention for schema class name - suffix `Zod`
 */
export const zodClass = <T extends z.ZodRawShape>(
    preZodObj: T,
): z.ZodObject<T> & (new (data: unknown) => z.infer<z.ZodObject<T>>) => {
    const zodObj = z.object(preZodObj);
    const anon = class {
        constructor(data: unknown) {
            const valid = zodObj.parse(data);
            Object.assign(this, valid);
        }
    } as any;
    Object.assign(anon, zodObj);
    anon.__proto__ = (zodObj as any).__proto__;
    return anon;
};
