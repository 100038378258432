import { StyledSection } from 'layout/StyledSection';

export const UISectionLayout: React.FC = () => {
    return (
        <StyledSection title="Layout">
            <div>
                Main gap: <b>gap-2</b>
            </div>
            <div>
                Main padding: <b>p-2</b>
            </div>
            <div>
                Reusable components should <b>not have margins</b>. Margins allowed only inside
                components. Spacing between components should be handled via gap classes of grids
                and flex.
            </div>
        </StyledSection>
    );
};
