import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AuthThunk } from '../store/auth/thunk';
import { clsxm } from '../utils/clsxm';
import { calcChamferedCorners } from 'styles/style.util';

const style = calcChamferedCorners([1, 0, 1, 0]);

export const SignOutButton: React.FC<{
    className?: string;
}> = ({ className }) => {
    const dispatch = useDispatch();
    const signOut = useCallback(() => {
        dispatch(AuthThunk.signOut());
        window.scrollTo(0, 0);
        window.location.reload();
    }, [dispatch]);

    return (
        <button
            style={style}
            onClick={signOut}
            className={clsxm(
                'bg-zinc-800 p-2 capitalize text-white transition',
                'hover:bg-zinc-700',
                'active:bg-zinc-900',
                'disabled:bg-gray-500 disabled:text-gray-300',
                className,
            )}>
            sign out
        </button>
    );
};
