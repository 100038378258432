import 'react-toastify/dist/ReactToastify.css';
import './api/interceptors';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { IS_DEV_MODE, IS_PROD_MODE, typedEnv } from './typed-env';
import { z } from 'zod';

if (IS_PROD_MODE) {
    Sentry.init({
        dsn: 'https://eae933c28435440e817cc6c8edb3f396@o496125.ingest.sentry.io/5569975',
        environment: `FE-${typedEnv.NODE_ENV}`,
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

if (IS_DEV_MODE) {
    // expose some usefull references
    (window as any).z = z;
}
