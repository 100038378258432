import { StyledButton } from 'components/form/StyledButton';
import { StyledSwitch } from 'components/form/StyledSwitch';
import { StyledInput } from 'components/styled-input/StyledInput';
import { StyledSection } from 'layout/StyledSection';
import { useState } from 'react';

export const UISectionForms: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    return (
        <div>
            <StyledSection title="Inputs">
                <div className="flex gap-4">
                    <StyledInput label="base" />
                    <StyledInput label="disabled" disabled value="some value" />
                    <StyledInput label="readonly" readOnly value="some value" />
                    <StyledInput label="with error" errorMessage="Required field" />
                </div>
            </StyledSection>
            <StyledSection title="Buttons">
                <div className="flex gap-4">
                    <StyledButton>Base State</StyledButton>
                    <StyledButton secondary>Secondary Role</StyledButton>
                    <StyledButton secondary disabled>
                        Secondary Disabled
                    </StyledButton>
                    <StyledButton
                        loading={loading}
                        onClick={() => {
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(false);
                            }, 2e3);
                        }}>
                        Loading On Click
                    </StyledButton>
                    <StyledButton disabled>Disabled</StyledButton>
                    <StyledButton requireAuth>Require Auth</StyledButton>
                </div>
            </StyledSection>
            <StyledSection title="Toggles">
                <StyledSwitch checked={checked} onChange={setChecked}>
                    Dark Mode
                </StyledSwitch>
            </StyledSection>
        </div>
    );
};
