import { useMemo } from 'react';
import { useAsync } from 'react-use';
import { getCachedRates } from '../utils/currency.util';

export const useUsdToAuhConversion = (usd: number) => {
    const { value: rateUsdUah } = useAsync(async () => {
        const res = await getCachedRates();
        return res.rates.USD;
    }, [usd]);

    return useMemo(() => {
        if (!rateUsdUah) {
            return null;
        }
        const uah = rateUsdUah * usd;
        return +uah.toFixed(1);
    }, [rateUsdUah, usd]);
};
