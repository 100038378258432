import { StyledSection } from 'layout/StyledSection';

export const UISectionColors: React.FC = () => {
    return (
        <StyledSection title="Colors">
            <div className="bg-sky-800 text-white">
                Main color: <b>bg-sky-800</b>
            </div>
            <div className="bg-sky-700 text-white">
                Hover color tone: <b>bg-sky-700</b>
            </div>
            <div className="bg-sky-900 text-white">
                Active color tone: <b>bg-sky-900</b>
            </div>
            <div className="bg-gray-500 text-gray-300">
                Disabled: <b>bg-gray-500 text-gray-300</b>
            </div>
            <div className="text-gray-300">
                Hint: <b>text-gray-300</b>
            </div>
        </StyledSection>
    );
};
