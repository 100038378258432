import React from 'react';
import style from './index.module.scss';
import icon1 from '../icons/step-1.svg';
import icon2 from '../icons/step-2.svg';
import icon3 from '../icons/step-3.svg';
import icon4 from '../icons/step-4.svg';
import icon5 from '../icons/step-5.svg';
import { clsxm } from 'utils/clsxm';

const getStepIcon = (n: number) => {
    switch (n) {
        case 1:
            return icon1;
        case 2:
            return icon2;
        case 3:
            return icon3;
        case 4:
            return icon4;
        default:
            return icon5;
    }
};

const getActiveDotStyle = (current: number, position: number) => {
    if (current !== position) {
        return style.dot;
    }
    switch (current) {
        case 1:
            return `${style.activeDot} ${style.active1}`;
        case 2:
            return `${style.activeDot} ${style.active2}`;
        case 3:
            return `${style.activeDot} ${style.active3}`;
        case 4:
            return `${style.activeDot} ${style.active4}`;
        default:
            return `${style.activeDot} ${style.active5}`;
    }
};

export const LandingStep: React.FC<{
    number: number;
    title: string;
}> = ({ number, title, children }) => {
    const isFinalStep = number === 5;

    return (
        <div className={style.wrap}>
            <div className={clsxm(style.mainPart, isFinalStep && style.finalStep)}>
                <div className={style.stepWrap}>
                    <img className={style.icon} src={getStepIcon(number)} alt="" />
                    Step {number}
                </div>
                <div className={style.contentWrap}>
                    <div className={style.title}>{title}</div>
                    <div className={style.description}>{children}</div>
                </div>
            </div>
            <div className={style.progressWrap}>
                <div className={getActiveDotStyle(number, 1)}></div>
                <div className={getActiveDotStyle(number, 2)}></div>
                <div className={getActiveDotStyle(number, 3)}></div>
                <div className={getActiveDotStyle(number, 4)}></div>
                <div className={getActiveDotStyle(number, 5)}></div>
            </div>
        </div>
    );
};
