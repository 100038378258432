import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import TargerMarkerSrc from './marker-images/targer-marker.png';
import MoneyMarkerSrc from './marker-images/money-marker.png';

/*
For private TileLayer 50K views/mo
https://www.jawg.io/lab/access-tokens

email and pass: sotibaw856@iucake.com

acces token: oeboUeGxg43sz13mcMH3qd7PCnGi3NI0Gnh4HyizSqnrh1sqyp3jp29ghqKQ5NzR

*/
export const TargeriansMapSection: React.FC = () => {
    return (
        <div>
            <MapContainer
                center={CENTER_COORDINATES}
                zoom={6}
                scrollWheelZoom={false}
                className="h-[640px] max-h-[60svh]">
                <TileLayer
                    url="https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token={accessToken}"
                    accessToken="oeboUeGxg43sz13mcMH3qd7PCnGi3NI0Gnh4HyizSqnrh1sqyp3jp29ghqKQ5NzR"
                />
                {/* <TileLayer url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png" /> */}
                {/* <TileLayer url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-background/{z}/{x}/{y}{r}.png" /> */}
                {/* <TileLayer url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-labels/{z}/{x}/{y}{r}.png" /> */}
                <Marker position={TOP_SALARY_COORDINATES} icon={topMoneyIcon}>
                    <Popup>Top salary in Ukraine</Popup>
                </Marker>
                {LOCATIONS.map((e, i) => (
                    <Marker key={i} position={[e.lat, e.lng]} icon={targerIcon}>
                        <Popup>{e.formatted_address}</Popup>
                    </Marker>
                ))}
            </MapContainer>
            <div className="flex flex-col gap-4 p-2">
                <div>
                    <div className="pb-4 text-lg font-bold">Top salary city</div>
                    <div className="flex gap-4">
                        <img src={MoneyMarkerSrc} alt="money icon" className="h-8 w-8" />
                        <div>Lviv</div>
                    </div>
                </div>
                <div>
                    <div className="pb-4 text-lg font-bold">Where targerians located</div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {LOCATIONS.map((e, i) => (
                            <div key={i} className="flex gap-4">
                                <img
                                    src={TargerMarkerSrc}
                                    alt="targer logo icon"
                                    className="h-8 w-8"
                                />
                                <div>{e.formatted_address.split(',').shift()}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const CENTER_COORDINATES: [number, number] = [48.718239, 31.746856];

// Lviv oblast
const TOP_SALARY_COORDINATES: [number, number] = [49.339683, 24.029717];

const targerIcon = L.icon({
    iconUrl: TargerMarkerSrc,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
});

const topMoneyIcon = L.icon({
    iconUrl: MoneyMarkerSrc,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
});

const LOCATIONS: {
    placeId: string;
    formatted_address: string;
    lat: number;
    lng: number;
}[] = [
    {
        placeId: 'ChIJBUVa4U7P1EAR_kYBF9IxSXY',
        formatted_address: 'Kyiv, Ukraine, 02000',
        lat: 50.4501,
        lng: 30.5234,
    },
    {
        placeId: 'ChIJV5oQCXzdOkcR4ngjARfFI0I',
        formatted_address: 'Lviv, Lviv Oblast, Ukraine, 79000',
        lat: 49.839683,
        lng: 24.029717,
    },
    {
        placeId: 'ChIJ_b4d3_wHMEcRFmgAIU5y12g',
        formatted_address: 'Zolochiv, Lviv Oblast, Ukraine, 80700',
        lat: 49.80941,
        lng: 24.901371,
    },
    {
        placeId: 'ChIJZxtECCdD0EAR2OigSUHFKOg',
        formatted_address: 'Kropyvnytskyi, Kirovohrad Oblast, Ukraine, 25000',
        lat: 48.50793300000001,
        lng: 32.262317,
    },
    {
        placeId: 'ChIJdc6CS602MEcR6FSx7UekhMQ',
        formatted_address: 'Ternopil, Ternopil Oblast, Ukraine, 46002',
        lat: 49.553517,
        lng: 25.594767,
    },
    {
        placeId: 'ChIJixe7REMGMkcRZMnZJDsL89k',
        formatted_address: 'Khmelnytskyi, Khmelnytskyi Oblast, Ukraine, 29000',
        lat: 49.422983,
        lng: 26.987133,
    },
    {
        placeId: 'ChIJf5dkYIZL0UAR2LXLqSPn3Pg',
        formatted_address: 'Cherkasy, Cherkasy Oblast, Ukraine, 18000',
        lat: 49.444433,
        lng: 32.059767,
    },
    {
        placeId: 'ChIJa7rmJeQl2EARgsrkud9rPs8',
        formatted_address: 'Poltava, Poltava Oblast, Ukraine, 36000',
        lat: 49.58826699999999,
        lng: 34.551417,
    },
];
