import { clsxm } from 'utils/clsxm';

export const EnumButtonGroup: <T extends string | number>(props: {
    options: T[];
    value: T;
    onChange: (selected: T) => void;
}) => React.ReactElement = ({ options, value, onChange }) => {
    return (
        <div className="inline-flex cursor-pointer flex-wrap divide-x border text-center">
            {options.map(e => {
                return (
                    <div
                        key={e}
                        aria-hidden
                        onClick={() => onChange(e)}
                        className={clsxm(
                            'min-w-[80px] select-none p-2',
                            'hover:shadow-inner',
                            value === e && 'bg-slate-200 shadow-inner',
                        )}>
                        {e}
                    </div>
                );
            })}
        </div>
    );
};
