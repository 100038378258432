import { ResponseCompetitionDto } from 'api/competition/dto';
import { StyledButton } from 'components/form/StyledButton';
import { TriangleBadge } from 'components/triangle-bage/TriangleBadge';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { calcChamferedCorners } from 'styles/style.util';
import { clsxm } from 'utils/clsxm';
import { ChallengeTitle } from './ChallengeTitle';
import { calcStatusAndLocationDetails } from './competition.util';
import colors from 'tailwindcss/colors';
import { useIsMobile } from 'hooks/useIsMobile.hook';
import { useTimeDistance } from 'hooks/time.hook';
import { GoGlobe } from 'react-icons/go';

export const ChallengeTile: React.FC<{
    competition: ResponseCompetitionDto;
}> = ({ competition }) => {
    const {
        text: textStatus,
        renderWhereDetails,
        renderWhenDetails,
        targetDate,
        status,
    } = calcStatusAndLocationDetails({
        startDate: competition.startDate,
        endDate: competition.expireDate,
    });
    const { days, hours, minutes, seconds } = useTimeDistance({
        toDate: targetDate,
        stopOnPast: false,
    });
    const isMobile = useIsMobile();

    // TODO refactor to reusable util
    const renderTime = () => {
        let time = '';
        if (Math.abs(days) > 9) {
            time = `${Math.abs(days)}d`;
        } else if (Math.abs(days) > 0) {
            time = `${Math.abs(days)}d ${Math.abs(hours)}h`;
        } else if (Math.abs(hours) > 0) {
            time = `${Math.abs(hours)}h ${Math.abs(minutes)}m`;
        } else {
            time = `${Math.abs(minutes)}m ${Math.abs(seconds)}s`;
        }
        if (status === 'future') {
            return (
                <>
                    {textStatus} <div className="text-slate-500">{time}</div>
                </>
            );
        }
        if (status === 'ending') {
            return (
                <>
                    {textStatus}{' '}
                    <div className="text-slate-500">
                        {time}
                        <div className="inline-block pl-1 text-xs text-slate-400">left</div>
                    </div>
                </>
            );
        }
        if (status === 'past') {
            return (
                <>
                    {textStatus}{' '}
                    <div className="text-slate-500">
                        {time}
                        <div className="inline-block pl-1 text-xs text-slate-400">ago</div>
                    </div>
                </>
            );
        }
        return null;
    };

    return (
        <div
            className={clsxm(
                'relative mx-auto inline-flex w-full max-w-2xl flex-col gap-4 p-4 pb-16 shadow-main',
                'md:gap-0 md:p-4',
            )}
            style={tileStyle}>
            <TriangleBadge
                asTicketPrice={competition.priceCoin}
                wrapClassName={clsxm(
                    'md:h-32 md:w-32',
                    isMobile && 'absolute left-0 bottom-0 w-full',
                )}
                // TODO handle different types
                asEventType="JavaScript"
                small
            />
            <div className="flex flex-col justify-between gap-8 md:flex-row md:gap-16 md:pr-24">
                <DateTile date={competition.startDate} />
                <div className="flex flex-1 flex-col justify-start">
                    <ChallengeTitle title={competition.title} />
                    <div className="flex flex-col gap-4 p-4 md:flex-row md:gap-8 md:pt-12 md:pb-0">
                        <div className="flex flex-col items-center gap-2">
                            <GoGlobe size={40} />
                            {renderWhereDetails()}
                        </div>
                        <div>{renderWhenDetails()}</div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div>{renderTime()}</div>
                <StyledButton linkTo={`/competitions/${competition.id}`}>view</StyledButton>
            </div>
        </div>
    );
};

const tileStyle: React.CSSProperties = {
    borderWidth: 4,
    borderImageSource: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(
        `<svg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <linearGradient id='g' x1='0%' y1='0%' x2='90%' y2='100%'>
            <stop offset='0%' stop-color='${colors.amber[800]}' />
            <stop offset='25%' stop-color='${colors.amber[500]}' />
            <stop offset='50%' stop-color='${colors.amber[200]}' />
            <stop offset='100%' stop-color='${colors.amber[600]}' />
        </linearGradient>
        <path d='${[
            'M 21.5 1.5',
            'L 98.5 1.5',
            'L 98.5 98.5',
            'L 1.5 98.5',
            'L 1.5 20.5',
            'L 20.5 1.5',
        ].join(
            ' ',
        )}' stroke-linecap='square' stroke='url(#g)' stroke-width='3' stroke-dasharray='388'/>
        </svg>`,
    )}")`,
    borderImageSlice: 24,
    borderImageWidth: 8,
    borderImageOutset: 0,
    borderImageRepeat: 'stretch',
};

const DateTile: React.FC<{
    date: Date | string;
}> = ({ date }) => {
    const memoDate = useMemo(() => new Date(date), [date]);
    return (
        <div
            className={clsxm(
                '-z-10 inline-flex items-center justify-center gap-2 bg-gradient-to-br from-sky-900 to-sky-600 p-2 px-4 text-center text-white',
                'md:flex-col md:gap-0 md:self-start',
            )}
            style={dateStyle}>
            <div className="text-xl font-bold">{format(memoDate, 'dd')}</div>
            <div className="text-lg font-bold">{format(memoDate, 'MMM')}</div>
            <div className="text-sm">{format(memoDate, 'yyyy')}</div>
        </div>
    );
};

const dateStyle = calcChamferedCorners([3, 0, 0, 0]);
