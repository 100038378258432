import React from 'react';
import { ResponsePaginatedCompetitionDto } from '../../../api/competition/dto';
import { ChallengeTile } from 'domain/competition/ChallengeTile';
import { PlaceholderBox } from 'components/ux/PlaceholderBox';
import { AnimatedItem } from 'components/ux/AnimatedItem';

export const CompetitionList: React.FC<{
    page: ResponsePaginatedCompetitionDto;
}> = ({ page }) => {
    const renderTotal = () => {
        if (!page.total) {
            return <PlaceholderBox noData />;
        }
        if (page.total < 5) {
            return;
        }
        return <div className="text-slate-400">total: {page.total}</div>;
    };

    return (
        <div className="flex flex-col items-center justify-center gap-8">
            {page.items.map((e, i) => {
                return (
                    <AnimatedItem key={i} className="flex md:w-full" index={i}>
                        <ChallengeTile competition={e} />
                    </AnimatedItem>
                );
            })}
            {renderTotal()}
        </div>
    );
};
