/**
 * @deprecated
 * TODO refactor to const [searchParams, setSearchParams] = useSearchParams();
 */
export const parseQueryString = (query = '') => {
    return query
        .slice(1)
        .split('&')
        .reduce((a, v) => {
            const [key, val] = v.split('=');
            if (!key || !val) {
                return a;
            }
            return {
                ...a,
                [key]: val,
            };
        }, {} as { [key: string]: string });
};
