import { useMemo, useState } from 'react';
import { clsxm } from 'utils/clsxm';

export const Popover: React.FC<{
    content: React.ReactNode;
    className?: string;
    contentClassName?: string;
    triggerClassName?: string;
    width?: string | number;
}> = ({ children, content, className, triggerClassName, contentClassName, width = 200 }) => {
    const style = useMemo(() => ({ width }), [width]);
    const [isHover, setIsHover] = useState(false);

    return (
        <div
            className={clsxm('relative', triggerClassName)}
            role="menuitem"
            tabIndex={0}
            onFocus={() => setIsHover(true)}
            onBlur={() => setIsHover(false)}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}>
            {children}
            <div
                style={style}
                className={clsxm(
                    'pointer-events-none absolute right-0 top-full translate-y-2 justify-center pt-2 opacity-0 transition',
                    'z-10',
                    isHover && 'pointer-events-auto translate-y-0 opacity-100',
                    className,
                )}>
                <div
                    className={clsxm(
                        'flex flex-col gap-2 bg-white p-2 text-center shadow',
                        contentClassName,
                    )}>
                    {content}
                </div>
            </div>
        </div>
    );
};
