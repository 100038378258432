import React, { useState, useEffect, useMemo } from 'react';
import { StyledInput } from '../../../components/styled-input/StyledInput';
import { CompetitionAccessType } from '../../../types';
import { useInput, useAdvancedInput, useIntInput } from '../../../utils';
import { StyledButton } from 'components/form/StyledButton';
import { CompetitionApi } from '../../../api/competition';
import { useAsyncFn } from 'react-use';
import { AdminGithubApi } from '../../../api/github';
import { createFrientlyRepoTitle } from '../../../utils/string';
import { useUsdToAuhConversion } from '../../../hooks/currency.hook';
import { renderReadableNumber } from '../../../utils/number.util';
import { convertUnit2Coin } from '../../../utils/currency.util';
import { format } from 'date-fns';
import { IS_DEV_MODE } from 'typed-env';
import { EnumButtonGroup } from 'components/form/EnumButtonGroup';
import { SectionContainer } from 'layout/SectionContainer';
import { StyledSection } from 'layout/StyledSection';
import { generatePath } from 'react-router-dom';
import { AppGeneratedRoutes } from 'router/app-generated-routes';
import { CompetitionSectionOverview } from 'pages/competitions/-page/sections/CompetitionSectionOverview';
import { ResponseCompetitionAdvancedInfoDto } from 'api/competition/dto';
import { StyledSwitch } from 'components/form/StyledSwitch';

const isValidDate = (d: any): d is Date => d instanceof Date && !isNaN(d as any);
const toLocalDateStr = (d: Date | undefined, withSeconds = false) => {
    if (isValidDate(d)) {
        if (withSeconds) {
            return format(d, "yyyy-MM-dd'T'HH:mm:ss");
        }
        return format(d, "yyyy-MM-dd'T'HH:mm");
    }
    return '';
};

export const AdminCompetitionCreatePage: React.FC = () => {
    const [title, { setValue: setTitle }] = useAdvancedInput('', s => createFrientlyRepoTitle(s));
    const description = useInput('');
    const maxParticipants = useIntInput(1);
    const usdPrice = useIntInput(0);
    const [accessType, setAccessType] = useState(
        IS_DEV_MODE ? CompetitionAccessType.PUBLIC : CompetitionAccessType.URL_ACCESS,
    );
    const [sourceRepo, setSourceRepo] = useState({
        id: 0,
        url: '',
    });
    const [repoFilter, { setValue: setRepoFilter }] = useAdvancedInput('targer-cha');
    const [startDate, setStartDate] = useState<Date>();
    const [expireDate, setExpireDate] = useState<Date>();
    const localStartDate = useMemo(() => toLocalDateStr(startDate), [startDate]);
    const localExpireDate = useMemo(() => toLocalDateStr(expireDate), [expireDate]);
    const uahPrice = useUsdToAuhConversion(usdPrice.value) || 0;

    useEffect(() => {
        if (isValidDate(startDate)) {
            // expire in 1 h
            setExpireDate(new Date(startDate.getTime() + 60e3 * 60));
        } else {
            setExpireDate(undefined);
        }
    }, [startDate]);

    // TODO refactor to react-query
    const [createState, createAction] = useAsyncFn(async () => {
        return await CompetitionApi.create({
            title: title.value,
            description: description.value,
            sourceRepoId: sourceRepo.id,
            accessType: accessType,
            priceCoin: convertUnit2Coin(usdPrice.value),
            maxParticipants: maxParticipants.value,
            startDate: startDate ? startDate.toJSON() : undefined,
            expireDate: expireDate ? expireDate.toJSON() : undefined,
        });
    }, [
        title.value,
        description.value,
        maxParticipants.value,
        usdPrice.value,
        sourceRepo.id,
        accessType,
        startDate,
        expireDate,
    ]);

    // TODO refactor to react-query
    const [myRepos, getMyRepos] = useAsyncFn(async () => {
        return await AdminGithubApi.getTargerRepos();
    }, []);

    useEffect(() => {
        getMyRepos();
    }, [getMyRepos]);

    const deleteRepo = async (repoHtmlUrl: string) => {
        await AdminGithubApi.deleteTargerRepo(repoHtmlUrl);
        setTimeout(getMyRepos, 1500);
    };

    const repoListFiltered = myRepos.value
        ? myRepos.value.items.filter(e => e.private && e.full_name.includes(repoFilter.value))
        : [];

    const renderedRepoList = repoListFiltered.map((e, i) => (
        <div key={i} className="flex gap-2 py-1">
            <StyledButton
                onClick={() => setRepoFilter(e.full_name)}
                disabled={repoFilter.value === e.full_name}>
                use
            </StyledButton>
            <StyledButton
                onClick={() => deleteRepo(e.html_url)}
                disabled={!e.full_name.match(/targer-.*-challenge-\w{8}$/)}>
                delete
            </StyledButton>
            <a href={e.html_url} target="_blank" rel="noopener noreferrer">
                {e.full_name}
            </a>
        </div>
    ));

    {
        const repo = repoListFiltered[0] ?? {
            id: 0,
            html_url: '',
        };
        if (sourceRepo.id !== repo.id) {
            setSourceRepo({
                id: repo.id,
                url: repo.html_url,
            });
        }
    }

    const previewCompetition: Pick<
        ResponseCompetitionAdvancedInfoDto,
        'startDate' | 'expireDate' | 'title' | 'description'
    > = {
        startDate: localStartDate,
        expireDate: localExpireDate,
        title: title.value,
        description: description.value,
    };

    const isValidHTML = !description.value || checkValidHTML(`<div>${description.value}</div>`);

    return (
        <>
            <SectionContainer className="flex-col md:flex-row">
                <StyledSection title="Create competition" wideTitle asMobile>
                    <div className="flex flex-col gap-2 md:flex-row">
                        <div className="flex flex-col gap-2">
                            {IS_DEV_MODE && (
                                <StyledButton
                                    onClick={() => setTitle(format(new Date(), 'MMM_d_HH_mm'))}>
                                    set simple title
                                </StyledButton>
                            )}
                            <StyledInput label="Title" {...title} />
                            <StyledInput label="Max participants" {...maxParticipants} />
                            <StyledInput label="USD price" {...usdPrice} />
                            <div className="flex flex-col gap-2">
                                <p className={'rounded bg-slate-900 p-2 text-sky-300'}>
                                    = {renderReadableNumber(usdPrice.value)} USD *{' '}
                                    {renderReadableNumber(maxParticipants.value)} ={' '}
                                    {renderReadableNumber(usdPrice.value * maxParticipants.value)}{' '}
                                    USD
                                </p>
                                <p className={'rounded bg-slate-900 p-2 text-sky-300'}>
                                    ≈ {renderReadableNumber(uahPrice)} UAH *{' '}
                                    {renderReadableNumber(maxParticipants.value)} ={' '}
                                    {renderReadableNumber(uahPrice * maxParticipants.value)} UAH
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col gap-2">
                                <span>Access type:</span>
                                <div className="flex">
                                    <EnumButtonGroup
                                        options={[
                                            CompetitionAccessType.URL_ACCESS,
                                            CompetitionAccessType.PUBLIC,
                                        ]}
                                        value={accessType}
                                        onChange={e => setAccessType(e)}
                                    />
                                </div>
                                <StyledInput
                                    label="Repo HTML URL"
                                    value={sourceRepo.url}
                                    readOnly
                                    placeholder="https://..."
                                />
                                <div>
                                    <StyledButton onClick={() => setStartDate(new Date())}>
                                        set to now
                                    </StyledButton>
                                </div>
                                <StyledInput
                                    label="Start date"
                                    type="datetime-local"
                                    value={localStartDate}
                                    onChange={e => setStartDate(new Date(e.target.value))}
                                />
                                <StyledInput
                                    label="Expire date"
                                    type="datetime-local"
                                    value={localExpireDate}
                                    onChange={e => setExpireDate(new Date(e.target.value))}
                                />
                                <div className="grid w-80 grid-cols-2 gap-2">
                                    <StyledButton onClick={() => setStartDate(undefined)}>
                                        reset start
                                    </StyledButton>
                                    <StyledButton onClick={() => setExpireDate(undefined)}>
                                        reset expire
                                    </StyledButton>
                                </div>
                            </div>
                            <StyledButton
                                onClick={() => createAction()}
                                loading={createState.loading}
                                disabled={!sourceRepo.id || !title.value}>
                                create
                            </StyledButton>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex gap-2">Description</div>
                        <textarea
                            placeholder="Description"
                            className="h-24 p-1 ring-1 ring-gray-400"
                            {...description}
                        />
                    </div>
                    {typeof isValidHTML === 'string' && (
                        <div className="bg-red-600 p-2 text-red-100">{isValidHTML}</div>
                    )}
                </StyledSection>
                <div className="flex flex-col gap-4">
                    <StyledSection title="Select private repo" wideTitle>
                        <StyledInput label="Filter name" {...repoFilter} />
                        <StyledButton
                            onClick={() => setRepoFilter('')}
                            disabled={!repoFilter.value}>
                            clear
                        </StyledButton>
                        {!myRepos.loading && renderedRepoList}
                    </StyledSection>
                    {createState.value && (
                        <StyledSection title="Competition created" wideTitle>
                            {JSON.stringify(createState.value, null, 2)}
                            <StyledButton
                                // TODO refactor use custom typed generatePath to type-check params
                                linkTo={generatePath(
                                    AppGeneratedRoutes.competitions._competitionId_,
                                    {
                                        competitionId: createState.value.id,
                                    },
                                )}>
                                view
                            </StyledButton>
                        </StyledSection>
                    )}
                </div>
            </SectionContainer>
            <PreviewCompetition previewCompetition={previewCompetition} />
        </>
    );
};

export const PreviewCompetition: React.FC<{
    previewCompetition: Pick<
        ResponseCompetitionAdvancedInfoDto,
        'startDate' | 'expireDate' | 'title' | 'description'
    >;
}> = ({ previewCompetition }) => {
    const [mockCurrentDate, setMockCurrentDate] = useState(new Date().toJSON());
    const [showMock, setShowMock] = useState(false);

    return (
        <SectionContainer>
            <CompetitionSectionOverview
                info={previewCompetition}
                mockCurrentDate={showMock ? mockCurrentDate : undefined}
            />
            <StyledSection title="Preview controls">
                <StyledInput
                    type="datetime-local"
                    value={toLocalDateStr(new Date(mockCurrentDate), true)}
                    onChange={e => setMockCurrentDate(e.target.value)}
                    label="Mock Current Date"
                    className="w-full"
                    disabled={!showMock}
                    step="1"
                />
                <div className="flex gap-4">
                    <StyledButton onClick={() => setMockCurrentDate(new Date().toJSON())}>
                        reset
                    </StyledButton>
                    <StyledSwitch checked={showMock} onChange={setShowMock}>
                        Use Mock Date
                    </StyledSwitch>
                </div>
            </StyledSection>
        </SectionContainer>
    );
};

const checkValidHTML = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/xml');
    if (doc.documentElement.querySelector('parsererror')) {
        return doc.documentElement.querySelector<HTMLElement>('parsererror')?.innerText;
    } else {
        return true;
    }
};
