import { clsxm } from 'utils/clsxm';
import { FiExternalLink } from 'react-icons/fi';

export const ExternalLink: React.FC<
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = ({ children, className, ...props }) => {
    return (
        <a
            target="_blank"
            rel="noreferrer"
            className={clsxm(
                'inline-flex items-center gap-1 text-sky-800 hover:shadow-[0_1px]',
                className,
            )}
            {...props}>
            {children}
            <FiExternalLink />
        </a>
    );
};
